import { SYSTEM_ROLES } from './enums';
import { ICMSRoute } from '../_models/cms-route.interface';

export const CMS_ROUTES: Array<ICMSRoute> = [
  {
    groupTitle: '',
    groupHref: '#item-0',
    groupId: 'item-0',
    routes: [
      {
        routerLink: '/cms/company-summary',
        routerName: 'Company Summary',
        roles: [SYSTEM_ROLES.SUPER_ADMIN, SYSTEM_ROLES.ADMIN, SYSTEM_ROLES.COORDINATOR],
      },
    ],
  },
  {
    groupTitle: 'Content',
    groupHref: '#item-1',
    groupId: 'item-1',
    routes: [
      {
        routerLink: '/cms/posters-brochures',
        routerName: 'Posters and Brochures',
        roles: [SYSTEM_ROLES.SUPER_ADMIN, SYSTEM_ROLES.ADMIN, SYSTEM_ROLES.COORDINATOR],
      },
      // {
      //   routerLink: '/cms/booking-poster',
      //   routerName: 'Booking Poster',
      //   roles: [SYSTEM_ROLES.SUPER_ADMIN, SYSTEM_ROLES.ADMIN, SYSTEM_ROLES.COORDINATOR],
      // },
      // {
      //   routerLink: '/cms/marketing-poster',
      //   routerName: 'Marketing Poster',
      //   roles: [SYSTEM_ROLES.SUPER_ADMIN, SYSTEM_ROLES.ADMIN, SYSTEM_ROLES.COORDINATOR],
      // },
      // {
      //   routerLink: '/cms/marketing-brochure',
      //   routerName: 'Marketing Brochure',
      //   roles: [SYSTEM_ROLES.SUPER_ADMIN, SYSTEM_ROLES.ADMIN, SYSTEM_ROLES.COORDINATOR],
      // },
      {
        routerLink: '/cms/app-links-manager',
        routerName: 'App Links Manager',
        roles: [SYSTEM_ROLES.SUPER_ADMIN],
      },
      {
        routerLink: '/cms/carousel',
        routerName: 'Carousel',
        roles: [SYSTEM_ROLES.SUPER_ADMIN],
      },
      {
        routerLink: '/cms/acknowledgement-of-country',
        routerName: 'Acknowledgement of Country',
        roles: [SYSTEM_ROLES.SUPER_ADMIN],
      },
      {
        routerLink: '/cms/information-packs',
        routerName: 'Information Packs',
        roles: [SYSTEM_ROLES.SUPER_ADMIN, SYSTEM_ROLES.ADMIN, SYSTEM_ROLES.COORDINATOR],
      },
      {
        routerLink: '/cms/articles',
        routerName: 'Articles',
        roles: [SYSTEM_ROLES.SUPER_ADMIN, SYSTEM_ROLES.ADMIN, SYSTEM_ROLES.COORDINATOR],
      },
      {
        routerLink: '/cms/recipes',
        routerName: 'Recipes',
        roles: [SYSTEM_ROLES.SUPER_ADMIN, SYSTEM_ROLES.ADMIN, SYSTEM_ROLES.COORDINATOR],
      },
      {
        routerLink: '/cms/videos',
        routerName: 'Videos',
        roles: [SYSTEM_ROLES.SUPER_ADMIN, SYSTEM_ROLES.ADMIN, SYSTEM_ROLES.COORDINATOR],
      },
      {
        routerLink: '/cms/authors',
        routerName: 'Authors',
        roles: [SYSTEM_ROLES.SUPER_ADMIN, SYSTEM_ROLES.ADMIN, SYSTEM_ROLES.COORDINATOR],
      },
      {
        routerLink: '/cms/questionnaires',
        routerName: 'Questionnaires',
        roles: [SYSTEM_ROLES.SUPER_ADMIN],
      },
      {
        routerLink: '/cms/peer-support',
        routerName: 'Peer Support',
        roles: [SYSTEM_ROLES.SUPER_ADMIN],
      },
      {
        routerLink: '/cms/eap',
        routerName: 'EAP',
        roles: [SYSTEM_ROLES.SUPER_ADMIN],
      },
      {
        routerLink: '/cms/lifeactions',
        routerName: 'Life Actions',
        roles: [SYSTEM_ROLES.SUPER_ADMIN],
      },
      {
        routerLink: '/cms/groups',
        routerName: 'Groups',
        roles: [SYSTEM_ROLES.SUPER_ADMIN, SYSTEM_ROLES.ADMIN, SYSTEM_ROLES.COORDINATOR],
      },
      {
        routerLink: '/cms/badges',
        routerName: 'Badges',
        roles: [SYSTEM_ROLES.SUPER_ADMIN],
      },
      {
        routerLink: '/cms/challenges',
        routerName: 'Challenges',
        roles: [SYSTEM_ROLES.SUPER_ADMIN],
      },
      {
        routerLink: '/cms/teams',
        routerName: 'Teams',
        roles: [SYSTEM_ROLES.SUPER_ADMIN],
      },
      {
        routerLink: '/cms/partners',
        routerName: 'Partners',
        roles: [SYSTEM_ROLES.SUPER_ADMIN],
      },
    ],
  },
  {
    groupTitle: 'Reporting',
    groupHref: '#item-2',
    groupId: 'item-2',
    routes: [
      {
        routerLink: '/cms/analytics',
        routerName: 'Report Dashboard',
        roles: [SYSTEM_ROLES.SUPER_ADMIN, SYSTEM_ROLES.ADMIN, SYSTEM_ROLES.COORDINATOR],
      },
      {
        routerLink: '/cms/users-reporting',
        routerName: 'Users',
        roles: [SYSTEM_ROLES.SUPER_ADMIN, SYSTEM_ROLES.ADMIN, SYSTEM_ROLES.COORDINATOR],
      },
      {
        routerLink: '/cms/content-reporting',
        routerName: 'Content',
        roles: [SYSTEM_ROLES.SUPER_ADMIN, SYSTEM_ROLES.ADMIN, SYSTEM_ROLES.COORDINATOR],
      },
      {
        routerLink: '/cms/tmh-reporting',
        routerName: 'Track My Health',
        roles: [SYSTEM_ROLES.SUPER_ADMIN, SYSTEM_ROLES.ADMIN, SYSTEM_ROLES.COORDINATOR],
      },
      {
        routerLink: '/cms/teams-reporting',
        routerName: 'Teams',
        roles: [SYSTEM_ROLES.SUPER_ADMIN, SYSTEM_ROLES.ADMIN],
      },
      {
        routerLink: '/cms/insights-reporting',
        routerName: 'Insights',
        roles: [SYSTEM_ROLES.SUPER_ADMIN, SYSTEM_ROLES.ADMIN],
      },
      {
        routerLink: '/cms/life-actions-reporting',
        routerName: 'Life Actions',
        roles: [SYSTEM_ROLES.SUPER_ADMIN, SYSTEM_ROLES.ADMIN],
      },
    ],
  },
  {
    groupTitle: 'Configuration',
    groupHref: '#item-3',
    groupId: 'item-3',
    routes: [
      {
        routerLink: '/cms/category',
        routerName: 'Categories',
        roles: [SYSTEM_ROLES.SUPER_ADMIN],
      },
      {
        routerLink: '/cms/companies',
        routerName: 'Companies',
        roles: [SYSTEM_ROLES.SUPER_ADMIN, SYSTEM_ROLES.ADMIN],
      },
      {
        routerLink: '/cms/connections',
        routerName: 'Connections',
        roles: [SYSTEM_ROLES.SUPER_ADMIN],
      },
      {
        routerLink: '/cms/users',
        routerName: 'Users',
        roles: [SYSTEM_ROLES.SUPER_ADMIN],
      },
      {
        routerLink: '/cms/languages',
        routerName: 'Languages',
        roles: [SYSTEM_ROLES.SUPER_ADMIN],
      },
      {
        routerLink: '/cms/feature',
        routerName: 'Features',
        roles: [SYSTEM_ROLES.SUPER_ADMIN],
      },
      {
        routerLink: '/cms/menu-configuration',
        routerName: 'Menu Configuration',
        roles: [SYSTEM_ROLES.SUPER_ADMIN],
      },
    ],
  },
];
