import { Component, OnInit, NgZone } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { IGroup } from '../../_models/group';
import { GroupService } from '../../../services/group-service';
import { UtilService } from '../../../services/util-service';
import { FileStackService } from '../../../services/file-stack.service';
import { CompanyService } from '../../../services/company-service';
import { BootstrapModalComponent } from '../../../components/bootstrap-modal/bootstrap-modal.component';
import { UserService } from '../../../services/user-service';
import { IIdNamePair } from '../../_models/id-name-pair';

@Component({
  selector: 'app-group-management',
  templateUrl: './group-management.component.html',
  styleUrls: ['./group-management.component.scss']
})
export class GroupManagementComponent implements OnInit {
  items: IGroup[] = [];
  item: IGroup;
  itemType = 'Group';
  columns = [];
  itemForm: FormGroup;
  companies = [];
  allCompanies: IIdNamePair[] = [];
  excludedCompanyIds: number[] = [];
  groupStatuses = [];
  additionalNumericColumns = [];

  isDisplayingList: boolean;
  isInitialised: boolean;
  isEditingItem: boolean;
  isAddingItem: boolean;
  canRespondToButtons: boolean;

  private canSelectCompany = false;

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private itemService: GroupService,
    private companyService: CompanyService,
    private utilService: UtilService,
    private fb: FormBuilder,
    private fileStackService: FileStackService,
    private ngZone: NgZone,
    private modalService: NgbModal,
    private userService: UserService
  ) {}

  ngOnInit() {
    this.activatedRoute.params.subscribe(route => {
      this.analyseRoute(route);

      if (this.isDisplayingList) {
        this.getList();
      } else if (this.isEditingItem) {
        this.setUserRoleAuthorisations();
        this.initialiseEditItem(+route.id);
      } else if (this.isAddingItem) {
        this.setUserRoleAuthorisations();
        this.initialiseAddItem();
      }
    });
  }

  private setUserRoleAuthorisations() {
    this.canSelectCompany = this.userService.isSuperAdminUser();
  }

  private analyseRoute(route) {
    this.isDisplayingList = false;
    this.isEditingItem = false;
    this.isAddingItem = false;
    this.isInitialised = false;
    this.canRespondToButtons = false;

    if (!route.id) {
      this.isDisplayingList = true;
      return;
    }

    if (route.id === 'new') {
      this.isAddingItem = true;
    } else {
      this.isEditingItem = true;
    }
  }

  private getList() {
    let companyId;
    if (this.userService.isSuperAdminUser()) {
      companyId = 0;
    } else {
      companyId = this.userService.profile.companyId;
    }
    this.itemService.getGroupsCMS(companyId)
      .subscribe(data => {
        this.items = data.map((i) => {
          return [
            i.id,
            i.name,
            i.members,
            i.posts,
            this.itemService.statusName(i.status)
          ];
        });

        this.columns = ['id', 'name', 'members count', 'posts count', 'status'];
        this.additionalNumericColumns = [2, 3];
        this.isInitialised = true;
        this.canRespondToButtons = true;
      },
      (err) => {
        this.utilService.showToastError('Error while loading groups', err);
      }
    );
  }

  private initialiseEditItem(id) {
    this.groupStatuses = this.itemService.getAllStatuses();
    this.getCompanies(id);
  }

  private initialiseAddItem() {
    this.groupStatuses = this.itemService.getAllStatuses();
    this.getCompanies();
  }

  private getCompanies(id?: number) {
    this.companyService.getAllCompanies()
      .subscribe(data => {
          this.companies = data;
          this.allCompanies = this.companies.map(c => {
            return { id: c.id, name: c.name };
          });

          if (id) {
            this.getItem(id);
          } else {
            this.getNewItem();
          }
        },
        (err) => {
          this.companies = [];
          this.utilService.showToastError('Error while loading companies', err);
        }
      );
    }

  private getItem(id: number) {
    this.itemService.getGroupById(id)
      .subscribe(data => {
        this.item = data;
        this.initialiseEditableFormGroup(data);
        this.isInitialised = true;
        this.canRespondToButtons = true;
      },
      (err) => {
        this.utilService.showToastError('Error while getting group for id: ' + id, err);
      }
    );
  }

  private getNewItem() {
    this.item = this.itemService.getNewGroup();
    this.initialiseEditableFormGroup(this.item);
    this.isInitialised = true;
    this.canRespondToButtons = true;
  }

  private initialiseEditableFormGroup(item) {
    this.itemForm = this.fb.group({
      name: [item.name, Validators.required],
      description: item.description,
      banner: [item.banner, Validators.required],
      logo: [item.logo, Validators.required],
      companyId: item.companyId,
      status: [item.status, Validators.required]
    });

    this.excludedCompanyIds = this.isAllCompanies()
      ? item.excludedCompanyIds
      : [];
  }

  private groupMembersCount(): number {
    return this.item.groupMembers.length;
  }

  private postsCount(): number {
    return this.item.posts.length;
  }

  private getGroupMembersAndPostsMessage() {
    if (!this.groupMembersCount() && !this.postsCount()) {
      return 'There are no group members nor posts for this group';
    }

    return `There are ${this.groupMembersCount()} group member(s) and ${this.postsCount()} posts for this group!!!`;
  }

  private deleteItem() {
    const modal = this.modalService.open(BootstrapModalComponent);
    modal.componentInstance.title = 'Confirm deleting this group';
    modal.componentInstance.message = this.getGroupMembersAndPostsMessage();
    modal.componentInstance.confirmButtonLabel = 'Delete';

    modal.result.then(
      (isConfirmed) => {
        if (!isConfirmed) {
          return;
        }

        this.itemService.deleteGroup(this.item.id)
          .subscribe(() => {
            this.itemForm.reset();
            this.utilService.showToastSuccess(`Group - ${this.item.name} - deleted`);
            this.navigateToList();
          },
          (error) => this.utilService.showToastError('Error while deleting item - ' + error)
        );
      },
      () => {}
    );
  }

  private markAllControlsAsTouched() {
    Object.keys(this.itemForm.controls).forEach((field) => {
      const control = this.itemForm.get(field);
      control.markAsTouched({ onlySelf: true });
    });
  }

  private navigateToList() {
    this.router.navigate(['/cms/groups']);
  }

  private persistItem() {
    this.canRespondToButtons = false;
    let itemToPersist: IGroup;

    try {
      itemToPersist = {
        id: this.item.id,
        posts: this.item.posts,
        groupMembers: this.item.groupMembers,
        name: this.itemForm.get('name').value,
        description: this.itemForm.get('description').value,
        companyId:
          +this.itemForm.get('companyId').value > 0
            ? +this.itemForm.get('companyId').value
            : null,
        logo: this.itemForm.get('logo').value,
        banner: this.itemForm.get('banner').value,
        status: +this.itemForm.get('status').value,
        excludedCompanyIds: this.isAllCompanies()
          ? this.excludedCompanyIds
          : []
      };

      if(!this.canSelectCompany) {
        itemToPersist.companyId = this.userService.company.id;
      }
    } catch (error) {
      this.utilService.showToastError('Error persisting - ' + error);
      this.canRespondToButtons = true;
      return;
    }

    if (this.isEditingItem) {
      this.updateItem(itemToPersist);
    } else if (this.isAddingItem) {
      this.addNewItem(itemToPersist);
    }
  }

  private updateItem(itemToPersist: IGroup) {
    this.itemService.updateGroup(itemToPersist)
      .subscribe(() => {
        this.itemForm.reset();
        this.canRespondToButtons = true;
        this.utilService.showToastSuccess(`Updated group - ${itemToPersist.name}`);
        this.navigateToList();
      },
      (error) => {
        this.utilService.showToastError(`Error while saving group - ${error}`);
        this.canRespondToButtons = true;
      }
    );
  }

  private addNewItem(itemToPersist: IGroup) {
    this.itemService.addGroup(itemToPersist)
      .subscribe(() => {
        this.itemForm.reset();
        this.canRespondToButtons = true;
        this.utilService.showToastSuccess(`Added new group - ${itemToPersist.name}`);
        this.navigateToList();
      },
      (error) => {
        this.utilService.showToastError(`Error while saving group - ${error}`);
        this.canRespondToButtons = true;
      }
    );
  }

  isFieldInvalid(field: string) {
    return !this.itemForm.get(field).valid && this.itemForm.get(field).touched;
  }

  displayFieldCss(field: string) {
    return {
      'is-invalid': this.isFieldInvalid(field)
    };
  }

  isAllCompanies() {
    try {
      return this.itemForm.get('companyId')
        && !this.itemForm.get('companyId').value
        && this.canSelectCompany;
    } catch (error) {
      return false;
    }
  }

  onCompanySelected(companyId) {
    this.itemForm.patchValue({ companyId: companyId });
  }

  excludedCompanyIdsSelected(selectedCompanyIds: number[]) {
    this.excludedCompanyIds = selectedCompanyIds;
  }

  onBannerClick() {
    this.fileStackService
      .pickGroupBanner()
      .then((result) => this.ngZone.run(() => this.itemForm.patchValue({ banner: result })))
      .catch((err) => {
        console.log(err);

        this.utilService.showToastError('Error while uploading the banner')});
  }

  onLogoClick() {
    this.fileStackService
      .pickGroupLogo()
      .then((result) => this.ngZone.run(() => this.itemForm.patchValue({ logo: result })))
      .catch(() => this.utilService.showToastError('Error while uploading the logo'));
  }

  onBackClick() {
    this.navigateToList();
  }

  onDeleteClick() {
    if (!this.isEditingItem) {
      return;
    }

    this.deleteItem();
  }

  onSaveClick() {
    if (!this.itemForm.valid) {
      this.markAllControlsAsTouched();
      return;
    }

    this.persistItem();
  }
}
