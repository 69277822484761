import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { of } from 'rxjs';
import { tap } from 'rxjs/operators';
import { ICategory } from '../app/_models/category';
import { IDomain } from '../app/_models/domain';
import { IDriver } from '../app/_models/driver';
import { CATEGORIES } from '../app/_shared/enums';
import { IIdNamePair } from '../app/_models/id-name-pair';
import { environment } from '../environments/_active-environment/environment';
import { IEnvironment } from '../app/_models/environment-config';

@Injectable({
  providedIn: 'root'
})
export class CategoryService {
  private cachedCategories: ICategory[] = [];
  private cachedDrivers: IDriver[] = [];
  private envVariablesInMemoryStorage: any;

  DomainMovementId = 1;
  DomainNutritionId = 2;
  DomainRecoveryId = 3;
  DomainMindfulnessId = 4;
  DomainPurposeId = 5;
  DomainRelationshipsId = 6;
  DomainProtectionId = 7;
  DomainManagementId = 8;
  DomainWealthId = 9;

  DomainMovementName = 'Movement';
  DomainNutritionName = 'Nutrition';
  DomainRecoveryName = 'Recovery';
  DomainMindfulnessName = 'Mindfulness';
  DomainPurposeName = 'Purpose';
  DomainRelationshipsName = 'Relationships';
  DomainProtectionName = 'Protection';
  DomainManagementName = 'Management';
  DomainWealthName = 'Wealth';

  DriverHealth = 'Health';
  DriverHappiness = 'Happiness';
  DriverSecurity = 'Security';

  private allDomains: IDomain[] = [
    { id: this.DomainMovementId, name: this.DomainMovementName, driver: this.DriverHealth },
    { id: this.DomainNutritionId, name: this.DomainNutritionName, driver: this.DriverHealth },
    { id: this.DomainRecoveryId, name: this.DomainRecoveryName, driver: this.DriverHealth },
    { id: this.DomainMindfulnessId, name: this.DomainMindfulnessName, driver: this.DriverHappiness },
    { id: this.DomainPurposeId, name: this.DomainPurposeName, driver: this.DriverHappiness },
    { id: this.DomainRelationshipsId, name: this.DomainRelationshipsName, driver: this.DriverHappiness },
    { id: this.DomainProtectionId, name: this.DomainProtectionName, driver: this.DriverSecurity },
    { id: this.DomainManagementId, name: this.DomainManagementName, driver: this.DriverSecurity },
    { id: this.DomainWealthId, name: this.DomainWealthName, driver: this.DriverSecurity }
  ];

  private allCategories: IIdNamePair[] = [
    { id: CATEGORIES.ALL, name: 'All' },
    { id: CATEGORIES.SOUPS_SALADS_AND_SIDES, name: 'Soups, salads & sides' },
    { id: CATEGORIES.VEGAN_AND_VEGETARIAN, name: 'Vegan & vegetarian' },
    { id: CATEGORIES.MEAT_AND_FISH, name: 'Meat & fish' },
    { id: CATEGORIES.SMOOTHIES_AND_DESSERTS, name: 'Smoothies & desserts' },
    { id: CATEGORIES.FITNESS, name: 'Fitness' },
    { id: CATEGORIES.FUEL, name: 'Nutrition' },
    { id: CATEGORIES.STAYING_HEALTHY, name: 'Staying healthy' },
    { id: CATEGORIES.FINDING_HAPPY, name: 'Finding happy' },
    { id: CATEGORIES.BUILDING_RESILIENCE, name: 'Building resilience' },
    { id: CATEGORIES.RELATIONSHIPS, name: 'Relationships' },
    { id: CATEGORIES.FINANCIAL_PLANNING, name: 'Financial planning' },
    { id: CATEGORIES.CAREER_FOCUS, name: 'Career focus' },
    { id: CATEGORIES.WORK_LIFE, name: 'Work life' },
    { id: CATEGORIES.PLANNING_AND_PREGNANCY, name: 'Planning & pregnancy' },
    { id: CATEGORIES.BABIES_AND_KIDS, name: 'Babies & kids' },
    { id: CATEGORIES.BACK_TO_WORK, name: 'Back to work' },
    { id: CATEGORIES.FEEL, name: 'Feel' },
    { id: CATEGORIES.NOURISH, name: 'Nourish' },
    { id: CATEGORIES.EXERCISE, name: 'Exercise' },
    { id: CATEGORIES.MINDSET, name: 'Mindset' },
    { id: CATEGORIES.HEALTH, name: 'Health' },
    { id: CATEGORIES.NUTRITION, name: 'Nutrition' },
  ]
;
  constructor(private http: HttpClient) {
    this.envVariables = environment;
  }

  get categoryImagesPathSegment(): string {
    return 'category-images/';
  }

  get envVariables(): IEnvironment {
    return this.envVariablesInMemoryStorage;
  }

  set envVariables(value: IEnvironment) {
    this.envVariablesInMemoryStorage = value;
  }

  allStaticDomains(): IDomain[] {
    return this.allDomains.slice();
  }

  domainName(id: number) {
    const domain = this.allDomains.find((d) => d.id === id);
    if (!domain) {
      return null;
    }

    return domain.name;
  }

  categoryName(categoryId: number): string {
    const category: IIdNamePair = this.allCategories.find(c => c.id === categoryId);
    if (!category) { return 'Unknown Category'; }

    return category.name;
  }

  getDomains() {
    const url = '/cms/domains';
    return this.http.get<any>(url);
  }

  getDrivers() {
    const url = '/drivers';
    return this.http.get<any>(url);
  }

  getCategories() {
    const url = '/cms/categories';

    return this.http.get<any>(url);
  }

  getCategoryById(id: number) {
    const url = '/cms/categories/' + id;

    return this.http.get<any>(url);
  }

  getNewCategory(): ICategory {
    return {
      id: 0,
      name: '',
      featureId: null,
      domainId: null,
      menuImage: '',
    };
  }

  updateCategory(category: ICategory) {
    const body = {
      name: category.name,
      featureId: category.featureId,
      domainId: category.domainId,
      menuImage: category.menuImage,
    };
    const url = `/cms/categories/${category.id}`;

    return this.http.put<any>(url, body);
  }

  addCategory(category: ICategory) {
    const body = {
      name: category.name,
      featureId: category.featureId,
      domainId: category.domainId,
      menuImage: category.menuImage,
    };
    const url = '/cms/categories';

    return this.http.post<any>(url, body);
  }

  deleteCategory(id: number) {
    const url = `/cms/categories/${id}`;

    return this.http.delete<any>(url);
  }

  refreshCache(forceRefresh?: boolean) {
    if (this.cachedCategories.length && !forceRefresh) {
      return of(this.cachedCategories);
    }

    return this.getCategories().pipe(tap((data) => this.updateCacheCategories(data)));
  }

  updateCacheCategories(data) {
    if (!data || !data.length) {
      return;
    }
    this.cachedCategories = data;
    this.allCategories = data.map(ct => ({ id: ct.id, name: ct.name }));
  }

  refreshDriverCache() {
    if (this.cachedDrivers.length) {
      return this.cachedDrivers;
    }

    this.getDrivers().subscribe((data) => (this.cachedDrivers = data));
  }

  getCachedDrivers(): IDriver[] {
    return this.cachedDrivers.slice();
  }

  getCachedCategoriesForFeatureId(id: number) {
    return this.cachedCategories.filter(c => c.featureId === id);
  }

  getImageForCategory(image: string): string {
    if (!image) {
      return null;
    }
    return this.getCategoryStorageAbsolutePath(this.categoryImagesPathSegment, image);
  }

  getCategoryStorageAbsolutePath(path, filename) {
    if (path.slice(-1) !== '/') {
      path += '/';
    }
    return `${this.envVariables.blobStorage.url}${path}${filename}`;
  }

  getCategoriesName(name: string) {

    switch(name.toLowerCase()) {
      case 'move':
        name = 'Fitness';
        break;
      case 'fuel':
        name = 'Nutrition'
        break;
      case 'recover':
        name = 'Staying healthy';
        break;
      case 'preserve':
        name = 'Building resilience'
        break;
      case 'relate':
        name = 'Relationships';
        break;
      case 'work':
        name = 'Work life'
        break;
      case 'plan':
        name = 'inancial planning';
        break;
      case 'protect':
        name = 'Career focus';
        break;
    }
    return name;
  }
}
