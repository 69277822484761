import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Component, NgZone, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ITeam } from '../../_models/team';
import { TeamService } from '../../../services/team.service';
import { UserService } from '../../../services/user-service';
import { FileStackService } from '../../../services/file-stack.service';
import { UtilService } from '../../../services/util-service';
import { ChallengeService } from '../../../services/challenge-service';
import { IChallenge } from '../../_models/challenge';
import { BootstrapModalComponent } from '../../../components/bootstrap-modal/bootstrap-modal.component';

@Component({
  selector: 'app-teams-management',
  templateUrl: './teams-management.component.html',
  styleUrls: ['./teams-management.component.scss'],
})
export class TeamsManagementComponent implements OnInit {
  items: ITeam[];
  item: ITeam;

  challenges: IChallenge[];

  itemForm: FormGroup;
  id: number;
  error = '';
  teamName: string;
  action: string;

  isDisplayingList: boolean;
  isInitialised: boolean;
  isEditingItem: boolean;
  isAddingItem: boolean;
  canRespondToButtons: boolean;

  createSectionOne: boolean;
  createSectionTwo: boolean;
  selectItem: number[] = [];

  constructor(
    private teamService: TeamService,
    private router: Router,
    public activatedRoute: ActivatedRoute,
    private fb: FormBuilder,
    private userService: UserService,
    private filestackService: FileStackService,
    private ngZone: NgZone,
    private utilService: UtilService,
    private challengeService: ChallengeService,
    private modalService: NgbModal,
  ) {
  }

  ngOnInit() {
    this.activatedRoute.params.subscribe(route => {
      this.analyseRoute(route);

      if (this.isDisplayingList) {
        this.getList();
      } else {
        this.isInitialised = false;
        this.canRespondToButtons = false;
        this.challengeService.getAllChallenges().subscribe(
          data => {
            this.challenges = data;
          },
          error =>
            this.utilService.showToastError(
              'Error while loading items - ' + error,
            ),
        );

        if (this.isEditingItem) {
          this.initialiseEditItem(+route.id);
        } else if (this.isAddingItem) {
          this.initialiseAddItem();
        }
      }
    });
  }

  private analyseRoute(route) {
    this.isDisplayingList = false;
    this.isEditingItem = false;
    this.isAddingItem = false;
    this.isInitialised = false;
    this.canRespondToButtons = false;

    if (!route.id) {
      this.isDisplayingList = true;
      return;
    }

    if (route.id === 'new') {
      this.isAddingItem = true;
    } else {
      this.isEditingItem = true;
    }
  }

  getList() {
    this.teamService.getAllTeams().subscribe(
      data => {
        this.items = data;
        this.items.forEach(element => {
          element.image = element.image ? this.userService.envVariables.blobStorage.url + 'team-images/' + element.image : null;
        });

        this.isInitialised = true;
        this.canRespondToButtons = true;
      },
      error =>
        this.utilService.showToastError('Error while loading items - ' + error),
    );
  }

  private initialiseAddItem(): void {
    this.item = this.teamService.getNewTeam();
    this.initialiseEditableFormGroup(this.item);
  }

  private initialiseEditableFormGroup(item: ITeam) {
    this.itemForm = this.fb.group({
      nameControl: [item.name, Validators.required],
      image: [item.image, Validators.required],
      challengeId: [item.challengeId, Validators.required],
    });

    if (item.id == 0) {
      this.isAddingItem = true;
      this.isEditingItem = false;
    } else {
      this.isEditingItem = true;
      this.isAddingItem = false;
    }
    this.createSectionOne = true;
    this.createSectionTwo = false;

    this.isInitialised = true;
    this.canRespondToButtons = true;
  }

  initialiseEditItem(id) {
    this.teamService.getTeamById(id).subscribe(data => {
      this.item = data;
      this.initialiseEditableFormGroup(data);
    });
  }

  isFieldInvalid(field: string) {
    return !this.itemForm.get(field).valid && this.itemForm.get(field).touched;
  }

  displayFieldCss(field: string) {
    return {
      'is-invalid': this.isFieldInvalid(field),
    };
  }

  openfilestack() {
    this.filestackService.pickTeamImg().then(
      res => {
        this.ngZone.run(() => this.itemForm.patchValue({ image: res }));
      },
      err => {
        this.utilService.showToastError('Error while uploading picture', err);
      },
    );
  }

  onSaveClick() {
    if (!this.itemForm.valid) {
      this.markAllControlsAsTouched();
      return;
    }
    this.persistItem();
  }

  private persistItem() {
    this.canRespondToButtons = false;
    let team: ITeam;
    try {
      team = {
        name: this.itemForm.get('nameControl').value,
        image: this.itemForm.get('image').value,
        challengeId: this.itemForm.get('challengeId').value,
        cId: this.isEditingItem ? this.item.cId : 0,
      };
      if (this.item.id !== 0) {
        team.id = this.item.id;
      }
    } catch (error) {
      const errorMsg = 'Error persisting item - ' + error;
      this.utilService.showToastError(errorMsg);
      this.canRespondToButtons = true;
      return;
    }

    if (this.isEditingItem) {
      this.updateItem(team);
    } else if (this.isAddingItem) {
      this.addNewItem(team);
    }
  }

  private addNewItem(body) {
    this.teamService.addTeam(body).subscribe(
      () => {
        this.itemForm.reset();
        this.canRespondToButtons = true;
        const msg = `Added new team - ${body.name}`;
        this.utilService.showToastSuccess(msg);
        this.teamName = body.name;
        this.action = 'created';
        this.goToSectionTwo();
      },
      error => {
        const errorMsg = `Error while adding item - ${error}`;
        this.utilService.showToastError(errorMsg);
        this.canRespondToButtons = true;
      },
    );
  }

  private updateItem(body) {
    this.teamService.updateTeam(body, body.id).subscribe(
      () => {
        this.itemForm.reset();
        this.canRespondToButtons = true;
        const msg = `Updated team - ${body.name}`;
        this.utilService.showToastSuccess(msg);
        this.teamName = body.name;
        this.action = 'updated';
        this.goToSectionTwo();
      },
      error => {
        const errorMsg = `Error while saving item - ${error}`;
        this.utilService.showToastError(errorMsg);
        this.canRespondToButtons = true;
      },
    );
  }

  goToSectionOne() {
    this.isDisplayingList = false;
    this.router.navigate(['/cms/teams/new']);
    this.createSectionOne = true;
    this.createSectionTwo = false;
    this.initialiseAddItem();
    // this.challengeService.getAllChallenges().subscribe(
    //   (data) => {
    //     this.challenges = data;
    //   },
    //   (error) => this.utilService.showToastError('Error while loading items - ' + error)
    // );
    // this.item = this.teamService.getNewTeam();
    // this.itemForm = this.fb.group({
    //   nameControl: [this.item.name, Validators.required],
    //   image: [this.item.image, Validators.required],
    //   challengeId: [this.item.challengeId, Validators.required]
    // });
    // this.isAddingItem = true;
    // this.isEditingItem = false;
  }

  goToSectionTwo() {
    this.createSectionOne = false;
    this.createSectionTwo = true;
    this.teamService.notifyTeamsChanged();
  }

  private markAllControlsAsTouched() {
    Object.keys(this.itemForm.controls).forEach(field => {
      const control = this.itemForm.get(field);
      control.markAsTouched({ onlySelf: true });
    });
  }

  private navigateToList() {
    this.router.navigate(['/cms/teams']);
  }

  goToDetailLink(id) {
    this.isDisplayingList = false;
    this.router.navigate(['/cms/teams/' + id]);
  }

  onDeleteClick() {
    if (!this.isEditingItem) {
      return;
    }
    this.deleteItem();
  }

  private deleteItem() {
    const modal = this.modalService.open(BootstrapModalComponent);
    modal.componentInstance.title = 'Confirm deleting this team(s)';
    modal.componentInstance.confirmButtonLabel = 'Delete';

    modal.result.then(
      isConfirmed => {
        if (!isConfirmed) {
          return;
        }
        this.teamService.deleteTeam(this.item.id).subscribe(
          () => {
            this.itemForm.reset();
            this.utilService.showToastSuccess(
              `Team - ${this.item.name} - deleted`,
            );
            this.teamService.notifyTeamsChanged();
            this.navigateToList();
          },
          error =>
            this.utilService.showToastError(
              'Error while deleting item - ' + error,
            ),
        );
      },
      () => {
      },
    );
  }

  onCheckClick(id) {
    if (this.selectItem && this.selectItem.indexOf(id) !== -1) {
      this.selectItem = this.selectItem.filter(item => item !== id);
    } else {
      this.selectItem.push(id);
    }
  }

  onMultiDeleteClick() {
    const modal = this.modalService.open(BootstrapModalComponent);
    const speltNumber = this.utilService.spellNumber(this.selectItem.length).toUpperCase();
    modal.componentInstance.title = `Confirm deleting ${speltNumber} team(s)`;
    modal.componentInstance.confirmButtonLabel = 'Delete';

    modal.result.then(
      isConfirmed => {
        if (!isConfirmed) {
          return;
        }
        let lastId = this.selectItem[this.selectItem.length - 1];
        this.selectItem.forEach(id => this.deleteItemForId(+id, lastId));
      },
      () => {
      },
    );
  }

  private deleteItemForId(id: number, lastId: number) {
    this.teamService.deleteTeam(id).subscribe(
      () => {
        this.utilService.showToastSuccess('Deleted item id ' + id);
        if (id === lastId) {
          this.teamService.notifyTeamsChanged();
        }
        this.removeIdFromLists(id);
      },
      err => this.utilService.showToastError('Error while deleting item id ' + id, err),
    );
  }

  private removeIdFromLists(id: number) {
    this.selectItem = this.selectItem.filter(item => item !== id);
    this.items = this.items.filter(item => item.id !== id);
  }

}
