import { Component, NgZone, OnInit } from '@angular/core';
import { CategoryService } from '../../../services/category-service';
import { UtilService } from '../../../services/util-service';
import { ICategory } from '../../_models/category';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BootstrapModalComponent } from '../../../components/bootstrap-modal/bootstrap-modal.component';
import { FeatureService } from '../../../services/feature-service';
import { IIdNamePair } from '../../_models/id-name-pair';
import { IDomain } from '../../_models/domain';
import { FeatureNamePipe } from '../../_shared/pipes/featureName.pipe';
import { FileStackService } from '../../../services/file-stack.service';

const maxCategoryImageSize = 100 * 1500;

@Component({
  selector: 'app-category-management',
  templateUrl: './category-management.component.html',
  styleUrls: ['./category-management.component.scss'],
})
export class CategoryManagementComponent implements OnInit {
  items: ICategory[];
  item: ICategory;
  itemType = 'Category';
  columns = [];
  itemForm: FormGroup;

  isDisplayingList: boolean;
  isInitialised: boolean;
  isEditingItem: boolean;
  isAddingItem: boolean;
  canRespondToButtons: boolean;

  features: IIdNamePair[] = [];
  domains: IDomain[] = [];

  image = '';

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private itemService: CategoryService,
    private featureService: FeatureService,
    private fb: FormBuilder,
    private utilService: UtilService,
    private modalService: NgbModal,
    private featureNamePipe: FeatureNamePipe,
    private fileStackService: FileStackService,
    private ngZone: NgZone,
    private categoryService: CategoryService,
  ) {
  }

  ngOnInit() {
    this.activatedRoute.params.subscribe(route => {
      this.analyseRoute(route);

      if (this.isDisplayingList) {
        this.getList();
      } else if (this.isEditingItem) {
        this.initialiseEditItem(+route.id);
      } else if (this.isAddingItem) {
        this.initialiseAddItem();
      }
    });
  }

  private analyseRoute(route) {
    this.isDisplayingList = false;
    this.isEditingItem = false;
    this.isAddingItem = false;
    this.isInitialised = false;
    this.canRespondToButtons = false;

    if (!route.id) {
      this.isDisplayingList = true;
      return;
    }

    if (route.id === 'new') {
      this.isAddingItem = true;
    } else {
      this.isEditingItem = true;
    }
  }

  private getList() {
    this.itemService.getCategories()
      .subscribe(data => {
          this.items = data.map(i => {
            return [i.id, i.name,
              this.featureNamePipe.transform(this.featureService.featureName(i.featureId) || ''),
              this.itemService.domainName(i.domainId) || '',
              this.categoryService.getImageForCategory(i.menuImage) || '',
            ];
          });

          this.columns = ['id', 'name', 'feature', 'domain', 'menu image'];
          this.isInitialised = true;
          this.canRespondToButtons = true;
        },

        error => this.utilService.showToastError('Error while loading categories - ' + error));
  }

  private initialiseEditItem(id: number): void {
    this.itemService.getCategoryById(id)
      .subscribe(data => {
        this.item = data;
        this.initialiseEditableFormGroup(data);
        this.isInitialised = true;
        this.canRespondToButtons = true;
      });
  }

  private initialiseAddItem(): void {
    this.item = this.itemService.getNewCategory();
    this.initialiseEditableFormGroup(this.item);
    this.isInitialised = true;
    this.canRespondToButtons = true;
  }

  private initialiseEditableFormGroup(item) {
    this.setImage(item.menuImage);
    this.features = this.featureService.allFeaturesForDropdownSelection();
    this.domains = this.itemService.allStaticDomains();

    this.itemForm = this.fb.group({
      nameControl: [item.name, Validators.required],
      featureIdControl: [item.featureId || '', Validators.required],
      domainIdControl: item.domainId,
      imageControl: [item.menuImage, Validators.required],
    });
  }

  private markAllControlsAsTouched() {
    Object.keys(this.itemForm.controls).forEach(field => {
      const control = this.itemForm.get(field);
      control.markAsTouched({ onlySelf: true });
    });
  }

  isFieldInvalid(field: string) {
    return !this.itemForm.get(field).valid && this.itemForm.get(field).touched;
  }

  displayFieldCss(field: string) {
    return {
      'is-invalid': this.isFieldInvalid(field),
    };
  }

  private navigateToList() {
    this.router.navigate(['/cms/category']);
  }

  private nullable(value) {
    if (value === 'null') {
      return null;
    }

    return value;
  }

  private deleteItem() {
    const modal = this.modalService.open(BootstrapModalComponent);
    modal.componentInstance.title = 'Delete ' + this.itemType;
    modal.componentInstance.message = 'Confirm deleting this ' + this.itemType;
    modal.componentInstance.confirmButtonLabel = 'Delete';

    modal.result.then(isConfirmed => {
      if (!isConfirmed) {
        return;
      }

      this.itemService.deleteCategory(this.item.id)
        .subscribe(() => {
            this.itemForm.reset();
            this.utilService.showToastSuccess(`${this.itemType} - ${this.item.name} - deleted`);
            this.navigateToList();
          },
          error => this.utilService.showToastError('Error while deleting item - ' + error));
    }, () => {
    });
  }

  private persistItem() {
    this.canRespondToButtons = false;
    let itemToPersist: ICategory;

    try {
      itemToPersist = {
        id: this.item.id,
        name: this.itemForm.get('nameControl').value,
        featureId: this.nullable(this.itemForm.get('featureIdControl').value),
        domainId: this.nullable(this.itemForm.get('domainIdControl').value),
        menuImage: this.itemForm.get('imageControl').value,
      };
    } catch (error) {
      this.utilService.showToastError('Error persisting item - ' + error);
      this.canRespondToButtons = true;
      return;
    }

    if (this.isEditingItem) {
      this.updateItem(itemToPersist);
    } else if (this.isAddingItem) {
      this.addNewItem(itemToPersist);
    }
  }

  private updateItem(itemToPersist: ICategory) {
    this.itemService.updateCategory(itemToPersist)
      .subscribe(() => {
          this.itemForm.reset();
          this.canRespondToButtons = true;
          this.utilService.showToastSuccess(`Updated ${this.itemType} - ${itemToPersist.name}`);
          this.navigateToList();
        },
        error => {
          this.utilService.showToastError(`Error while saving item - ${error}`);
          this.canRespondToButtons = true;
        });
  }

  private addNewItem(itemToPersist: ICategory) {
    this.itemService.addCategory(itemToPersist)
      .subscribe(() => {
          this.itemForm.reset();
          this.canRespondToButtons = true;
          this.utilService.showToastSuccess(`Added new ${this.itemType} - ${itemToPersist.name}`);
          this.navigateToList();
        },
        error => {
          this.utilService.showToastError(`Error while adding item - ${error}`);
          this.canRespondToButtons = true;
        });
  }

  onBackClick() {
    this.navigateToList();
  }

  onDeleteClick() {
    if (!this.isEditingItem) {
      return;
    }

    this.deleteItem();
  }

  onSaveClick() {
    if (!this.itemForm.valid) {
      this.markAllControlsAsTouched();
      return;
    }

    this.persistItem();
  }

  setImage(image?) {
    if (!image || image.trim().length === 0) {
      return;
    }

    this.image = this.categoryService.getImageForCategory(image);
  }

  openFileStack() {
    this.fileStackService.pickCategoryImage(maxCategoryImageSize)
      .then(res => {
          this.ngZone.run(() => {
            setTimeout(() => {
              this.itemForm.patchValue({ imageControl: res });
              this.setImage(res);
            }, 1000);
          });
        },
        err => {
          this.utilService.showToastError('Error while uploading picture', err);
        },
      );
  }
}
