<section class='home-preview' ngbAutofocus>
  <div class='home-preview__header-bar d-flex justify-content-between align-items-center'>
    <img [src]='logoSrc' alt='logo' class='brand-logo-preview' />
    <div>
      <ul [style.color]='topTextBarColor' class='d-flex flex-row align-items-end text-uppercase'>
        <li>
      <span aria-expanded='false'
            aria-haspopup='true' class='dropdown-toggle'
            data-toggle='dropdown' href='#' role='button'>
        Discover
      </span>
        </li>
        <li>
      <span aria-expanded='false'
            aria-haspopup='true' class='dropdown-toggle'
            data-toggle='dropdown' href='#'
            role='button'>
        My Health
      </span>
        </li>
        <li><span aria-expanded='false'
                  aria-haspopup='true' class='dropdown-toggle'
                  data-toggle='dropdown' href='#'
                  role='button'>
      Community
    </span></li>
      </ul>
    </div>
  </div>
  <div class='container mt-3'>
    <div class='row p-0'>

      <div class='col-12 col-md-9 p-0'>
        <div class='home-preview__main px-3'>
          <div class='px-2'>
            <form class='search-group'>
              <i class='fa fa-search'></i>
              <input class='form-control text-sm-80' placeholder='Search' type='search' />
            </form>
          </div>
          <div class='d-flex flex-row flex-wrap p-0 mt-2'>

            <div *ngFor='let i of sampleArticles' class='col-12 col-sm-6 p-2'>
              <div class='article'>
                <div><img [alt]='i.title' [src]='i.coverImage' class='w-100'></div>
                <div class='p-2 mb-2'>
                  <div class='d-flex justify-content-between px-2 mb-2'>
                    <div><i aria-hidden='true' class='fa article-icon fa-thumbs-o-up'></i> &nbsp; <span
                      class='article-likes'>&nbsp; {{i.numberOfLikes}} likes</span></div>
                    <div><i aria-hidden='true' class='fa article-icon fa-bookmark-o'></i></div>
                  </div>
                  <div [style.color]='bodyTextColor' class='article-title'>{{i.title}}</div>
                  <div [style.color]='bodyTextColor' class='article-excerpt'>{{i.excerpt}}</div>
                </div>
              </div>
            </div>
          </div>
        </div>


      </div>
      <div class='col-12 col-md-3 pb-4 p-0'>
        <div class='widget-card'>
          <div [style.backgroundColor]='primaryColor' class='widget-card-header text-white'>
            Employee Assistance Program
          </div>
          <div class='w-100 text-center'>
            <div class='p-0'>
              <img alt='Show my rewards' class='w-100'
                   src='https://altiuslifestorage.blob.core.windows.net/article-images/DefaultContent.png'>
            </div>
            <div class='w-100 text-center p-2'><span [style.color]='primaryColor' class='fs-80-rem'> Book your
            confidential EAP appointment
            today. </span></div>
          </div>
        </div>


        <div class='widget-card mt-2'>
          <div [style.backgroundColor]='primaryColor' class='widget-card-header text-white boder'>
            Download the app
          </div>
          <div class='w-100 text-center'>
            <div class='p-2'>
              <img
                alt='download app links'
                class='w-100'
                src='assets/img/widgets/download_altiuslife.png'
              />
            </div>
            <div class='row'>
              <div class='col-6 pb-2'>
                <a class='btn btn-link-lg pl-2' target='_blank'>
                  <img [src]='appleImageSrc' alt='apple link' class='img-fluid' />
                </a>
              </div>
              <div class='col-6'>
                <a class='btn btn-link-lg pr-2' target='_blank'>
                  <img [src]='androidImageSrc' alt='android link' class='img-fluid' />
                </a>
              </div>
            </div>
          </div>
        </div>

      </div>

    </div>
  </div>

  <div class='modal-footer d-flex flex-row justify-content-end'>
    <button (click)='onClosePreview()' class='btn btn-outline-primary btn-md' type='button'>Close Preview</button>
  </div>
</section>
