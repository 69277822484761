import { Injectable } from '@angular/core';
import { UserService } from './user-service';
import { CategoryService } from './category-service';
import { FeatureService } from './feature-service';
import fileStack from 'filestack-js';
import { UtilService } from './util-service';

const defaultMaxImageSize = 1024 * 1024 * 4;// 4MB
const companyMaxImageSize = 1024 * 1024;// 1MB

@Injectable({
  providedIn: 'root',
})
export class FileStackService {
  client: any;

  constructor(
    private userService: UserService,
    private categoryService: CategoryService,
    private featureService: FeatureService,
    private utilService: UtilService,
  ) {
    this.client = fileStack.init(this.userService.getFileStackApiKey());
  }

  async pickSpecifiedPathImage(imagePath: string, maxSize = defaultMaxImageSize) {
    return await this.pickImage(imagePath, maxSize);
  }

  // todo
  async pickImage(path: string, maxSize = defaultMaxImageSize) {
    const fileName = `${path}${this.utilService.genUUID()}-`;
    let originalFileName;

    const pickOptions = {
      accept: 'image/*',
      maxFiles: 1,
      maxSize: maxSize,
      fromSources: ['local_file_system', 'imagesearch'],
      disableStorageKey: true,
      onFileSelected: (file) => {
        originalFileName = file.filename;
      },
      storeTo: {
        location: 'azure',
        path: fileName,
        access: 'public',
        container: this.userService.envVariables.blobStorage.container,
      },
      transformations: {
        crop: { circle: false },
      },
    };

    await this.client.pick(pickOptions);

    return fileName.replace(path, '') + originalFileName;
  }

  async pickCompanyImage(maxSize = companyMaxImageSize) {
    const imagePath = this.userService.companyImagesPathSegment;
    return await this.pickSpecifiedPathImage(imagePath, maxSize);
  }

  async pickCategoryImage(maxSize = defaultMaxImageSize) {
    const imagePath = this.categoryService.categoryImagesPathSegment;
    return await this.pickSpecifiedPathImage(imagePath, maxSize);
  }

  async pickFeatureImage(maxSize = defaultMaxImageSize) {
    const imagePath = this.featureService.featureImagesPathSegment;
    return await this.pickSpecifiedPathImage(imagePath, maxSize);
  }

  async pickPostImage() {
    return await this.pickImage('groups/posts/');
  }

  async pickTeamPostImage() {
    return await this.pickImage('team-post-images/');
  }

  async pickEAPImage(maxSize = 1024 * 1024 * 5) {
    const imagePath = 'eap-images/';
    return await this.pickSpecifiedPathImage(imagePath, maxSize);
  }

  async pickInfoPackFile() {
    const fileName = `info-packs/files/${this.utilService.genUUID()}-`;
    let originalFileName;

    const pickOptions = {
      accept: '.pdf',
      maxFiles: 1,
      maxSize: defaultMaxImageSize,
      fromSources: ['local_file_system'],
      disableStorageKey: true,
      onFileSelected: (file) => {
        originalFileName = file.filename;
      },
      storeTo: {
        location: 'azure',
        path: fileName,
        access: 'public',
        container: this.userService.envVariables.blobStorage.container,
      },
    };

    await this.client.pick(pickOptions);
    return fileName.replace('info-packs/files/', '') + originalFileName;
  }

  async pickProfileImage() {
    const fileName = `profile-images/${this.utilService.genUUID()}-`;
    let originalFileName;

    await this.client.pick({
      accept: 'image/*',
      maxFiles: 1,
      maxSize: defaultMaxImageSize,
      fromSources: ['local_file_system', 'imagesearch'],
      disableStorageKey: true,
      onFileSelected: (file) => {
        originalFileName = file.filename;
      },
      storeTo: {
        location: 'azure',
        path: fileName,
        access: 'public',
        container: this.userService.envVariables.blobStorage.container,
      },
      transformations: {
        crop: {
          aspectRatio: 1,
          circle: false,
          force: true,
        },
      },
      uploadInBackground: false,
    });

    return fileName.replace('profile-images/', '') + originalFileName;
  }

  async pickGroupLogo() {
    const fileName = `groups/logos/${this.utilService.genUUID()}-`;
    let originalFileName;

    await this.client.pick({
      accept: 'image/*',
      maxFiles: 1,
      maxSize: defaultMaxImageSize,
      fromSources: ['local_file_system', 'imagesearch'],
      disableStorageKey: true,
      onFileSelected: (file) => {
        originalFileName = file.filename;
      },
      storeTo: {
        location: 'azure',
        path: fileName,
        access: 'public',
        container: this.userService.envVariables.blobStorage.container,
      },
      transformations: {
        crop: { aspectRatio: 1, circle: true, force: true },
      },
    });

    return fileName.replace('groups/logos/', '') + originalFileName;
  }

  async pickGroupBanner() {
    const fileName = `/groups/banners/${this.utilService.genUUID()}-`;
    let originalFileName;

    await this.client.pick({
      accept: 'image/*',
      maxFiles: 1,
      maxSize: defaultMaxImageSize,
      fromSources: ['local_file_system', 'imagesearch'],
      disableStorageKey: true,
      onFileSelected: (file) => {
        originalFileName = file.filename;
      },
      storeTo: {
        location: 'azure',
        path: fileName,
        access: 'public',
        container: this.userService.envVariables.blobStorage.container,
      },
      transformations: {
        crop: { circle: false, force: true },
      },
      uploadInBackground: false,
    });

    return fileName.replace('groups/banners/', '') + originalFileName;
  }

  async pickMobileCarouselImg() {
    const fileName = `carousel-images/${this.utilService.genUUID()}-`;
    let originalFileName;

    await this.client.pick({
      accept: 'image/*',
      maxFiles: 1,
      // maxSize: 1024 * 1024 * 2,
      maxSize: defaultMaxImageSize,
      fromSources: 'local_file_system',
      disableStorageKey: true,
      onFileSelected: (file) => {
        originalFileName = file.filename;
      },
      storeTo: {
        location: 'azure',
        path: fileName,
        access: 'public',
        container: this.userService.envVariables.blobStorage.container,
      },
      transformations: {
        // maxDimensions: [900, 600],
        crop: {
          aspectRatio: 3 / 2,
          // force: true
        },
      },
      uploadInBackground: false,
    });

    return fileName.replace('carousel-images/', '') + originalFileName;
  }

  async pickDesktopCarouselImg() {
    const fileName = `carousel-images/${this.utilService.genUUID()}-`;
    let originalFileName;

    await this.client.pick({
      accept: 'image/*',
      maxFiles: 1,
      // maxSize: 1024 * 1024 * 2,
      maxSize: defaultMaxImageSize,
      fromSources: 'local_file_system',
      disableStorageKey: true,
      onFileSelected: (file) => {
        originalFileName = file.filename;
      },
      storeTo: {
        location: 'azure',
        path: fileName,
        access: 'public',
        container: this.userService.envVariables.blobStorage.container,
      },
      transformations: {
        // maxDimensions: [1600, 400],
        crop: {
          aspectRatio: 4,
          // force: true
        },
      },
      uploadInBackground: false,
    });

    return fileName.replace('carousel-images/', '') + originalFileName;
  }

  async pickArticleImg() {
    const fileName = `article-images/${this.utilService.genUUID()}-`;
    let originalFileName;

    await this.client.pick({
      accept: 'image/*',
      maxFiles: 1,
      maxSize: defaultMaxImageSize,
      fromSources: 'local_file_system',
      disableStorageKey: true,
      onFileSelected: (file) => {
        originalFileName = file.filename;
      },
      storeTo: {
        location: 'azure',
        path: fileName,
        access: 'public',
        container: this.userService.envVariables.blobStorage.container,
      },
      transformations: {
        maxDimensions: [1000, 666.6],
        crop: { aspectRatio: 1, circle: false },
      },
    });

    return fileName.replace('article-images/', '') + originalFileName;
  }

  async pickAuthorImage() {
    const fileName = `author-images/${this.utilService.genUUID()}-`;
    let originalFileName;

    await this.client.pick({
      accept: 'image/*',
      maxFiles: 1,
      maxSize: defaultMaxImageSize,
      fromSources: ['local_file_system', 'imagesearch'],
      disableStorageKey: true,
      onFileSelected: (file) => {
        originalFileName = file.filename;
      },
      storeTo: {
        location: 'azure',
        path: fileName,
        access: 'public',
        container: this.userService.envVariables.blobStorage.container,
      },
      transformations: {
        crop: {
          aspectRatio: 1,
          circle: false,
          force: true,
        },
      },
      uploadInBackground: false,
    });
    return fileName.replace('author-images/', '') + originalFileName;
  }

  async pickChallengeImg() {
    const fileName = `challenge-images/${this.utilService.genUUID()}-`;
    let originalFileName;

    await this.client.pick({
      accept: 'image/*',
      maxFiles: 1,
      // maxSize: 1024 * 1024 * 2,
      maxSize: defaultMaxImageSize,
      fromSources: 'local_file_system',
      disableStorageKey: true,
      onFileSelected: (file) => {
        originalFileName = file.filename;
      },
      storeTo: {
        location: 'azure',
        path: fileName,
        access: 'public',
        container: this.userService.envVariables.blobStorage.container,
      },
      transformations: {
        crop: {
          aspectRatio: 4,
          circle: false,
          force: true,
        },
      },
      uploadInBackground: false,
    });
    return fileName.replace('challenge-images/', '') + originalFileName;
  }

  async pickPartnerImg() {
    const fileName = `partner-images/${this.utilService.genUUID()}-`;
    let originalFileName;

    await this.client.pick({
      accept: 'image/*',
      maxFiles: 1,
      // maxSize: 1024 * 1024 * 2,
      maxSize: defaultMaxImageSize,
      fromSources: 'local_file_system',
      disableStorageKey: true,
      onFileSelected: (file) => {
        originalFileName = file.filename;
      },
      storeTo: {
        location: 'azure',
        path: fileName,
        access: 'public',
        container: this.userService.envVariables.blobStorage.container,
      },
      transformations: {
        maxDimensions: [900, 600],
        crop: {
          aspectRatio: 3 / 2,
          force: true,
        },
      },
      uploadInBackground: false,
    });

    return fileName.replace('partner-images/', '') + originalFileName;
  }

  async pickTeamImg() {
    const fileName = `team-images/${this.utilService.genUUID()}-`;
    let originalFileName;

    await this.client.pick({
      accept: 'image/*',
      maxFiles: 1,
      maxSize: defaultMaxImageSize,
      fromSources: 'local_file_system',
      disableStorageKey: true,
      onFileSelected: (file) => {
        originalFileName = file.filename;
      },
      storeTo: {
        location: 'azure',
        path: fileName,
        access: 'public',
        container: this.userService.envVariables.blobStorage.container,
      },
      transformations: {
        maxDimensions: [1000, 666.6],
        crop: { aspectRatio: 1, circle: false, force: true },
      },
      uploadInBackground: false,
    });

    return fileName.replace('team-images/', '') + originalFileName;
  }
}
