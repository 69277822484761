import { debounceTime } from 'rxjs/operators';
import { IQuestionnaire } from '../../_models/questionnaire';
import { CompanyService } from '../../../services/company-service';
import { Component, ElementRef, NgZone, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UtilService } from '../../../services/util-service';
import { QuestionnaireService } from '../../../services/questionnaire-service';
import { DateService } from '../../../services/date.service';
import { FeatureService } from '../../../services/feature-service';
import { ICompany } from '../../_models/company';
import { FileStackService } from '../../../services/file-stack.service';
import { Subscription } from 'rxjs';
import { BootstrapModalComponent } from '../../../components/bootstrap-modal/bootstrap-modal.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { IIdTypePair } from '../../_models/id-type-pair';
import { IIdBooleanNamePair } from '../../_models/id-boolean-name-pair';
import { IIdNamePair } from '../../_models/id-name-pair';
import { UserService } from '../../../services/user-service';
import { FEATURES } from '../../_shared/enums';
import { IQuestion } from '../../_models/question';
import { IResult } from '../../_models/result';
import { ICategory } from '../../_models/category';
import { CategoryService } from '../../../services/category-service';

@Component({
  selector: 'app-questionnaire-management',
  templateUrl: './questionnaire-management.component.html',
  styleUrls: ['./questionnaire-management.component.scss'],
})
export class QuestionnaireManagementComponent implements OnInit, OnDestroy {
  items: IQuestionnaire[];
  item: IQuestionnaire;
  questions: IQuestion[];
  results: IResult[];
  itemType = 'Questionnaire';
  columns = [];
  itemForm: FormGroup;
  yesNoItems: IIdBooleanNamePair[] = [];
  features = [];
  resultTypes: IIdTypePair[] = [];
  companies: ICompany[] = [];
  allCompanies: IIdNamePair[] = [];
  allCategories: ICategory[] = [];

  resultTypeDescriptions = {
    1: `Only one result regardless of the user's answers. A modal that includes the title and description and can have a button that redirects to a piece of content, partner, another questionnaire, the partner list, or the questionnaire list.`,
    2: `Different result depending on the user's answers. A modal that includes the title, description, result date and can have a button that redirects to a piece of content, partner, another questionnaire, the partner list, or the questionnaire list.`,
    4: `Different results depending on the user's answers. A full result page that includes the title, description, and history graphs (can be turned off)`,
  };
  startTimeSub: Subscription;
  endTimeSub: Subscription;

  isDisplayingList: boolean;
  isInitialised: boolean;
  isEditingItem: boolean;
  isAddingItem: boolean;
  canRespondToButtons: boolean;
  excludedCompanyIds: number[] = [];
  @ViewChild('questionnaireAccordionButton') questionnaireAccordionButton: ElementRef;
  @ViewChild('questionsAccordionButton') questionsAccordionButton: ElementRef;
  @ViewChild('resultsAccordionButton') resultsAccordionButton: ElementRef;

  isAddingQuestion: Boolean = false;
  isEditingQuestion: Boolean = false;
  editableQuestionId;

  isAddingResult: Boolean = false;
  isEditingResult: Boolean = false;
  editableResultId;
  editableResult;

  isCreatingDependentQuestion: Boolean = false;
  dependentQuestionParams;
  options: Object = {
    paragraphFormat: {
      N: 'Normal',
      H1: 'Heading 1',
      H2: 'Heading 2',
      H3: 'Heading 3',
      H4: 'Heading 4',
      H5: 'Heading 5',
      H6: 'Heading 6',
    },
    toolbarSticky: false,
    key: this.userService.envVariables.cms.froalaApiKey,
  };

  private canSelectCompany = false;

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private itemService: QuestionnaireService,
    private fb: FormBuilder,
    private dateService: DateService,
    private utilService: UtilService,
    private featureService: FeatureService,
    private companyService: CompanyService,
    private fileStackService: FileStackService,
    private userService: UserService,
    private ngZone: NgZone,
    private modalService: NgbModal,
    private categoryService: CategoryService,
  ) {
  }

  ngOnInit() {
    this.companyService.refreshCache();

    this.activatedRoute.params.subscribe(route => {
      this.analyseRoute(route);

      if (this.isDisplayingList) {
        this.getList();
      } else if (this.isEditingItem) {
        this.setUserRoleAuthorisations();
        this.getCompanies();
        this.initialiseEditItem(+route.id);
      } else if (this.isAddingItem) {
        this.setUserRoleAuthorisations();
        this.getCompanies();
        this.initialiseAddItem();
      }
    });
    this.getAllCategories();
  }

  private setUserRoleAuthorisations() {
    this.canSelectCompany = this.userService.isSuperAdminUser();
  }

  ngOnDestroy() {
    if (this.startTimeSub) {
      this.startTimeSub.unsubscribe();
    }

    if (this.endTimeSub) {
      this.endTimeSub.unsubscribe();
    }
  }

  private analyseRoute(route) {
    this.isDisplayingList = false;
    this.isEditingItem = false;
    this.isAddingItem = false;
    this.isInitialised = false;
    this.canRespondToButtons = false;

    if (!route.id) {
      this.isDisplayingList = true;
      return;
    }

    if (route.id === 'new') {
      this.isAddingItem = true;
    } else {
      this.isEditingItem = true;
    }
  }

  private getList() {
    this.itemService.getAllQuestionnaires()
      .subscribe(data => {
          this.items = data.map(i => {
            return [
              i.id,
              i.name,
              this.utilService.formatAsYesNo(i.active),
              this.dateService.formatDD_MMM_YYYY(i.startTime),
              this.dateService.formatDD_MMM_YYYY(i.endTime),
              `${this.featureService.featureName(i.featureId)} (${i.featureId})`,
              this.companyService.companyName(i.companyId) || 'All',
            ];
          });

          this.columns = ['id', 'name', 'active', 'Start Date', 'End Date', 'Feature', 'Company'];
          this.isInitialised = true;
          this.canRespondToButtons = true;
        },
        error => this.utilService.showToastError('Error while loading categories - ' + error));
  }

  private getFeatures() {
    this.features = [
      {
        id: this.featureService.ChanceToWinFeatureId,
        name: this.featureService.ChanceToWinFeatureName,
      },
      {
        id: this.featureService.HealthRiskAssessmentsFeatureId,
        name: this.featureService.HealthRiskAssessmentsFeatureName,
      },
    ];
  }

  createDependentForm(result) {
    this.isAddingQuestion = false;
    this.isEditingQuestion = false;
    this.isCreatingDependentQuestion = true;
    this.dependentQuestionParams = result;
  }

  private initialiseEditItem(id: number): void {
    this.getFeatures();
    this.itemService.getQuestionnaireDetails(id)
      .subscribe(data => {
        this.item = data;
        this.questions = data.liqQuestions;
        this.results = data.liqResults;
        this.questions.map(question => {
          const foundDependencyParams = this.questions.find(q => q.id === question.questionDependencyId);
          if (foundDependencyParams) {
            question.dependencyParams = foundDependencyParams;
          }
        });
        this.initialiseEditableFormGroup(data);
        this.isInitialised = true;
        this.canRespondToButtons = true;
      });
  }

  private initialiseAddItem(): void {
    this.getFeatures();
    this.item = this.itemService.getNewQuestionnaire();
    this.initialiseEditableFormGroup(this.item);
    this.isInitialised = true;
    this.canRespondToButtons = true;
  }

  private initialiseEditableFormGroup(item: IQuestionnaire) {
    this.yesNoItems = this.utilService.yesNoItems();
    this.companies = this.companyService.getAllCachedCompanies();
    this.resultTypes = this.itemService.allStaticResultTypes();

    this.itemForm = this.fb.group({
      nameControl: [item.name, Validators.required],
      activeControl: item.active,
      descriptionControl: item.description,
      summaryControl: item.summary,
      featureIdControl: [item.featureId, Validators.required],
      companyIdControl: item.companyId,
      startTimeControl: [this.dateService.toYearMonthDayObject(item.startTime), Validators.required],
      endTimeControl: [this.dateService.toYearMonthDayObject(item.endTime), Validators.required],
      bannerControl: item.banner,
      widgetImageControl: item.widgetImage,
      multipleAnswersControl: item.multipleAnswers,
      showHistoryControl: item.showHistory,
      liqResultTypeIdControl: +item.liqResultTypeId,
      termConditionsControl: item.termsConditions,
    });
    this.excludedCompanyIds = this.isAllCompanies()
      ? item.excludedCompanyIds
      : [];

    this.startTimeSub = this.itemForm.get('startTimeControl')
      .valueChanges.pipe(
        debounceTime(500))
      .subscribe(() => this.validateDateRange());

    this.endTimeSub = this.itemForm.get('endTimeControl')
      .valueChanges.pipe(
        debounceTime(500))
      .subscribe(() => this.validateDateRange());
  }

  isAllCompanies() {
    try {
      return this.itemForm.get('companyIdControl')
        && !this.itemForm.get('companyIdControl').value && this.canSelectCompany;
    } catch (error) {
      return false;
    }
  }

  getCompanies() {
    this.companyService.getAllCompanies()
      .subscribe((data) => {
        this.companies = data;
        this.allCompanies = this.companies.map(c => {
          return { id: c.id, name: c.name };
        });
      }, () => (this.companies = []));
  }

  onCompanySelected(companyId) {
    this.itemForm.patchValue({ companyIdControl: companyId });
  }

  excludedCompanyIdsSelected(selectedCompanyIds: number[]) {
    this.excludedCompanyIds = selectedCompanyIds;
  }

  private validateDateRange() {
    try {
      const startTimeControl = this.itemForm.get('startTimeControl');
      const endTimeControl = this.itemForm.get('endTimeControl');

      const lowerDate = this.dateService.fromYearMonthDayObject(startTimeControl.value);
      const upperDate = this.dateService.fromYearMonthDayObject(endTimeControl.value);

      const isValidLowerDate = !isNaN(lowerDate.getTime());
      const isValidUpperDate = !isNaN(upperDate.getTime());

      if (!isValidLowerDate || !isValidUpperDate) {
        return;
      }

      if (lowerDate <= upperDate) {
        endTimeControl.setErrors(null);
      } else {
        endTimeControl.setErrors({ dateRange: true });
      }
    } catch (error) {
    }
  }

  private markAllControlsAsTouched() {
    Object.keys(this.itemForm.controls).forEach(field => {
      const control = this.itemForm.get(field);
      control.markAsTouched({ onlySelf: true });
    });
  }

  isFieldInvalid(field: string) {
    return !this.itemForm.get(field).valid && this.itemForm.get(field).touched;
  }

  displayFieldCss(field: string) {
    return {
      'is-invalid': this.isFieldInvalid(field),
    };
  }

  private navigateToList() {
    this.router.navigate(['/cms/questionnaires']);
  }

  private persistItem() {

    this.canRespondToButtons = false;
    let itemToPersist: IQuestionnaire;

    try {
      itemToPersist = {
        id: this.item.id,
        name: this.itemForm.get('nameControl').value,
        description: this.itemForm.get('descriptionControl').value,
        summary: this.itemForm.get('summaryControl').value,
        active: this.itemForm.get('activeControl').value,
        multipleAnswers: this.itemForm.get('multipleAnswersControl').value,
        showHistory: this.itemForm.get('showHistoryControl').value,
        featureId: this.itemForm.get('featureIdControl').value,
        companyId: this.canSelectCompany ? Number.parseInt(this.itemForm.get('companyIdControl').value) : this.userService.company.id,
        startTime: this.dateService.fromYearMonthDayObject(this.itemForm.get('startTimeControl').value),
        endTime: this.dateService.fromYearMonthDayObject(this.itemForm.get('endTimeControl').value),
        liqResultTypeId: +this.itemForm.get('liqResultTypeIdControl').value,
        banner: this.itemForm.get('bannerControl').value,
        widgetImage: this.itemForm.get('widgetImageControl').value,
        termsConditions: this.itemForm.get('termConditionsControl').value,
        liqUserAnswers: this.item.liqUserAnswers,
        excludedCompanyIds: this.isAllCompanies()
          ? this.excludedCompanyIds
          : [],
      };

    } catch (error) {
      this.utilService.showToastError('Error persisting item - ' + error);
      this.canRespondToButtons = true;
      return;
    }

    if (this.item.liqResultTypeId !== itemToPersist.liqResultTypeId) {
      const modal = this.modalService.open(BootstrapModalComponent);
      modal.componentInstance.title = `Changing the result type will delete the existing results`;
      modal.componentInstance.message = `Are you sure you want to continue?`;
      modal.componentInstance.confirmButtonLabel = 'Yes, continue';

      modal.result.then(isConfirmed => {
        if (!isConfirmed) {
          return;
        }
        this.results.map(result => {
          this.itemService.deleteResult(result.id)
            .subscribe(() => {
            });
        });
        this.results = [];
        if (this.isEditingItem) {
          this.updateItem(itemToPersist);
        } else if (this.isAddingItem) {
          this.addNewItem(itemToPersist);
        }
      });
    }
    if (this.isEditingItem) {
      this.updateItem(itemToPersist);
    } else if (this.isAddingItem) {
      this.addNewItem(itemToPersist);
    }
  }

  private updateItem(itemToPersist: IQuestionnaire) {
    this.itemService.updateQuestionnaire(itemToPersist)
      .subscribe((data) => {
          this.item = data;
          this.item.liqQuestions = this.questions;
          this.item.liqResults = this.results;

          this.canRespondToButtons = true;
          this.utilService.showToastSuccess(`Update ${this.itemType} - ${itemToPersist.name}`);
          this.questionnaireAccordionButton.nativeElement.click();
          this.itemForm.markAsPristine();
        },
        error => {
          this.utilService.showToastError(`Error while updating item - ${error}`);
          this.canRespondToButtons = true;
        });
  }

  finishedResultEdit(result, type) {
    if (type === 'add') {
      this.results.push(result);
    } else {
      this.results = this.results.map(res => res.id === result.id ? result : res);
    }
    this.isAddingResult = false;
    this.isEditingResult = false;
  }

  private addNewItem(itemToPersist: IQuestionnaire) {
    this.itemService.addQuestionnaire(itemToPersist)
      .subscribe((data) => {
          this.item = data;
          this.canRespondToButtons = true;
          this.utilService.showToastSuccess(`Added new ${this.itemType} - ${itemToPersist.name}`);
          this.questionnaireAccordionButton.nativeElement.click();
          this.itemForm.markAsPristine();
        },
        error => {
          this.utilService.showToastError(`Error while saving item - ${error}`);
          this.canRespondToButtons = true;
        });
  }

  onBannerClick() {
    this.fileStackService.pickSpecifiedPathImage('questionnaire-images/')
      .then(result => this.ngZone.run(() => this.itemForm.patchValue({ bannerControl: result })))
      .catch(err => this.utilService.showToastError('Error while uploading the banner', err));
  }

  onWidgetImageClick() {
    this.fileStackService.pickSpecifiedPathImage('questionnaire-images/')
      .then(result => this.ngZone.run(() => this.itemForm.patchValue({ widgetImageControl: result })))
      .catch(err => this.utilService.showToastError('Error while uploading the banner', err));
  }

  onBackClick() {
    this.navigateToList();
  }

  onSaveClick() {

    if (!this.itemForm.valid) {
      this.markAllControlsAsTouched();
      return;
    }

    this.persistItem();
  }

  addQuestion() {
    this.isAddingQuestion = true;
    this.isEditingQuestion = false;
  }

  addResult() {
    this.isAddingResult = true;
    this.isEditingResult = false;
  }

  editQuestion(questionId) {
    this.isAddingQuestion = false;
    this.isEditingQuestion = true;
    this.editableQuestionId = questionId;
  }

  isCompetition() {
    return +this.itemForm.get('featureIdControl').value === FEATURES.CHANCE_TO_WIN;
  }

  finishedQuestionEdit(question) {
    if (question) {
      const existingQuestion = this.questions.find(q => q.id === question.id);
      if (!existingQuestion) {
        this.questions.push(question);
      }
    }
    this.isAddingQuestion = false;
    this.isEditingQuestion = false;
    this.isCreatingDependentQuestion = false;
  }

  editResult(resultId) {
    this.isEditingResult = true;
    this.isAddingResult = false;
    this.editableResultId = resultId;
    this.editableResult = this.results.find(res => res.id === resultId);
  }

  deleteQuestion(question) {
    const modal = this.modalService.open(BootstrapModalComponent);
    modal.componentInstance.title = `Deleting question`;
    modal.componentInstance.message = `Confirm deleting this question '${question.question}`;
    modal.componentInstance.confirmButtonLabel = 'Delete';

    modal.result.then(isConfirmed => {
      if (!isConfirmed) {
        return;
      }

      this.itemService.deleteQuestion(question.id)
        .subscribe(() => {
            this.questions = this.questions.filter(q => q.id !== question.id);
            this.utilService.showToastSuccess('Question deleted.');
          },
          error => this.utilService.showToastError('Error while deleting question - ' + error));
    }, () => {
    });
  }

  deleteResult(result) {
    const modal = this.modalService.open(BootstrapModalComponent);
    modal.componentInstance.title = `Deleting result`;
    modal.componentInstance.message = `Confirm deleting this result ${result.title}`;
    modal.componentInstance.confirmButtonLabel = 'Delete';

    modal.result.then(isConfirmed => {
      if (!isConfirmed) {
        return;
      }

      this.itemService.deleteResult(result.id)
        .subscribe(() => {
            this.results = this.results.filter(q => q.id !== result.id);
            this.utilService.showToastSuccess('Result deleted.');
          },
          error => this.utilService.showToastError('Error while deleting result - ' + error));
    }, () => {
    });
  }

  isResultType1WnoDependency() {
    return this.item.liqResultTypeId === 1 && this.results && this.results[0] && !this.results[0].answerDependencyId;
  }

  private getAllCategories() {
    this.categoryService.getCategories()
      .pipe()
      .subscribe(res => {
        this.allCategories = res;
      });
  }
}
