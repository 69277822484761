import { CmsComponent } from './cms.component';
import { CmsRoutingModule } from './cms-routing.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatSelectModule } from '@angular/material/select';
import { ContentReportingComponent } from './content-reporting/content-reporting.component';
import { UsersReportingComponent } from './users-reporting/users-reporting.component';
import { AnalyticsReportsComponent } from './analytics-reporting/analytics-reporting.component';
import { LifeActionManagementComponent } from './life-action-management/life-action-management.component';
import { QuestionManagementComponent } from './questionnaire-management/question-management/question-management.component';
import { QuestionnaireManagementComponent } from './questionnaire-management/questionnaire-management.component';
import { CategoryManagementComponent } from './category-management/category-management.component';
import { ConnectionManagementComponent } from './connection-management/connection-management.component';
import { LanguageManagementComponent } from './language-management/language-management.component';
import { BadgeManagementComponent } from './badge-management/badge-management.component';
import { CompanyManagementComponent } from './company-management/company-management.component';
import { CarouselManagementComponent } from './carousel-management/carousel-management.component';
import { ArticleManagementComponent } from './article-management/article-management.component';
import { AcknowledgementOfCountryManagementComponent } from './acknowledgement-of-country-management/acknowledgement-of-country-management.component';
import { InformationPacksManagementComponent } from './information-packs-management/information-packs-management.component';
import { EAPManagementComponent } from './eap-management/eap-management.component';
import { PeerSupportManagementComponent } from './peer-support-management/peer-support-management.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GroupManagementComponent } from './group-management/group-management.component';
import { ResultManagementComponent } from './questionnaire-management/result-management/result-management.component';
import { VideoManagementComponent } from './video-management/video-management.component';
import { RecipeManagementComponent } from './recipe-management/recipe-management.component';
import { UserManagementComponent } from './user-management/user-management.component';
import { TrackMyHealthReportingComponent } from './track-my-health-reporting/track-my-health-reporting.component';
import { RecipeIngredientManagementComponent } from './recipe-management/recipe-ingredient-management/recipe-ingredient-management.component';
import { RecipeMethodManagementComponent } from './recipe-management/recipe-method-management/recipe-method-management.component';
import { RecipeNutritionManagementComponent } from './recipe-method/recipe-nutrition-management/recipe-nutrition-management.component';
import { AnswerManagementComponent } from './questionnaire-management/answer-management/answer-management.component';
import { CmsListViewComponent } from './cms-list-view/cms-list-view.component';
import { CmsListSelectComponent } from './cms-list-select/cms-list-select.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { SharedModule } from '../_shared/shared.module';
import { FroalaEditorModule, FroalaViewModule } from 'angular-froala-wysiwyg';
import { DateSelectorComponent } from './date-selector/date-selector.component';
import { SelectCompanyComponent } from './select-company/select-company.component';
import { SelectCompaniesComponent } from './select-companies/select-companies.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ColorPickerModule } from 'ngx-color-picker';
import { NgxEditorModule } from 'ngx-editor';
import { RangeAnswersEditComponent } from './questionnaire-management/range-answers-edit/range-answers-edit.component';
import { AuthorManagementComponent } from './author-management/author-management.component';
import { ChallengesManagementComponent } from './challenges-management/challenges-management.component';
import { TeamsManagementComponent } from './teams-management/teams-management.component';
import { PartnerManagementComponent } from './partner-management/partner-management.component';
import { TeamsReportingComponent } from './teams-reporting/teams-reporting.component';
import { InsightsReportingComponent } from './insights-reporting/insights-reporting.component';
import { MarketingPageManagementComponent } from './marketing-page/marketing-page-management.component';
import { GenerateLinkDialogComponent } from './generate-link-dialog/generate-link-dialog.component';
import { MarketingBrochureComponent } from './marketing-brochure/marketing-brochure.component';
import { FeatureNamePipe } from '../_shared/pipes/featureName.pipe';
import { MenuConfigurationManagementComponent } from './menu-configuration-management/menu-configuration-management.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { FeatureManagementComponent } from './feature-management/feature-management.component';
import { MultipleSelectComponent } from './multiple-select/multiple-select.component';
import { EapBulkUpdateComponent } from './eap-bulk-update/eap-bulk-update.component';
import { MarketingPosterComponent } from './marketing-poster/marketing-poster.component';
import { BookingPosterComponent } from './booking-poster/booking-poster.component';
import { CompanySummaryComponent } from './company-summary/company-summary.component';
import { QRCodeModule } from 'angularx-qrcode';
import { LifeActionsReportingComponent } from './life-actions-reporting/life-actions-reporting.component';
import { PosterBrochureComponent } from './posters-brochures/posters-brochures.component';
import { MarketingTemplateDetailComponent } from './marketing-template-detail/marketing-template-detail.component';

@NgModule({
  declarations: [
    CmsComponent,
    CmsListViewComponent,
    CmsListSelectComponent,
    ArticleManagementComponent,
    MarketingPageManagementComponent,
    AcknowledgementOfCountryManagementComponent,
    EapBulkUpdateComponent,
    InformationPacksManagementComponent,
    EAPManagementComponent,
    PeerSupportManagementComponent,
    CarouselManagementComponent,
    CompanyManagementComponent,
    CompanySummaryComponent,
    BadgeManagementComponent,
    GroupManagementComponent,
    CategoryManagementComponent,
    ConnectionManagementComponent,
    LanguageManagementComponent,
    QuestionnaireManagementComponent,
    QuestionManagementComponent,
    AnswerManagementComponent,
    RangeAnswersEditComponent,
    ResultManagementComponent,
    VideoManagementComponent,
    RecipeManagementComponent,
    RecipeIngredientManagementComponent,
    RecipeMethodManagementComponent,
    RecipeNutritionManagementComponent,
    UserManagementComponent,
    LifeActionManagementComponent,
    AnalyticsReportsComponent,
    UsersReportingComponent,
    ContentReportingComponent,
    TrackMyHealthReportingComponent,
    DateSelectorComponent,
    SelectCompanyComponent,
    SelectCompaniesComponent,
    AuthorManagementComponent,
    ChallengesManagementComponent,
    TeamsManagementComponent,
    PartnerManagementComponent,
    TeamsReportingComponent,
    InsightsReportingComponent,
    GenerateLinkDialogComponent,
    MarketingBrochureComponent,
    MarketingPosterComponent,
    BookingPosterComponent,
    MarketingTemplateDetailComponent,
    MenuConfigurationManagementComponent,
    FeatureManagementComponent,
    MultipleSelectComponent,
    LifeActionsReportingComponent,
    PosterBrochureComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    NgxPaginationModule,
    NgxEditorModule,
    ColorPickerModule,
    FroalaEditorModule.forRoot(),
    FroalaViewModule.forRoot(),
    CmsRoutingModule,
    SharedModule,
    MatSelectModule,
    DragDropModule,
    QRCodeModule,
  ],
  entryComponents: [
    CmsListSelectComponent,
    AnswerManagementComponent,
    RecipeIngredientManagementComponent,
    RecipeMethodManagementComponent,
    RecipeNutritionManagementComponent,
    GenerateLinkDialogComponent,
  ],
  providers: [
    FeatureNamePipe,
  ],
})
export class CmsModule {
}
