import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { debounceTime } from 'rxjs/operators';
import { Component, NgZone, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { IChallenge } from '../../_models/challenge';
import { ChallengeService } from '../../../services/challenge-service';
import { DateService } from '../../../services/date.service';
import { Subscription } from 'rxjs';
import { UserService } from '../../../services/user-service';
import { environment } from '../../../environments/_active-environment/environment';
import { FileStackService } from '../../../services/file-stack.service';
import { UtilService } from '../../../services/util-service';
import { IIdNamePair } from '../../_models/id-name-pair';
import { CompanyService } from '../../../services/company-service';
import { BootstrapModalComponent } from '../../../components/bootstrap-modal/bootstrap-modal.component';
import * as moment from 'moment';
import { FroalaService } from '../froala-service';


@Component({
  selector: 'app-challenges-management',
  templateUrl: './challenges-management.component.html',
  styleUrls: ['./challenges-management.component.scss']
})
export class ChallengesManagementComponent implements OnInit {


  items: IChallenge[];
  item: IChallenge;

  columns = [];
  fields: any = {};
  minDate: Object;
  loading = false;
  itemForm: FormGroup;
  id: number;
  error = '';
  startTimeSub: Subscription;
  endTimeSub: Subscription;
  displayStartTimeSub: Subscription;
  displayEndTimeSub: Subscription;
  typeSelected: number;
  primaryColor: string;
  envVariables: any;
  teamTypeClass: string;
  individualTypeClass: string;

  isDisplayingList: boolean;
  isInitialised: boolean;
  isEditingItem: boolean;
  isAddingItem: boolean;
  canRespondToButtons: boolean;

  createSectionOne: boolean;
  createSectionTwo: boolean;
  createSectionThree: boolean;
  p_One: string;
  p_Two: string;
  p_Three: string;
  action: string;

  companies = [];
  allCompanies: IIdNamePair[] = [];
  excludedCompanyIds: number[] = [];
  selectItem: number[] = [];

  selectedGoalTypeId: any = null;
  goaltypes = [];
  allGoalTypes: IIdNamePair[] = [];

  private canSelectCompany = false;

  private options: any = {
    events: {
      'froalaEditor.link.beforeInsert': function (e, editor, link, text, attrs) {
        if (link.indexOf('://') < 0) {
          attrs['data-contenttype'] = link.split('/').slice(-2)[0];
          attrs['data-internallink'] = true;
          attrs['data-contentid'] = link.split('/').slice(-1)[0];
        }
      },
    },
    placeholderText: 'Edit Content Here',
    paragraphFormat: {
      N: 'Normal',
      H1: 'Heading 1',
      H2: 'Heading 2',
      H3: 'Heading 3',
      H4: 'Heading 4',
      H5: 'Heading 5',
      H6: 'Heading 6'
    },
    linkList: [
      {
        text: 'Article Link (update)',
        href: `/home/article/:id`
      },
      {
        text: 'Recipe Link (update)',
        href: `/home/recipe/:id`
      },
      {
        text: 'Video Link (update)',
        href: `/home/video/:id`
      },
      {
        text: 'External Link',
        href: 'https://'
      }
    ],
    toolbarSticky: false,
    key: this.userService.getFroalaApiKey(),
  };
  public initControls: any;

  constructor(
    private challengeService: ChallengeService,
    private router: Router,
    public activatedRoute: ActivatedRoute,
    private fb: FormBuilder,
    private dateService: DateService,
    private userService: UserService,
    private filestackService: FileStackService,
    private ngZone: NgZone,
    private utilService: UtilService,
    private companyService: CompanyService,
    private modalService: NgbModal,
    private froalaService: FroalaService,
  ) {
    this.envVariables = environment;
  }

  ngOnInit() {

    this.activatedRoute.params
    .subscribe(route => {
      this.analyseRoute(route);

      if (this.isDisplayingList) {
        this.getList();
      } else {
        this.isInitialised = false;
        this.canRespondToButtons = false;
        this.setUserRoleAuthorisations();
        this.getGoalTypes();
        if (this.isEditingItem) {
          //this.initialiseEditItem( + route.id);
          this.getCompanies(+ route.id);
        } else if (this.isAddingItem) {
          //this.initialiseAddItem();
          this.getCompanies();
        }
      }
    });
  }

  private setUserRoleAuthorisations() {
    this.canSelectCompany = this.userService.isSuperAdminUser();
  }

  public initialize(initControls) {
    try {
      this.options.imageAllowedTypes = this.froalaService.getAllowedImageOptions();
      this.options.imageUploadURL= `${environment.app.endpoint}/blob-storage/upload`;
      this.options.imageUploadMethod= 'POST';
      this.options.imageUploadParams= {folderPath: 'challenge-images'};
      this.options.events['froalaEditor.image.beforeUpload'] =  (e, editor, images) => {
        const requestHeaders = editor.opts.requestHeaders || {};
        editor.opts.requestHeaders = {
          ...requestHeaders,
          Authorization: this.userService.token,
        };
      };
      this.initControls = initControls;
      this.initControls.initialize();
    } catch (error) {
      this.utilService.showToastError('Error while uploading items - ' + error)
    }
  }

  getList() {
    //DODO normal user api need to replace
    this.challengeService.getAllChallenges().subscribe(
      (data) => {
        this.items = data;
        this.items.forEach(element => {
          if (element.name.length >= 14){
            const removeLength = element.name.length - 14;
            element.name = element.name.substring(0, element.name.length - removeLength) + '...';
          }
          element.image = element.image ? this.userService.envVariables.blobStorage.url + 'challenge-images/' + element.image : null;
        });

        this.isInitialised = true;
        this.canRespondToButtons = true;
      },
      (error) => this.utilService.showToastError('Error while loading items - ' + error)
    );
  }

  private getCompanies(id?: number) {
    this.companyService.getAllCompanies()
      .subscribe(data => {
          this.companies = data;
          this.allCompanies = this.companies.map(c => {
            return { id: c.id, name: c.name };
          });

          if (id) {
            this.initialiseEditItem(id);
          } else {
            this.initialiseAddItem();
          }
        },
        (err) => {
          this.companies = [];
          this.utilService.showToastError('Error while loading companies', err);
        }
      );
    }

  private analyseRoute(route) {
    this.isDisplayingList = false;
    this.isEditingItem = false;
    this.isAddingItem = false;
    this.isInitialised = false;
    this.canRespondToButtons = false;

    if (!route.id) {
      this.isDisplayingList = true;
      return;
    }

    if (route.id === 'new') {
      this.isAddingItem = true;
    } else {
      this.isEditingItem = true;
    }
  }

  initialiseEditItem(id) {
    this.challengeService.getChallengesById(id).subscribe((data) => {

      this.item = data;
      this.selectedGoalTypeId = this.item.goalTypeId;
      this.initialiseEditableFormGroup(data);
    });
  }

  private initialiseAddItem(): void {
    // console.log('env', this.envVariables.theme.primaryColor);
    // this.userService.getLoggedInUserCompany()
    // .subscribe(companies => {
    //   if (!companies.length) {
    //     this.primaryColor = this.envVariables.theme.primaryColor;
    //     return;
    //    }
    //   this.primaryColor = companies[0].primaryColor;
    //   console.log('api', this.primaryColor);
    // });

    this.item = this.challengeService.getNewChallenge();
    this.initialiseEditableFormGroup(this.item);
  }

  private initialiseEditableFormGroup(item: IChallenge) {
    this.itemForm = this.fb.group({
      nameControl: [item.name, Validators.required],
      startTimeControl: [this.dateService.toYearMonthDayObject(item.startDate), Validators.required],
      endTimeControl: [this.dateService.toYearMonthDayObject(item.endDate), Validators.required],
      displayStartTimeControl: [this.dateService.toYearMonthDayObject(item.displayStartDate), Validators.required],
      displayEndTimeControl: [this.dateService.toYearMonthDayObject(item.displayEndDate), Validators.required],
      image: [item.image],
      descriptionControl: [item.description, Validators.max(250)],
      companyId: item.companyId,
      challengeGoalControl: [item.challengeGoal],
      goalTypeId: [this.selectedGoalTypeId]
    });

    this.excludedCompanyIds = this.isAllCompanies()
    ? item.excludedCompanyIds
    : [];

    this.startTimeSub = this.itemForm.get('startTimeControl')
    .valueChanges.pipe(
      debounceTime(500))
    .subscribe(() => this.validateDateRange());

    this.endTimeSub = this.itemForm.get('endTimeControl')
    .valueChanges.pipe(
      debounceTime(500))
    .subscribe(() => this.validateDateRange());

    this.displayStartTimeSub = this.itemForm.get('displayStartTimeControl')
    .valueChanges.pipe(
      debounceTime(500))
    .subscribe(() => this.validateDateRange());

    this.displayEndTimeSub = this.itemForm.get('displayEndTimeControl')
    .valueChanges.pipe(
      debounceTime(500))
    .subscribe(() => this.validateDateRange());

    if (item.id == 0){
      this.isAddingItem = true;
      this.isEditingItem = false;
      this.typeSelected = 1;
      this.teamTypeClass = 'card t-challenge';
      this.individualTypeClass = 'card';
    }else{
      this.isEditingItem = true;
      this.isAddingItem = false;
      this.typeSelected = item.type;
       this.typeClick(item.type);
    }
    this.createSectionOne = true;
    this.createSectionTwo = false;
    this.createSectionThree = false;
    this.p_One = 'active';

    this.isInitialised = true;
    this.canRespondToButtons = true;


  }

  isAllCompanies() {
    try {
      return this.itemForm.get('companyId')
        && !this.itemForm.get('companyId').value
        && this.canSelectCompany;
    } catch (error) {
      return false;
    }
  }

  private validateDateRange() {
    try {
      const startTimeControl = this.itemForm.get('startTimeControl');
      const endTimeControl = this.itemForm.get('endTimeControl');

      const lowerDate = this.dateService.fromYearMonthDayObject(startTimeControl.value);
      const upperDate = this.dateService.fromYearMonthDayObject(endTimeControl.value);

      const isValidLowerDate = !isNaN(lowerDate.getTime());
      const isValidUpperDate = !isNaN(upperDate.getTime());

      if (!isValidLowerDate || !isValidUpperDate) {
        return;
      }

      if (lowerDate <= upperDate) {
        endTimeControl.setErrors(null);
      } else {
        endTimeControl.setErrors({ dateRange: true });
      }
    } catch (error) { }
  }

  isFieldInvalid(field: string) {
    return !this.itemForm.get(field).valid && this.itemForm.get(field).touched;
  }

  displayFieldCss(field: string) {
    return {
      'is-invalid': this.isFieldInvalid(field)
    };
  }

  onCompanySelected(companyId) {
    this.itemForm.patchValue({ companyId: companyId });
  }

  excludedCompanyIdsSelected(selectedCompanyIds: number[]) {
    this.excludedCompanyIds = selectedCompanyIds;
  }

  typeClick(id){
    this.typeSelected = id;
    if (id == 1){
      this.teamTypeClass = 'card t-challenge';
      this.individualTypeClass = 'card';
    }else{
      this.teamTypeClass = 'card';
      this.individualTypeClass = 'card t-challenge';
    }
  }

  goToSectionOne(){
    this.createSectionOne = true;
    this.createSectionTwo = false;
    this.createSectionThree = false;
    this.p_One = 'active';
    this.p_Two = '';
    this.p_Three = '';

    this.itemForm.get('image').setValidators([]); // or clearValidators()
    this.itemForm.get('image').updateValueAndValidity();

    this.itemForm.get('goalTypeId').setValidators([]);
    this.itemForm.get('goalTypeId').updateValueAndValidity();
  }

  goToSectionTwo(){
    this.p_One = 'active';
    this.p_Two = 'active';
    this.p_Three = '';
    this.createSectionOne = false;
    this.createSectionTwo = true;
    this.createSectionThree = false;

    this.itemForm.get('image').setValidators([Validators.required]);
    this.itemForm.get('image').updateValueAndValidity();

    this.itemForm.get('goalTypeId').setValidators([Validators.required]);
    this.itemForm.get('goalTypeId').updateValueAndValidity();
  }

  goToSectionThree(){
    this.createSectionOne = false;
    this.createSectionTwo = false;
    this.createSectionThree = true;
    this.p_One = 'active';
    this.p_Two = 'active';
    this.p_Three = 'active';
    this.challengeService.notifyChallengesChanged();
  }

  openfilestack() {
    this.filestackService.pickChallengeImg().then(
      (res) => {
        this.ngZone.run(() => this.itemForm.patchValue({ image: res }));
      },
      (err) => {
        this.utilService.showToastError('Error while uploading picture', err);
      }
    );
  }

  private markAllControlsAsTouched() {
    Object.keys(this.itemForm.controls).forEach((field) => {
      const control = this.itemForm.get(field);
      control.markAsTouched({ onlySelf: true });
    });
  }

  onSaveClick() {
    if (!this.itemForm.valid) {
      this.markAllControlsAsTouched();
      return;
    }
    this.persistItem();
  }

  private persistItem() {
    this.canRespondToButtons = false;
    let challange: IChallenge;
    try {

      const startDate = moment
        .parseZone(this.dateService.fromYearMonthDayObject(this.itemForm.get('startTimeControl').value))
        .format('YYYY-MM-DD');
      const displayStartDate = moment
        .parseZone(this.dateService.fromYearMonthDayObject(this.itemForm.get('displayStartTimeControl').value))
        .format('YYYY-MM-DD');
      const endDate = moment
        .parseZone(this.dateService.fromYearMonthDayObject(this.itemForm.get('endTimeControl').value).setHours(23, 59, 59))
        .format('YYYY-MM-DD HH:mm:ss');
      const displayEndDate = moment
        .parseZone(this.dateService.fromYearMonthDayObject(this.itemForm.get('displayEndTimeControl').value).setHours(23, 59, 59))
        .format('YYYY-MM-DD HH:mm:ss');


      challange = {
        name: this.itemForm.get('nameControl').value,
        image: this.itemForm.get('image').value,
        startDate,
        endDate,
        displayStartDate,
        displayEndDate,
        type: this.typeSelected,
        description: this.itemForm.get('descriptionControl').value,
        companyId: + this.itemForm.get('companyId').value > 0
          ? +this.itemForm.get('companyId').value
          : null,
        excludedCompanyIds: this.isAllCompanies()
          ? this.excludedCompanyIds
          : [],
        goalTypeId: this.itemForm.get('goalTypeId').value,
        challengeGoal: this.itemForm.get('challengeGoalControl').value
      };

      if (!this.canSelectCompany) {
        challange.companyId = this.userService.company.id;
      }

      if (this.item.id !== 0) {
        challange.id = this.item.id;
      }
    } catch (error) {
      const errorMsg = 'Error persisting item - ' + error;
      this.utilService.showToastError(errorMsg);
      this.canRespondToButtons = true;
      return;
    }

    if (this.isEditingItem) {
      this.updateItem(challange);
    } else if (this.isAddingItem) {
      this.addNewItem(challange);
    }
  }

  private addNewItem(body) {
    this.challengeService.addChallenge(body)
      .subscribe(() => {
        this.itemForm.reset();
        this.canRespondToButtons = true;
        const msg = `Added new challenge - ${body.name}`;
        this.utilService.showToastSuccess(msg);
        this.action = 'created';
        this.goToSectionThree();
      },
        (error) => {
          const errorMsg = `Error while adding item - ${error}`;
          this.utilService.showToastError(errorMsg);
          this.canRespondToButtons = true;
        }
      );
  }

  private updateItem(body) {
    this.challengeService.updateChallenge(body, body.id)
      .subscribe(() => {
        this.itemForm.reset();
        this.canRespondToButtons = true;
        const msg = `Updated challenge - ${body.name}`;
        this.utilService.showToastSuccess(msg);
        this.action = 'updated';
        this.goToSectionThree();
      },
        (error) => {
          const errorMsg = `Error while saving item - ${error}`;
          this.utilService.showToastError(errorMsg);
          this.canRespondToButtons = true;
        }
      );
  }

  private navigateToList() {
    this.router.navigate(['/cms/challenges']);
  }

  private navigateTeams() {
    this.router.navigate(['/cms/teams']);
  }

  goToDetailLink(id){
    this.isDisplayingList = false;
    this.router.navigate(['/cms/challenges/' + id ]);
  }

  onDeleteClick(){
    if (!this.isEditingItem) {
      return;
    }
    this.deleteItem();
  }

  private deleteItem() {
    const modal = this.modalService.open(BootstrapModalComponent);
    modal.componentInstance.title = 'Confirm deleting this challenge';
    modal.componentInstance.confirmButtonLabel = 'Delete';

    modal.result.then(
      (isConfirmed) => {
        if (!isConfirmed) {
          return;
        }
        this.challengeService.deleteChallenge(this.item.id)
          .subscribe(() => {
            this.itemForm.reset();
            this.utilService.showToastSuccess(`Challenge - ${this.item.name} - deleted`);
            this.challengeService.notifyChallengesChanged();
            this.navigateToList();
          },
          (error) => this.utilService.showToastError('Error while deleting item - ' + error)
        );
      },
      () => {}
    );
  }

  onCheckClick(id) {
    if (this.selectItem && this.selectItem.indexOf(id) !== -1) {
      this.selectItem = this.selectItem.filter(item => item !== id);
    } else {
      this.selectItem.push(id);
    }
  }

  onMultiDeleteClick(){
    const modal = this.modalService.open(BootstrapModalComponent);
    const speltNumber = this.utilService.spellNumber(this.selectItem.length).toUpperCase();
    modal.componentInstance.title = `Confirm deleting ${speltNumber} challenge(s)`;
    modal.componentInstance.confirmButtonLabel = 'Delete';

    modal.result.then(
      isConfirmed => {
        if (!isConfirmed) {
          return;
        }
        const lastId = this.selectItem[this.selectItem.length - 1];
        this.selectItem.forEach(id => this.deleteItemForId(+id, lastId));
      },
      () => {}
    );
  }

  private deleteItemForId(id: number, lastId: number) {
    this.challengeService.deleteChallenge(id).subscribe(
      () => {
        this.utilService.showToastSuccess('Deleted item id ' + id);
        if (id === lastId){
          this.challengeService.notifyChallengesChanged();
        }
        this.removeIdFromLists(id);
      },
        err => this.utilService.showToastError('Error while deleting item id ' + id, err)
      );
  }

  private removeIdFromLists(id: number) {
    this.selectItem = this.selectItem.filter(item => item !== id);
    this.items = this.items.filter(item => item.id !== id);
  }

  onGoalTypeIdChange(goalTypeId: number) {
    this.selectedGoalTypeId = goalTypeId;
  }

  getGoalTypes() {
    this.challengeService.getGoalTypes().subscribe(
      (data) => {
       this.goaltypes = data;

       this.allGoalTypes = this.goaltypes.map(c => {
         return { id: c.id, name: c.name };
       });
      },
      (error) => this.utilService.showToastError('Error while loading items - ' + error)
    );
  }
}
