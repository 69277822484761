import { IEnvironment } from '../../app/_models/environment-config';

export const environment: IEnvironment = {
  name: 'altiuslife-qa',
  title: 'AltiusLife',
  insightsTitle: 'AltiusInsights',
  address: 'Level 1, 54 Miller Street, Sydney NSW, 2060',
  abn: 'ABN 64 160 164 198',
  app: {
    production: false,
    endpoint: 'https://api-qa.altiuslife.com.au',
    url: 'https://qa.altiuslife.com.au',
    isAccessCodeCompany: true,
    hasUserLevels: true,
    hasGuestContent: false,
    hasStripe: false,
    hasHomePageRegisterButton: true,
    hasApp: true,
    mainDomain: 'qa.altiuslife.com.au',
    insightsDomain: 'qa.altiusinsights.com.au',
    subDomainPattern: /^[a-zA-Z0-9]+-{0,1}[a-zA-Z0-9]+\.qa\.altiuslife\.com\.au$/,
    internalLinkBaseDomains: ['localhost:4200', 'qa.altiuslife.com.au', 'altiuslife.com.au'],
  },
  blobStorage: {
    url: 'https://altiuslifestorage.blob.core.windows.net/assets/',
    container: 'assets',
  },
  devices: {
    fitbit: {
      clientId: '23BLFC',
      callbackUrl: 'https://api-qa.altiuslife.com.au/fitbit/auth',
      authUri: 'https://www.fitbit.com/oauth2/authorize',
      tokenUri: 'https://api.fitbit.com/oauth2/token',
    },
    googleFit: {
      clientId: '738699637221-gt7hu4vat1sk9v8iq0553t30apf84u7k.apps.googleusercontent.com',
      redirectUrl: 'https://api-qa.altiuslife.com.au/google/auth',
      authUri: 'https://accounts.google.com/o/oauth2/v2/auth',
    },
  },
  cms: {
    filestackApiKey: 'AG0HQxXGTz5eD0b8shJkwz',
    froalaApiKey: 'qB1G1C1F1C4B1B5mD6F5F4D4E1B9D6C3F5B4g1lC-7E2amvjqvjnnlD8hcg1H5ne==',
  },
  appDownloadLinks: {
    apple: 'https://itunes.apple.com/us/app/altius-life/id1440228163?ls=1&mt=8',
    google: 'https://play.google.com/store/apps/details?id=io.lifeiq.altuis',
  },
  notifications: {
    oneSignal: {
      oneSignalAppId: 'd6e3db0c-2598-4e3e-a074-3d0fbe0d2610',
      oneSignalSafariWebId: 'web.onesignal.auto.54d60a3d-c0d7-4ae1-b490-c29a9cfa7b70',
    },
  },
  rewards: {
    myRewards: {
      myRewardsAction: 'https://altiuslife.myrewards.com.au/users/member_auto_login',
      myRewardsDomainUrl: 'https://altiuslife.myrewards.com.au',
      myRewardsDomainCode: 735,
    },
  },
  analytics: {
    googleAnalytics: {
      viewId: 'UA-194895002-1',
      ga4MeasurementId: 'G-BCB5DB0545',
    },
  },
  brand: 'AltiusLife',
  companyId: 61,
  features: [
    1, 2, 3, 4, 5, 6, 11, 12, 13, 14, 15, 16, 17, 19, 22, 26, 27, 28, 29, 33, 32, 52, 53, 55, 56,
    57, 58, 59, 60, 61, 64, 65, 66, 67, 69, 77, 78, 80, 81,
  ],
  theme: {
    logoImg: 'assets/logo/altiuslife_logo.png',
    authLogo: 'assets/logo/altiuslife_logo.png',
    primaryColor: '#1D327D',
    topBarColor: '#ffffff',
    topTextBarColor: '#8c8c8c',
    bottomTextBarColor: '#8c8c8c',
    headingFont: 'Lato',
    bodyFont: 'Source Sans Pro',
  },
  contactInfo: {
    website: 'http://altius-group.com.au/',
    helpEmail: 'info@altiuslife.com.au',
    externalContacts: [
      {
        title: 'Urgent Help:',
        paragraph: 'If you are at risk of harm, please call your local emergency services (in Australia please call <a href="tel:000"><b>000</b></a>).<br/> If you are in distress or require an urgent appointment, please call our reception desk on <a href="tel:1300307912"><b>1300 307 912</b></a> (within Australia), or from outside Australia please call <a href="tel:+61893889000"><b>+61 8 9388 9000</b></a>',
      },
      {
        title: 'Appointments, including changes and cancellations:',
        paragraph: 'Call  <a href="tel:1300307912"><b>1300 307 912</b></a><br/> Email  <a href="mailto:reception@peoplesense.com.au"><b><u>reception@peoplesense.com.au</u></b></a>',
      },
      {
        title: 'AltiusLife Application Support:',
        paragraph: 'Email  <a href="mailto:info@altiuslife.com.au"><b><u>info@altiuslife.com.au</u></b></a>',
      },
    ],
  },
  payments: {},
  landingPageText: {
    homePageSloganText: 'DISCOVER, SHARE, ACT & REWARD YOUR WAY TO A HEALTHIER YOU',
    homePageWelcomeTitle: '',
    homePageWelcomeText: 'Welcome to AltiusLife',
    homePageTitle: 'WELCOME TO ALTIUSLIFE',
    homePageSubText: `We recognise your health and wellbeing is important to help you thrive in all areas of life. To support you, we’ve partnered with AltiusLife to bring you a wellbeing unique platform/app where you’ll find tools and resources to help you realise your health potential.`,
    homePageRegisterText: `WHAT WE CAN DO FOR YOU`,
    marketingPageLoginText: 'To login to AltiusLife, please use the unique web address and mobile app code provided for your organisation.',
    insightsWelcomeText: 'WELCOME TO ALTIUSINSIGHTS',
    insightsDescription: 'AltiusInsights is the reporting and dashboard service of Altius Group',
    insightsContactText: 'To active your account, please contact us.'
  },
  auth0: {
    domain: 'https://altius.au.auth0.com',
    clientId: 'aCOuwfXukHzBcYx8D46bWSn1GKY3dD1X',
    redirectUri: 'https://qa.altiuslife.com.au/auth/authorize',
    insightsRedirectUri: 'https://qa.altiusinsights.com.au/auth/authorize',
    audience: 'https://api-qa.altiuslife.com.au/',
  },
};
