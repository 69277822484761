import * as _ from 'lodash';

import { BreadcrumbService } from '../../../../services/breadcrumb.service';
import { Component, Input, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { Location, LocationStrategy } from '@angular/common';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { MAT_SELECT_SCROLL_STRATEGY } from '@angular/material';
import { BlockScrollStrategy, Overlay } from '@angular/cdk/overlay';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ShareContentComponent } from '../share-content/share-content.component';
import { FeatureService } from '../../../../services/feature-service';
import { LikeService } from '../../../../services/like-service';
import { LifeActionService } from '../../../../services/life-action-service';
import { LogService } from '../../../../services/log.service';
import { UtilService } from '../../../../services/util-service';
import { FeedbackService } from '../../../../services/feedback.service';
import { BadgeService } from '../../../../services/badge-service';
import { BookmarkService } from '../../../../services/bookmark-service';
import { UserService } from '../../../../services/user-service';
import { ContentService } from '../../../../services/content-service';
import { IContentDetail } from '../../../_models/content-detail';
import { CONTENT_TYPES, FEATURES, INCLUDE_EXCLUDE_SPECIAL_OPTION } from '../../enums';
import { IBookmarkChanged } from '../../../_models/bookmark-changed';

import { IContentComment } from '../../../_models/contentComment';
import { FriendsService } from '../../../../services/friends-service';
import { IFriend } from '../../../_models/friend';
import { IBadgeCount } from '../../../_models/badge-count';
import { FriendPresentationComponent } from '../../../share/friends-container/friend-presentation/friend-presentation.component';
import { BootstrapModalComponent } from '../../../../components/bootstrap-modal/bootstrap-modal.component';
import { IFriendActions } from '../../../_models/friend-actions';
import { ItemDetails } from '../flagging/flagging.component';
import { DateService } from '../../../../services/date.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { IUser } from '../../../_models/user';

export function scrollFactory(overlay: Overlay): () => BlockScrollStrategy {
  return () => overlay.scrollStrategies.block();
}

interface INutritionItem {
  name: string;
  value: number;
  unit: string;
}

@Component({
  selector: 'app-content-detail',
  templateUrl: './content-detail.component.html',
  styleUrls: ['./content-detail.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [
    {
      provide: MAT_SELECT_SCROLL_STRATEGY,
      useFactory: scrollFactory,
      deps: [Overlay],
    },
  ],
})
export class ContentDetailComponent implements OnInit, OnDestroy {
  @Input() rating;
  @Input() comment;
  @Input() previewContent;

  dataType: string;
  item: IContentDetail;
  isLoggedIn = false;
  nutritionItems: INutritionItem[] = [];

  isDataAvailable = false;
  isCalledFromDiscover = false;
  indicators: any = [];

  logId;

  touched = false;
  isCmsPreview = false;
  canShareToGroup = false;

  // Comment On Comment
  itemForm: FormGroup;
  canRespondToButtons: boolean;
  commentItems: IContentComment[];
  editingCommentId = 0;
  editingPostId = 0;
  userCompanyId: any;
  actionButtonText = 'Post';
  initialNumberOfCommentsShown = 2;
  numberCommentsIncrements = 10;
  isEmptyCommentError = false;
  userName = '';
  isLoggedin = false;
  userProfileId;
  userImage;
  contentType;
  mainContent: SafeHtml;

  constructor(
    private contentService: ContentService,
    private router: Router,
    public activatedRoute: ActivatedRoute,
    public userService: UserService,
    private bookmarkService: BookmarkService,
    public badgeService: BadgeService,
    private domSanitizer: DomSanitizer,
    public feedbackService: FeedbackService,
    private utilService: UtilService,
    private breadcrumbService: BreadcrumbService,
    private logService: LogService,
    private locationStrategy: LocationStrategy,
    public lifeActionService: LifeActionService,
    public activeModal: NgbActiveModal,
    public likeService: LikeService,
    private location: Location,
    private feature: FeatureService,
    private modalService: NgbModal,
    private friendsService: FriendsService,
    private dateService: DateService,
    private fb: FormBuilder,
  ) {
  }

  ngOnInit() {
    this.isLoggedIn = this.userService.isLoggedIn();
    if (this.previewContent) {
      this.isCmsPreview = true;
      this.item = this.previewContent;
      this.contentService.setContentType(this.item);
      this.isDataAvailable = true;
      if (this.item.longText) {
        this.mainContent = this.domSanitizer.bypassSecurityTrustHtml(this.item.longText);
      }
    } else {
      this.getContent();

      this.canRespondToButtons = false;
      this.initialiseAddItem();
      this.userService.getMe().subscribe((result: IUser) => {
        this.userProfileId = result.userProfile.id;
        this.userImage = result.userProfile.photo ? this.userService.envVariables.blobStorage.url + 'profile-images/' + result.userProfile.photo : null;
        this.userName = result.userProfile.username;
      });
      this.userCompanyId = this.userService.company.id;
      this.isCalledFromDiscover = (this.locationStrategy.path().indexOf('discover') > -1);
    }
    this.canShareToGroup = this.hasFeature(FEATURES.SHARE_TO_GROUP);
  }

  isFieldInvalid(field: string) {
    return !this.itemForm.get(field).valid && this.itemForm.get(field).touched;
  }

  displayFieldCss(field: string) {
    return {
      'is-invalid': this.isFieldInvalid(field),
    };
  }

  private initialiseAddItem(): void {
    this.comment = '';
    this.initialiseEditableFormGroup(this.comment);
  }

  private initialiseEditableFormGroup(comment: string) {
    this.itemForm = this.fb.group({
      commentControl: [comment, [Validators.required, Validators.max(250)]],
    });
  }

  private markAllControlsAsTouched() {
    Object.keys(this.itemForm.controls).forEach((field) => {
      const control = this.itemForm.get(field);
      control.markAsTouched({ onlySelf: true });
    });
  }

  onSaveClick() {
    if (!this.itemForm.valid) {
      this.markAllControlsAsTouched();
      return;
    }
    this.persistItem();
  }

  persistItem() {
    let comment: any;
    try {

      comment = {
        description: this.itemForm.get('commentControl').value,
        contentId: this.item.id,
        userProfileId: this.userProfileId,
        contentType: this.contentType,
      };

      this.updateUserBadges('feature',
        this.feature.ContentFeedbackFeatureId);

      this.contentService.addContentComments(comment)
        .subscribe(() => {
            this.itemForm.reset();
            this.canRespondToButtons = true;
            const msg = `Added new comments successfully`;
            this.utilService.showToastSuccess(msg);
            this.getContentComments(this.item.id);
          },
          (error) => {
            const errorMsg = `Error while adding item - ${error}`;
            this.utilService.showToastError(errorMsg);
            this.canRespondToButtons = true;
          },
        );
    } catch (error) {
      const errorMsg = 'Error persisting item - ' + error;
      this.utilService.showToastError(errorMsg);
      this.canRespondToButtons = true;
      return;
    }
  }

  addComment(comment, post, postIndex) {
    this.isEmptyCommentError = this.isEmptyComment(comment);
    if (this.isEmptyCommentError) {
      return;
    }

    this.contentService.addContentComments({
      parentId: post.id,
      description: comment.description,
      contentId: this.item.id,
      userProfileId: this.userProfileId,
      contentType: this.contentType,
    })
      .subscribe(result => {
        const newComment = {
          createdAt: Date(),
          description: comment.description,
          id: result[0],
          photo: this.userImage,
          contentId: this.item.id,
          contentType: this.contentType,
          updatedAt: Date(),
          userProfileId: this.userProfileId,
          username: this.userName,
          liked: '0',
          likes: '0',
          isEditable: this.canUserEditItem(this.userProfileId),
          isDeletable: this.canUserDeleteItem(this.userProfileId),
        };
        this.commentItems[postIndex].comments.push(newComment);
        this.initialisePostsDisplayComments(this.commentItems);
        this.refreshPostDisplayComments(post);
      });
  }

  getNewItem(postId) {
    return {
      parentId: postId,
      description: '',
      contentId: this.item.id,
      userProfileId: this.userProfileId,
    };
  }

  ngOnDestroy() {
    if (!this.isCmsPreview && this.logId) {
      this.logService.updateLog(this.logId)
        .subscribe();
    }
  }

  private contentBelongToCompany(content) {
    const { excludedCompanyIds, includedCompanyIds, companyId } = content;
    const userCompanyId = this.userService.company.id;

    if (CONTENT_TYPES.RECIPE === content.type) {
      return companyId === null || companyId === userCompanyId;
    }

    if (_.isArray(includedCompanyIds) && includedCompanyIds.length > 0) {
      return includedCompanyIds.includes(userCompanyId) || includedCompanyIds.includes(INCLUDE_EXCLUDE_SPECIAL_OPTION.ALL);
    }

    if (_.isArray(excludedCompanyIds) && excludedCompanyIds.length > 0) {
      return !excludedCompanyIds.includes(userCompanyId) && !excludedCompanyIds.includes(INCLUDE_EXCLUDE_SPECIAL_OPTION.ALL);
    }

    return false;
  }

  private isNoItem(data) {
    return data === 'not available' || !this.contentBelongToCompany(data);
  }

  getContent() {
    this.activatedRoute.data
      .subscribe(() => {
        this.activatedRoute.params
          .subscribe(params => {
            this.dataType = params.type;
            this.contentService.getContentByIdForAnyUser(
              params.id, params.type, this.isLoggedIn)
              .subscribe(item => {
                if (this.isNoItem(item)) {
                  if (this.isLoggedIn) {
                    this.item = item;
                    return;
                    // this.location.back();
                  } else {
                    this.router.navigate(['home']);
                  }
                }

                this.item = item;
                if (this.item.longText) {
                  this.mainContent = this.domSanitizer.bypassSecurityTrustHtml(this.item.longText);
                }
                if (item.indicators && item.indicators.length) {
                  this.indicators = item.indicators;
                }
                this.breadcrumbService.setBreadcrumbExtraName(item.title);
                if (item.isRecipe) {
                  this.setNutrition(this.item);
                  this.sortByMethods(this.item.methods);
                }

                this.isDataAvailable = true;

                if (this.isLoggedIn) {
                  this.logContentItem(this.item);
                  this.checkUserBadges(this.item);
                }

                this.getContentComments(this.item.id);
              });
          });
      });
  }

  hasFeature(num) {
    if (this.userService.company) {
      return this.userService.showFeature(num, this.userService);
    } else {
      return false;
    }
  }

  private logContentItem(item) {
    if (!this.isLoggedIn) {
      return;
    }

    this.logService.logContentItem(item)
      .subscribe(log => {
        this.logId = log.id;
      });
  }

  private checkUserBadges(item) {
    this.updateUserBadges(item.type);
  }

  getImagePath(item: IContentDetail): string {
    return this.userService.getContentItemImage(item);
  }

  getAuthorImage(image) {
    return this.userService.getAuthorImage(image);
  }

  private isNutritionIncludesTypeValue(item, typeName): boolean {
    return item.name.includes(typeName) && item.unit
      && item.value && +item.value > 0;
  }

  private includeNutritionItem(item, typeName, name = null) {
    if (this.isNutritionIncludesTypeValue(item, typeName)) {
      const itemName = name || typeName;
      const nutritionItem: INutritionItem = {
        name: itemName,
        value: item.value,
        unit: item.unit,
      };

      this.nutritionItems.push(nutritionItem);
    }
  }

  private setNutrition(recipe) {
    this.nutritionItems = [];

    recipe.nutrition.map(item => {
      this.includeNutritionItem(item, 'Kilojoules', 'Energy');
      this.includeNutritionItem(item, 'Fat');
      this.includeNutritionItem(item, 'Carb', 'Carbs');
      this.includeNutritionItem(item, 'Protein');
      this.includeNutritionItem(item, 'Sugar');
      this.includeNutritionItem(item, 'Sodium');
    });

    this.nutritionItems.sort((a: INutritionItem, b: INutritionItem) => {
      return a.name.toLowerCase().localeCompare(b.name.toLowerCase());
    });
  }

  sortByMethods(methods) {
    methods.sort(function(a, b) {
      return +a.methodName - +b.methodName;
    });

    return methods;
  }

  onBookmarkClick(event, item: IContentDetail) {
    event.preventDefault();
    event.stopPropagation();

    if (item.isBookmarked) {
      this.deleteBookmark(item);
    } else {
      this.addBookmark(item);
    }
  }

  addBookmark(item: IContentDetail) {
    this.bookmarkService.addBookmark(item.id, item.contentType)
      .subscribe(data => {
          this.item.bookmarkId = data.id;
          this.item.isBookmarked = true;
          const change: IBookmarkChanged = {
            newBookmark: true,
            contentId: data.contentId,
            bookmarkId: data.id,
          };
          this.emitChangedBookmark(change);
        },
        (err) => {
          console.log(err);
        },
      );
  }

  deleteBookmark(item: IContentDetail) {
    this.bookmarkService.deleteBookmark(item.bookmarkId)
      .subscribe(data => {
        const change: IBookmarkChanged = {
          newBookmark: false,
          bookmarkId: data.id,
          contentId: data.contentId,
        };
        this.item.bookmarkId = null;
        this.item.isBookmarked = false;
        this.emitChangedBookmark(change);
      });
  }

  emitChangedBookmark(change) {
    if (this.isCalledFromDiscover) {
      this.contentService.emitBookmark(change);
    } else {
      this.contentService.emitBookmarkHome(change);
    }
  }

  updateUserBadges(type, typeId = 0) {
    this.badgeService.updateUserBadges(type, typeId);
  }

  submit() {
    const feedbackObj = {
      contentId: this.item.id,
      type: this.item.contentType,
      value: this.rating,
      comment: this.comment,
    };

    this.feedbackService
      .postFeedback(feedbackObj)
      .subscribe(
        (res) => {
          if (res.success) {
            this.updateUserBadges('feature', this.feature.ContentFeedbackFeatureId);
            this.utilService.showToastSuccess('Thank you for your feedback.');
          } else {
            if (res.feedbackLimitError) {
              this.utilService.showToastError(res.err);
            } else {
              this.utilService.showToastError('There was an error submitting your feedback');
            }
          }
        }
      );

    this.rating = null;
    this.comment = null;
    this.touched = false;
  }

  onLikeClick(event) {
    event.preventDefault();
    event.stopPropagation();

    if (this.item.isLiked) {
      this.contentService.unlikeItem(this.item);
    } else {
      this.contentService
        .likeItem(this.item, this.userService.profile.id);
    }
  }

  onDoLifeAction(indicatorBody) {
    this.item.indicators[indicatorBody.index] = indicatorBody.indicator;
  }

  onChangeActivationStatus(item) {
    const lifeAction = this.item.indicators.find(i => i.id === +item.indicatorId);

    if (item.active) {
      this.lifeActionService.activateLifeAction(item)
        .subscribe(data => {
          this.item.indicators[item.index] = data;
        });
    } else {
      this.lifeActionService.deactivateLifeAction(item.userLifeActionsActivationId)
        .subscribe(() => {
          lifeAction.active = false;
          lifeAction.userLifeActionsActivationId = null;
        });
    }
  }

  hasAuthor(item) {
    return item.authorName;
  }

  shareToGroup() {
    const shareModal = this.modalService.open(ShareContentComponent);
    shareModal.componentInstance.contentType = this.dataType;
    shareModal.componentInstance.contentImage = this.item.image;
    shareModal.componentInstance.contentTitle = this.item.title;
    shareModal.componentInstance.contentId = this.item.id;
    shareModal.componentInstance.contentDescription = this.dataType !== 'recipe' ? this.item.shortText : this.item.shortDescription;
    shareModal.componentInstance.contentUrl = window.location.origin + '/home' + '/' + this.dataType + '/' + this.item.id;

  }

  openLink(url) {
    window.open(`${url}`, '_system', 'location=yes');
    return false;
  }

  closeModal() {
    this.activeModal.dismiss();
  }

  getContentComments(contentId) {

    if (this.item.isArticle) {
      this.contentType = 'article';
    } else if (this.item.isRecipe) {
      this.contentType = 'recipe';
    } else if (this.item.isVideo) {
      this.contentType = 'video';
    }
    this.contentService.getContentComments(contentId, this.contentType).subscribe((data) => {
      this.commentItems = data;
      this.initialisePostsDisplayComments(this.commentItems);
      this.commentItems.forEach(element => {
        element.photo = element.photo ? this.userService.envVariables.blobStorage.url + 'profile_images/' + element.photo : null;
      });
      this.markItemUpdatability(this.commentItems);
    });
  }

  private initialisePostsDisplayComments(posts) {
    const nrCommentsToShow = this.initialNumberOfCommentsShown;
    posts.forEach(post => {
      post.displayComments = post.comments.slice(-nrCommentsToShow);
      post.displayComments.forEach(element => {
        if (element.photo.indexOf('profile_images') < 0) {
          element.photo = element.photo ? this.userService.envVariables.blobStorage.url + 'profile_images/' + element.photo : null;
        }
      });
    });
  }

  private markItemUpdatability(posts) {
    posts.forEach(post => {
      post.isEditable = this.canUserEditItem(post.userProfileId);
      post.isDeletable = this.canUserDeleteItem(post.userProfileId);
      post.comments.forEach((comment) => {
        comment.isEditable = this.canUserEditItem(comment.userProfileId);
        comment.isDeletable = this.canUserDeleteItem(comment.userProfileId);
      });
    });
  }

  private canUserEditItem(userProfileId: number) {
    return this.userService.isThisProfileUser(userProfileId);
  }

  private canUserDeleteItem(userProfileId: number) {
    return this.userService.isSuperAdminUser() || this.userService.isAdminUser() || this.userService.isThisProfileUser(userProfileId);
  }

  private deletePost(postId, userProfileId) {
    if (!this.canUserDeleteItem(userProfileId)) {
      return;
    }

    const deleteModal = this.modalService.open(BootstrapModalComponent);
    deleteModal.componentInstance.title = 'Delete Comment';
    deleteModal.componentInstance.message = 'Are you sure you want to delete this comment?';
    deleteModal.componentInstance.confirmButtonLabel = 'Delete';

    deleteModal.result.then((isConfirmed) => {
      if (isConfirmed) {
        this.contentService.deleteComment(postId).subscribe(() => {
          this.commentItems = this.commentItems.filter((post) => {
            return post.id !== postId;
          });
          this.utilService.showToastSuccess('Comment deleted');
        });
      }
    });
  }

  callEditComment(itemDetails: ItemDetails) {
    if (!this.canUserEditItem(itemDetails.userProfileId)) {
      return;
    }
    this.editingCommentId = itemDetails.itemId;
  }

  editComment(comment, post) {
    if (this.isEmptyComment(comment)) {
      // editingCommandId NOT reset to 0 to permit user to continue to edit.
      // Underlying values reset in case user navigates away to edit another comment
      this.resetToPreviousComment(comment, post);
      this.editingCommentId = 0;
      this.utilService.showToastError('Can not update an empty comment');

      return;
    }

    const payload = {
      description: comment.description,
      //image: comment.image ? comment.image : null,
      //preview: comment.preview ? comment.preview : null
    };

    this.contentService.updateComment(this.editingCommentId, payload)
      .subscribe(
        () => {
          this.updateReadOnlyComment(comment, post);
          this.moveUpdatedCommentToDateOrder(post, this.editingCommentId);
          this.refreshPostDisplayComments(post);
          this.editingCommentId = 0;
        },
        (error) => console.log(error),
      );
  }

  isEmptyComment(comment): boolean {
    return !comment.description;
  }

  private refreshPostDisplayComments(post) {
    const nrCommentsToShow = Math.max(
      post.displayComments.length, this.initialNumberOfCommentsShown);
    post.displayComments = post.comments.slice(-nrCommentsToShow);
  }

  resetToPreviousComment(comment, post) {
    const editedComment = post.comments.find(
      (c) => c.id === this.editingCommentId,
    );
    comment.description = editedComment.description;
    //comment.displayDescription = this.urlify(editedComment.description);
    //comment.image = editedComment.image;
    //comment.preview = editedComment.preview;
  }

  updateReadOnlyComment(comment, post) {
    const updatedComment = post.comments.find(
      (c) => c.id === this.editingCommentId,
    );
    if (!updatedComment) {
      return;
    }

    updatedComment.updatedAt = this.dateService.getDate();
    updatedComment.description = comment.description;
    //updatedComment.image = comment.image ? comment.image : null;
    //updatedComment.preview = comment.preview ? comment.preview : null;
    //updatedComment.displayDescription = this.urlify(comment.description);

    //updatedComment.photoSource = this.getItemUserPhotoSource(updatedComment);
    //updatedComment.imageSource = this.getItemPhoto(updatedComment.image);
  }

  moveUpdatedCommentToDateOrder(post: any, updatedCommentId: number) {
    const index = post.comments.findIndex(c => c.id === updatedCommentId);
    const updatedComment = post.comments.find(c => c.id === updatedCommentId);
    if (index < 0 || !updatedComment || post.comments.length === 1) {
      return;
    }

    post.comments.splice(index, 1);
    post.comments.push(updatedComment);
  }

  callDeleteComment(itemDetails: ItemDetails) {
    this.deleteComment(itemDetails.itemId, itemDetails.itemIndex, itemDetails.userProfileId);
  }

  private deleteComment(commentId, postIndex, userProfileId) {
    if (!this.canUserDeleteItem(userProfileId)) {
      return;
    }

    const deleteModal = this.modalService.open(BootstrapModalComponent);
    deleteModal.componentInstance.title = 'Delete Comment';
    deleteModal.componentInstance.message = 'Are you sure you want to delete this comment?';
    deleteModal.componentInstance.confirmButtonLabel = 'Delete';

    deleteModal.result.then((isConfirmed) => {
      if (isConfirmed) {
        this.contentService.deleteComment(commentId).subscribe(() => {
          const post = this.commentItems[postIndex];
          post.comments = this.commentItems[postIndex].comments.filter(
            (comment) => comment.id !== commentId,
          );
          this.refreshPostDisplayComments(post);
          this.utilService.showToastSuccess('Comment deleted');
        });
      }
    });
  }

  onViewProfile(userProfileId) {
    this.friendsService.getFriendByUserProfileId(userProfileId)
      .subscribe((friend: IFriend) => {
        this.setAdditionalFriendProperties(friend);
        this.badgeService.getUserBadgesSummary(userProfileId)
          .subscribe((badgeCounts: IBadgeCount[]) => {
            this.openFriendPresentationModal(friend, badgeCounts);
          }, err => {
            console.log('friendsService.getFriendByUserProfileId(item.id)', err);
          });
      });
  }

  private setAdditionalFriendProperties(friend: IFriend) {
    friend.photoSrc = this.userService.getProfilePicture(friend.photo);
    friend.levelSrc = this.userService.getLevelSrc(friend.level);
    friend.username ?
      friend.usernameTruncated = this.utilService.truncateForXS(friend.username, screen.width) :
      friend.usernameTruncated = friend.username;
  }

  private openFriendPresentationModal(friend: IFriend, badgeCounts: IBadgeCount[]) {
    const modal = this.modalService.open(FriendPresentationComponent);
    modal.componentInstance.friend = friend;
    modal.componentInstance.badgeCounts = badgeCounts;

    modal.result.then((selectedAction: IFriendActions) => {
      this.doSelectedAction(selectedAction);
    }, () => {
    });
  }

  private doSelectedAction(selectedAction: IFriendActions) {
    if (selectedAction.sendFriendRequest) {
      this.sendFriendRequest(selectedAction.actionedFriend);
    }

    if (selectedAction.cancelSentFriendRequest) {
      this.cancelSentFriendRequest(selectedAction.actionedFriend);
    }

    if (selectedAction.confirmReceivedFriendRequest) {
      this.confirmReceivedFriendRequest(selectedAction.actionedFriend);
    }

    if (selectedAction.declineReceivedFriendRequest) {
      this.declineReceivedFriendRequest(selectedAction.actionedFriend);
    }

    if (selectedAction.removeConfirmedFriend) {
      this.removeConfirmedFriend(selectedAction.actionedFriend);
    }
  }

  private sendFriendRequest(friend: IFriend) {
    this.friendsService.sendFriendRequest(friend.id)
      .subscribe((requestedFriend: IFriend) => {
        const msg = `Successfully sent a friend request for ${requestedFriend.username}`;
        this.utilService.showToastSuccess('Friend Request Sent', msg);
      }, (err) => {
        console.log('sendFriendRequest error', err);
        const msg = `Unable to send a friend request for ${friend.username}`;
        this.utilService.showToastError('Friend Request Error', msg);
      });
  }

  private cancelSentFriendRequest(friend: IFriend) {
    this.friendsService.cancelSentFriendRequest(friend.id)
      .subscribe((cancelledFriend: IFriend) => {
        const msg = `Cancelled your sent friend request to ${cancelledFriend.username}`;
        this.utilService.showToastSuccess('Cancel sent friend request', msg);
      }, (err) => {
        console.log('cancelSentFriendRequest error', err);
        const msg = `Unable to cancel the friend request for ${friend.username}`;
        this.utilService.showToastError('Cancel Friend Request Error', msg);
      });
  }

  private confirmReceivedFriendRequest(friend: IFriend) {
    this.friendsService.confirmReceivedFriendRequest(friend.id)
      .subscribe((confirmedFriend: IFriend) => {
        const msg = `Successfully confirmed friend ${confirmedFriend.username}`;
        this.utilService.showToastSuccess('Confirm Friend', msg);
      }, (err) => {
        console.log('confirmReceivedFriendRequest error', err);
        const msg = `Unable to confirm the friend request from ${friend.username}`;
        this.utilService.showToastError('Confirm Friend Request Error', msg);
      });
  }

  private declineReceivedFriendRequest(friend: IFriend) {
    this.friendsService.declineReceivedFriendRequest(friend.id)
      .subscribe((declinedFriend: IFriend) => {
        const msg = `Declined the friend rerequest received from ${declinedFriend.username}`;
        this.utilService.showToastSuccess('Decline Friend Request', msg);
      }, (err) => {
        console.log('declineReceivedFriendRequest error', err);
        const msg = `Unable to decline the friend request from ${friend.username}`;
        this.utilService.showToastError('Decline Friend Request Error', msg);
      });
  }

  private removeConfirmedFriend(friend: IFriend) {
    this.friendsService.removeConfirmedFriend(friend.id)
      .subscribe((removedFriend: IFriend) => {
        const msg = `Removed friend ${removedFriend.username}`;
        this.utilService.showToastSuccess('Remove Friend', msg);
      }, (err) => {
        console.log('removeConfirmedFriend error', err);
        const msg = `Unable to remove the friend ${friend.username}`;
        this.utilService.showToastError('Remove Friend Error', msg);
      });
  }

  getPostLevelClass(postLevel: string, alternateClass: string): string {
    if (this.userService.isProfileLevel()) {
      return postLevel;
    }

    return alternateClass;
  }

  isActiveImage(item) {
    return item.active && item.image !== null;
  }

  isActivePreview(item) {
    return item.active && item.image === null && item.preview;
  }

  isEditingPost(postId: number): boolean {
    return postId === this.editingPostId;
  }

  isEditingComment(commentId: number): boolean {
    return commentId === this.editingCommentId;
  }

  callEditPost(itemDetails: ItemDetails) {
    if (!this.canUserEditItem(itemDetails.userProfileId)) {
      return;
    }

    this.editingPostId = itemDetails.itemId;
  }

  getUserProfileLevelClass(alternateClass: string): string {
    if (this.userService.isProfileLevel()) {
      return this.userService.profile.level;
    }

    return alternateClass;
  }

  numberOfCommentsLabel(post) {
    return `${post.displayComments.length} of ${post.comments.length} comments`;
  }

  isDisplayNumberOfCommentsLabel(post) {
    return post.displayComments
      && post.displayComments.length > 0;
  }

  isDisplayMoreComments(post) {
    return post.displayComments
      && post.displayComments.length < post.comments.length;
  }

  displayMorePostComments(post) {
    const nrCommentsToShow = (post.displayComments.length < this.numberCommentsIncrements)
      ? this.numberCommentsIncrements
      : (post.displayComments.length + this.numberCommentsIncrements);

    post.displayComments = post.comments.slice(-nrCommentsToShow);
  }

  isDisplayLessComments(post) {
    return post.displayComments.length > this.initialNumberOfCommentsShown
      && post.displayComments.length >= post.comments.length;
  }

  displayLessComments(post) {
    const nrCommentsToSlice = this.initialNumberOfCommentsShown;
    post.displayComments = post.comments.slice(-nrCommentsToSlice);
  }

  resetToPreviousPost(post) {
    const editedPost = this.commentItems.find((p) => p.id === this.editingPostId);
    if (editedPost) {
      post.description = editedPost.description;
      //post.image = editedPost.image;
      //post.preview = editedPost.preview;
      //post.displayDescription = this.urlify(editedPost.description);
    }
  }

  editPost(post) {
    if (!post.description) {
      this.resetToPreviousPost(post);
      this.utilService.showToastError('Please include a description in the post');

      return;
    }

    const payload = {
      description: post.description,
      //image: post.image ? post.image : null,
      //preview: post.preview ? post.preview : null
    };

    this.contentService.updateComment(this.editingPostId, payload).subscribe(
      () => {
        this.updateReadOnlyPost(post);
        //post.imageSource = this.getItemPhoto(post.image);
        this.editingPostId = 0;
      },
      (error) => console.log(error),
    );
  }

  cancel(type) {
    if (type === 'post') {
      this.editingPostId = 0;
    } else {
      this.editingCommentId = 0;
    }
  }

  updateReadOnlyPost(post) {
    const editedPost = this.commentItems.find((p) => p.id === this.editingPostId);
    if (!editedPost) {
      return;
    }

    editedPost.description = post.description;
    //editedPost.displayDescription = this.urlify(editedPost.description);
    //editedPost.image = post.image ? post.image : null;
    //editedPost.preview = post.preview ? post.preview : null;

    //editedPost.imageSource = this.getItemPhoto(editedPost.image);
  }

  callDeletePost(itemDetails: ItemDetails) {
    this.deletePost(itemDetails.itemId, itemDetails.userProfileId);
  }

  onLikeClicked(event, obj, type) {
    event.preventDefault();
    event.stopPropagation();
    if (+obj.liked === 0) {
      this.like(obj, type);
    } else {
      this.unlike(obj, type);
    }
  }

  private like(obj, type) {
    this.likeService.like(+obj.id, type, this.userService.profile.id).subscribe(
      (data) => {
        if (data[0] && data[0].contentId) {
          obj.liked = 1;
          obj.likes = +obj.likes + 1;
        }
      },
      (err) => {
        console.log(err);
      },
    );
  }

  private unlike(obj, type) {
    this.likeService.unlike(Number.parseInt(obj.id), type).subscribe((data) => {
      if (data === 1) {
        obj.liked = 0;
        obj.likes = Number.parseInt(obj.likes) - 1;
      }
    });
  }
}
