import { Component } from '@angular/core';
import { PageService } from '../../../services/page-service';
import { UserService } from '../../../services/user-service';

@Component({
  selector: 'app-peer-support-widget',
  templateUrl: './peer-support-widget.component.html',
})
export class PeerSupportWidgetComponent {
  image: string;
  widgetTitle: string;
  title: string;
  link: string;

  constructor(
    private pageService: PageService,
    private userService: UserService,
  ) {
  }

  ngOnInit(): void {
    this.pageService.getPage('peer-support', this.userService.profile.lang, this.userService.company.id)
      .subscribe(data => {
        if (data && data.code !== 404) {
          this.image = this.userService.getUserStorageAbsolutePath('peer-support-images/', data.image);
          const dataObj = JSON.parse(data.data);
          this.widgetTitle = dataObj.widgetTitle || 'Log a Peer Support Interaction';
          this.title = data.title || 'All information is de-identified and managed confidentiality';
          this.link = dataObj.url || 'https://forms.office.com/r/a1PzcX2aLj';
        } else {
          this.image = '../../../assets/img/widgets/log_widget_image.png';
        }
      });
  }
}
