<div *ngIf='isDisplayingList && isInitialised'>
  <app-cms-list-view
    *ngIf='isInitialised'
    imgColumn='3'
    [dataList]='items'
    [columnList]='columns'
    [disableIds]='[0]'>
  </app-cms-list-view>
</div>

<div *ngIf='(isAddingItem || isEditingItem) && isInitialised' class='padding jumbotron'>
  <div class='row'>
    <div *ngIf='errorMsg' class='col-12'>
      <p class='form-input-error-message' [innerHTML]='errorMsg'></p>
    </div>
    <div class='col-4'>
      <app-language-filter (languagesLoadedEvent)='loadAvailableLanguages($event)' (filterEvent)='changeLanguage($event)'></app-language-filter>
    </div>
  </div>
  <form [formGroup]='itemForm' (ngSubmit)='onSaveClick()'>
    <div class='row'>
      <div class='col-4'>
        <div class='form-group'>
          <label class='control-label required'>Company</label>
          <select *ngIf='isAddingItem'
                  class='form-control custom-select'
                  formControlName='companyIdControl'
                  [ngClass]="displayFieldCss('companyIdControl')"
                  (change)='onCompanyChange($event.target.value)'>
            <option value='null' selected>-- Select Company IF applicable --</option>
            <option
              *ngFor='let item of companies'
              [value]='item.id'>{{item.name}}
            </option>
          </select>
          <input *ngIf='isEditingItem'
                 type='text' readonly
                 formControlName='companyIdControl'
                 class='form-control' />
          <span class='invalid-feedback'>Company is required</span>
        </div>
      </div>
      <div class='col-12'>
        <!-- Image -->
        <div class='form-group'>
          <label class='control-label required'>Image</label>
          <div class='input-group'>
            <input type='text' name='logo' id='logo' class='form-control' [ngClass]="displayFieldCss('image')"
                   placeholder='Choose the image' formControlName='image' readonly />
            <div class='input-group-prepend'>
              <button type='button' class='input-group-text btn btn-primary' (click)='openFileStack()'>
                Browse
              </button>
            </div>
            <span class='invalid-feedback'>Image is required</span>
          </div>
        </div>

        <!-- URL -->
        <div class="form-group">
          <label class="control-label required">URL</label>
          <input type="text" class="form-control" [ngClass]="displayFieldCss('url')" formControlName='url' />
          <span class='invalid-feedback'>URL is required.</span>
        </div>

        <!-- Widget Title -->
        <div class='form-group'>
          <label class='control-label required'>Widget Title</label>
          <input type='text' class='form-control' [ngClass]="displayFieldCss('widgetTitle')" formControlName='widgetTitle' />
          <span class='invalid-feedback'>Widget Title is required.</span>
        </div>

        <!-- Title -->
        <div class='form-group'>
          <label class='control-label required'>Title</label>
          <input type='text' class='form-control' [ngClass]="displayFieldCss('title')" formControlName='title' />
          <span class='invalid-feedback'>Title is required.</span>
        </div>

        <br />

        <!-- Buttons -->
        <div class='row'>
          <div [ngClass]="{ 'col-6': isAddingItem || isDEFAULT(), 'col-4': isEditingItem }">
            <button type='button' class='btn btn-outline-primary w-100' (click)='onBackClick()'>
              <i class='fa fa-undo mr-2' aria-hidden='true'></i> BACK
            </button>
          </div>

          <div *ngIf='isEditingItem && !isDEFAULT()' class='col-4'>
            <button type='button' class='btn btn-outline-primary w-100' [disabled]='!canRespondToButtons' (click)='onDeleteClick()'>
              <i class='fa fa-trash mr-2' aria-hidden='true'></i> DELETE
            </button>
          </div>

          <div [ngClass]="{ 'col-6': isAddingItem || isDEFAULT(), 'col-4': isEditingItem }">
            <button type='submit' class='btn btn-primary float-right w-100' [disabled]='!canRespondToButtons'>
              <i class='fa fa-floppy-o mr-2' aria-hidden='true'></i> SAVE
            </button>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>



