import { UserService } from '../../../services/user-service';
import { Component, OnInit } from '@angular/core';
import { IPage } from '../../_models/page';
import { PageService } from '../../../services/page-service';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Component({
  selector: 'app-eap-booking-detail',
  templateUrl: './eap-booking-detail.component.html',
  styleUrls: ['./eap-booking-detail.component.scss'],
})
export class EapBookingDetailComponent implements OnInit {
  item: IPage;
  isDataAvailable = false;
  image: string;
  content: SafeHtml;
  url: string;

  constructor(
    private userService: UserService,
    private pageService: PageService,
    public domSanitizer: DomSanitizer,
  ) {
  }

  ngOnInit() {
    this.pageService.getPage('eap', this.userService.profile.lang, this.userService.company.id).subscribe((data) => {
        if (data && data.code !== 404) {
          this.item = data;
          this.isDataAvailable = true;
          this.image = this.userService.getImagePath('eap-images/' + data.image);
          this.content = this.domSanitizer.bypassSecurityTrustHtml(data.longText);
          const obj = JSON.parse(data.data);
          this.url = obj.url;
        }
      },
    );
  }

  openEAPUrl() {
    window.open(this.url, '_blank');
  }
}
