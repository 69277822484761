import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UtilService } from '../../services/util-service';
import { UserService } from '../../services/user-service';
import { DateService } from '../../services/date.service';
import { TeamService } from '../../services/team.service';
import { IUser } from '../_models/user';
import { DatasourceComponent } from '../datasource/datasource.component';
import { DataSourceService } from '../../services/dataSource-service';
import { TMHService } from '../../services/track-my-health-service';
import { ShareContentComponent } from '../_shared/components/share-content/share-content.component';
import { ContentService } from '../../services/content-service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ITeamComment } from '../_models/teamComment';
import { ItemDetails } from '../_shared/components/flagging/flagging.component';
import { IFriend } from '../_models/friend';
import { IBadgeCount } from '../_models/badge-count';
import { IFriendActions } from '../_models/friend-actions';
import { BootstrapModalComponent } from '../../components/bootstrap-modal/bootstrap-modal.component';
import { FriendPresentationComponent } from '../share/friends-container/friend-presentation/friend-presentation.component';
import { FriendsService } from '../../services/friends-service';
import { BadgeService } from '../../services/badge-service';
import { LikeService } from '../../services/like-service';
import { DomSanitizer } from '@angular/platform-browser';

const PATH_POSTS = 'teams_posts_images/';

@Component({
  selector: 'app-team-details',
  templateUrl: './team-details.component.html',
  styleUrls: ['./team-details.component.scss'],
})
export class TeamDetailsComponent implements OnInit {

  items: ITeamComment[];
  item: ITeamComment;

  id: number;
  loading = true;
  btnLoading = false;
  teamName: string;
  imagePath: string;
  isMember: boolean;
  challengeId: number;
  challengeName: string;
  challengeImagePath: string;
  description: string;
  comment: string;
  startDate: string;
  endDate: string;
  members: [];
  topPerformers: [];
  likesCount: number;
  isLike: boolean;
  likeItem: {
    contentId: number,
    userProfileId: number,
    contentType: string,
  };
  teamComment: {
    description: string,
    teamId: number,
    userProfileId: number
  };

  userProfileId: number;
  userImage: string;

  btnDisable: boolean;

  itemForm: FormGroup;
  canRespondToButtons: boolean;

  editingCommentId = 0;
  editingPostId = 0;
  userCompanyId: any;
  postPlaceholder = 'Start a conversation...';
  commentPlaceholder = 'Write a comment...';
  actionButtonText = 'Post';
  actionButtonTextOnEdit = 'Save';
  initialNumberOfCommentsShown = 2;
  numberCommentsIncrements = 10;
  isEmptyCommentError = false;
  userName = '';
  isLoggedin = false;
  isOtherTeamMember = false;
  postImagePath = PATH_POSTS;

  constructor(
    private activatedRoute: ActivatedRoute,
    private utilService: UtilService,
    private userService: UserService,
    private dateService: DateService,
    private teamService: TeamService,
    private modalService: NgbModal,
    private dataSourceService: DataSourceService,
    private tMHService: TMHService,
    private router: Router,
    private contentService: ContentService,
    private fb: FormBuilder,
    private friendsService: FriendsService,
    private badgeService: BadgeService,
    private likeService: LikeService,
    public domSanitizer: DomSanitizer,
  ) {
  }

  ngOnInit() {
    this.isLoggedin = this.userService.isLoggedIn();
    this.btnDisable = true;
    this.userCompanyId = this.userService.company.id;
    this.activatedRoute.params.subscribe((data) => {
      this.id = this.utilService.getIdFromHyphenatedUrl(data.id);
      this.getTeam(this.id, 'onLoad');
    });
    this.canRespondToButtons = false;
    this.initialiseAddItem();
  }

  async getTeam(id, type) {
    this.teamService.getAvailableTeamById(id).subscribe((data) => {
      if (data) {
        if (type == 'onLoad') {
          this.teamName = data.name;
          this.imagePath = data.image ? this.userService.envVariables.blobStorage.url + 'team-images/' + data.image : null;
          this.challengeId = data.challenge.id;
          this.challengeName = data.challenge.name;
          this.challengeImagePath = data.challenge.image ? this.userService.envVariables.blobStorage.url + 'challenge-images/' + data.challenge.image : null;
          this.description = data.challenge.description;
          this.startDate = this.dateService.formatDD_MMM_YYYY_Allow_Null_With_Text(data.challenge.startDate);
          this.endDate = this.dateService.formatDD_MMM_YYYY_Allow_Null_With_Text(data.challenge.endDate);
          this.getTeamComments(this.id);
        }
        data.members.forEach(element => {
          element.photo = element.photo ? this.userService.envVariables.blobStorage.url + 'profile-images/' + element.photo : null;
          element.stepsCompleted = element.stepscompleted != null && element.stepscompleted != undefined ? element.stepscompleted : 0;
          element.stepsGoal = element.goals != null && element.goals != undefined ? Math.ceil(element.goals) : 0;
          element.stepsCompletedInPercentage = (element.stepsCompleted / element.stepsGoal) * 100;
        });
        data.topPerformers.forEach(element => {
          element.photo = element.photo ? this.userService.envVariables.blobStorage.url + 'profile-images/' + element.photo : null;
          element.stepsCompleted = element.stepscompleted != null && element.stepscompleted != undefined ? element.stepscompleted : 0;
          element.stepsGoal = element.goals != null && element.goals != undefined ? Math.ceil(element.goals) : 0;
          element.stepsCompletedInPercentage = (element.stepsCompleted / element.stepsGoal) * 100;
        });
        this.userService.getMe().subscribe((result: IUser) => {
          this.userName = result.userProfile.username;
          this.userProfileId = result.userProfile.id;
          this.userImage = result.userProfile.photo ? this.userService.envVariables.blobStorage.url + 'profile_images/' + result.userProfile.photo : null;
          this.isMember = data.members.find(x => x.userProfileId == this.userProfileId) != undefined;
          if (this.userService.isNormalUser()) {
            this.isOtherTeamMember = data.isOtherTeam != undefined;
          }
        });
        this.members = data.members;
        this.topPerformers = data.topPerformers;

        if (data.like) {
          this.isLike = true;
          this.likeItem = data.like;
        } else {
          this.isLike = false;
        }
        this.likesCount = data.likesCount.count;
      }
      this.loading = false;
    });
  }

  getTeamComments(teamId) {
    this.teamService.getTeamComments(teamId).subscribe((data) => {
      this.items = data;
      this.initialisePostsDisplayComments(this.items);
      this.items.forEach(element => {
        element.photo = element.photo ? this.userService.envVariables.blobStorage.url + 'profile-images/' + element.photo : null;
        element.imageWithSource = element.image ? this.userService.envVariables.blobStorage.url + 'team-post-images/' + element.image : null;
      });
      this.markItemUpdatability(this.items);
    });
  }

  leaveTeam() {
    this.btnLoading = true;
    let body = {
      teamId: this.id,
      userProfileId: this.userProfileId,
    };
    this.teamService.leaveTeam(body)
      .subscribe(() => {
          this.isMember = false;
          this.getTeam(this.id, 'LeaveTeam');
          let message = `You have successfully left '${this.teamName}' team`;
          this.teamService.notifyTeamsChanged();
          this.utilService.showToastSuccess(message);
          this.btnLoading = false;
        },
        (error) => {
          const errorMsg = `Error while adding item - ${error}`;
          this.utilService.showToastError(errorMsg);
          this.isMember = false;
          this.btnLoading = false;
        });
    // const dataSourceModal = this.modalService.open(TeamLeaveComponent);
    // dataSourceModal.result
    //   .then((result) => {

    //     if(result == 'leave-challenge'){
    //       let body = {
    //       teamId: this.id,
    //       userProfileId: this.userProfileId
    //     }
    //     this.teamService.leaveTeam(body)
    //     .subscribe(() => {
    //       this.isMember = false;
    //       this.getTeam(this.id, 'LeaveTeam');
    //       let message = `You have successfully left '${this.teamName}' team`;
    //       this.teamService.notifyTeamsChanged();
    //       this.utilService.showToastSuccess(message);
    //     },
    //       (error) => {
    //         const errorMsg = `Error while adding item - ${error}`;
    //         this.utilService.showToastError(errorMsg);
    //         this.isMember = false;
    //       });
    //     } else if(result == 'join-another-team'){
    //       this.router.navigate([`act/teams`]);
    //     }
    //   })
    //   .catch((error) => console.log(error));
  }

  joinTeam() {
    this.btnLoading = true;
    let body = {
      teamId: this.id,
      userProfileId: this.userProfileId,
    };
    this.teamService.joinTeam(body)
      .subscribe(() => {
          this.isMember = true;
          this.getTeam(this.id, 'joinTeam');
          let message = `You successfully joined '${this.teamName}' team`;
          this.teamService.notifyTeamsChanged();
          this.utilService.showToastSuccess(message);
          this.btnLoading = false;
        },
        (error) => {
          const errorMsg = `Error while adding item - ${error}`;
          this.utilService.showToastError(errorMsg);
          this.isMember = false;
          this.btnLoading = false;
        });
  }

  onDataSourceClick(indicator) {
    this.tMHService.getTrackerWithLimit(indicator).subscribe((data) => {
      if (data.success === false) {
        this.utilService.showToastError(data.err);
      } else {
        this.changeIndicatorDataSource(data);
      }
    });
  }

  private changeIndicatorDataSource(indicator) {
    const dataSourceModal = this.modalService.open(DatasourceComponent);
    dataSourceModal.componentInstance.item = indicator;
    dataSourceModal.componentInstance.parentPage = 'team-details';

    dataSourceModal.result
      .then((result) => {
        this.dataSourceService
          .changeDataSource(result.datasource, indicator.dataSource.id)
          .subscribe((data) => {
            if (data.success === false) {
              this.utilService.showToastError(data.err);
            } else {
              const message = `${indicator.name} data source was changed to ${result.datasource}`;
              this.utilService.showToastSuccess(message);
            }
          });
      })
      .catch((error) => console.log(error));
  }

  shareToGroup() {
    const shareModal = this.modalService.open(ShareContentComponent);
    shareModal.componentInstance.contentType = 'team';
    shareModal.componentInstance.contentImage = this.imagePath;
    shareModal.componentInstance.contentTitle = this.teamName;
    shareModal.componentInstance.contentId = this.id;
    shareModal.componentInstance.contentDescription = this.description;
    shareModal.componentInstance.contentUrl = window.location.origin + '/act/team-details/' + this.id;
  }

  onLikeClick(event) {
    if (this.isLike) {
      this.contentService.unlike(this.likeItem.contentId, this.likeItem.contentType);
      this.isLike = false;
      this.likesCount = Number(this.likesCount) - 1;
    } else {
      this.contentService.like(this.id, 'team', this.userService.profile.id);
      this.isLike = true;
      this.likesCount = Number(this.likesCount) + 1;
    }
  }

  isFieldInvalid(field: string) {
    return !this.itemForm.get(field).valid && this.itemForm.get(field).touched;
  }

  displayFieldCss(field: string) {
    return {
      'is-invalid': this.isFieldInvalid(field),
    };
  }

  private initialiseAddItem(): void {
    this.comment = '';
    this.initialiseEditableFormGroup(this.comment);
  }

  private initialiseEditableFormGroup(comment: string) {
    this.itemForm = this.fb.group({
      commentControl: [comment, [Validators.required, Validators.max(250)]],
    });
  }

  private markAllControlsAsTouched() {
    Object.keys(this.itemForm.controls).forEach((field) => {
      const control = this.itemForm.get(field);
      control.markAsTouched({ onlySelf: true });
    });
  }

  createNewPost(post) {
    console.log(post);
    if (post.image && !post.description) {
      this.utilService.showInfo('Please add a description for your image', 1, 'exclamation');
      return;
    }

    if (!post.description) {
      this.utilService.showInfo('Post must include a description', 1, 'exclamation');
      return;
    }

    const data = {
      description: post.description,
      teamId: this.id,
      image: post.image,
      userProfileId: this.userProfileId,
    };

    this.teamService.addTeamComments(data)
      .subscribe(() => {
        this.canRespondToButtons = true;
        const msg = `Added new comments successfully`;
        this.utilService.showToastSuccess(msg);
        this.getTeamComments(this.id);
      });
  }

  onSaveClick() {
    if (!this.itemForm.valid) {
      this.markAllControlsAsTouched();
      return;
    }
    this.persistItem();
  }

  persistItem() {
    let comment: any;
    try {
      comment = {
        description: this.itemForm.get('commentControl').value,
        teamId: this.id,
        userProfileId: this.userProfileId,
      };

      this.teamService.addTeamComments(comment)
        .subscribe(() => {
            this.itemForm.reset();
            this.canRespondToButtons = true;
            const msg = `Added new comments successfully`;
            this.utilService.showToastSuccess(msg);
            this.getTeamComments(this.id);
          },
          (error) => {
            const errorMsg = `Error while adding item - ${error}`;
            this.utilService.showToastError(errorMsg);
            this.canRespondToButtons = true;
          },
        );
    } catch (error) {
      const errorMsg = 'Error persisting item - ' + error;
      this.utilService.showToastError(errorMsg);
      this.canRespondToButtons = true;
      return;
    }
  }

  addComment(comment, post, postIndex) {

    this.isEmptyCommentError = this.isEmptyComment(comment);
    if (this.isEmptyCommentError) {
      return;
    }

    this.teamService.addTeamComments({
      parentId: post.id,
      description: comment.description,
      teamId: this.id,
      userProfileId: this.userProfileId,
    })
      .subscribe(() => {
        const newComment = {
          createdAt: Date(),
          description: comment.description,
          id: -1,
          photo: this.userImage,
          teamId: this.id,
          updatedAt: Date(),
          userProfileId: this.userProfileId,
          username: this.userName,
          liked: '0',
          likes: '0',
          isEditable: this.canUserEditItem(this.userProfileId),
          isDeletable: this.canUserDeleteItem(this.userProfileId),
        };
        this.items[postIndex].comments.push(newComment);
        this.initialisePostsDisplayComments(this.items);
        this.refreshPostDisplayComments(post);
      });
  }

  isEmptyComment(comment): boolean {
    return !comment.description;
  }

  private refreshPostDisplayComments(post) {
    const nrCommentsToShow = Math.max(
      post.displayComments.length, this.initialNumberOfCommentsShown);
    post.displayComments = post.comments.slice(-nrCommentsToShow);
  }

  private initialisePostsDisplayComments(posts) {
    const nrCommentsToShow = this.initialNumberOfCommentsShown;
    posts.forEach(post => {
      post.displayComments = post.comments.slice(-nrCommentsToShow);
      post.displayComments.forEach(element => {
        element.photo = element.photo ? this.userService.envVariables.blobStorage.url + 'profile-images/' + element.photo : null;
      });
    });
  }

  private markItemUpdatability(posts) {
    posts.forEach(post => {
      post.isEditable = this.canUserEditItem(post.userProfileId);
      post.isDeletable = this.canUserDeleteItem(post.userProfileId);
      post.comments.forEach((comment) => {
        comment.isEditable = this.canUserEditItem(comment.userProfileId);
        comment.isDeletable = this.canUserDeleteItem(comment.userProfileId);
      });
    });
  }

  getPostLevelClass(postLevel: string, alternateClass: string): string {
    if (this.userService.isProfileLevel()) {
      return postLevel;
    }

    return alternateClass;
  }

  isActiveImage(item) {
    return item.image !== null;
  }

  isActivePreview(item) {
    return item.active && item.image === null && item.preview;
  }

  isPreviewUrlValid(item) {
    const itemPreviewUrl = item.preview && item.preview.url;
    const appBaseUrls = this.userService.envVariables.app.internalLinkBaseDomains;
    return itemPreviewUrl && !appBaseUrls.find(appUrl => itemPreviewUrl && itemPreviewUrl.toLowerCase().includes(appUrl));
  }

  isEditingPost(postId: number): boolean {
    return postId === this.editingPostId;
  }

  isEditingComment(commentId: number): boolean {
    return commentId === this.editingCommentId;
  }

  callEditPost(itemDetails: ItemDetails) {
    if (!this.canUserEditItem(itemDetails.userProfileId)) {
      return;
    }

    this.editingPostId = itemDetails.itemId;
  }

  resetToPreviousPost(post) {
    const editedPost = this.items.find((p) => p.id === this.editingPostId);
    if (editedPost) {
      post.description = editedPost.description;
      //post.image = editedPost.image;
      //post.preview = editedPost.preview;
      //post.displayDescription = this.urlify(editedPost.description);
    }
  }

  editPost(post) {
    if (!post.description) {
      this.resetToPreviousPost(post);
      this.utilService.showToastError('Please include a description in the post');

      return;
    }

    const payload = {
      description: post.description,
      image: post.image ? post.image : null,
    };

    this.teamService.updateComment(this.editingPostId, payload).subscribe(
      () => {
        this.updateReadOnlyPost(post);
        post.imageWithSource = post.image ? this.userService.envVariables.blobStorage.url + 'team-post-images/' + post.image : null;
        this.editingPostId = 0;
      },
      (error) => console.log(error),
    );
  }

  cancel(type) {
    if (type === 'post') {
      this.editingPostId = 0;
    } else {
      this.editingCommentId = 0;
    }
  }

  updateReadOnlyPost(post) {
    const editedPost = this.items.find((p) => p.id === this.editingPostId);
    if (!editedPost) {
      return;
    }

    editedPost.description = post.description;
    editedPost.image = post.image ? post.image : null;
    editedPost.imageWithSource = this.getItemPhoto(editedPost.image);
  }

  callDeletePost(itemDetails: ItemDetails) {
    this.deletePost(itemDetails.itemId, itemDetails.userProfileId);
  }

  private canUserEditItem(userProfileId: number) {
    return this.userService.isThisProfileUser(userProfileId);
  }

  private canUserDeleteItem(userProfileId: number) {
    return this.userService.isSuperAdminUser() || this.userService.isThisProfileUser(userProfileId);
  }

  private deletePost(postId, userProfileId) {
    if (!this.canUserDeleteItem(userProfileId)) {
      return;
    }

    const deleteModal = this.modalService.open(BootstrapModalComponent);
    deleteModal.componentInstance.title = 'Delete Team Comment';
    deleteModal.componentInstance.message = 'Are you sure you want to delete this comment?';
    deleteModal.componentInstance.confirmButtonLabel = 'Delete';

    deleteModal.result.then((isConfirmed) => {
      if (isConfirmed) {
        this.teamService.deleteComment(postId).subscribe(() => {
          this.items = this.items.filter((post) => {
            return post.id !== postId;
          });
          this.utilService.showToastSuccess('Comment deleted');
        });
      }
    });
  }

  callEditComment(itemDetails: ItemDetails) {
    if (!this.canUserEditItem(itemDetails.userProfileId)) {
      return;
    }
    this.editingCommentId = itemDetails.itemId;
  }

  editComment(comment, post) {
    if (this.isEmptyComment(comment)) {
      // editingCommandId NOT reset to 0 to permit user to continue to edit.
      // Underlying values reset in case user navigates away to edit another comment
      this.resetToPreviousComment(comment, post);
      this.editingCommentId = 0;
      this.utilService.showToastError('Can not update an empty comment');

      return;
    }

    const payload = {
      description: comment.description,
      //image: comment.image ? comment.image : null,
      //preview: comment.preview ? comment.preview : null
    };

    this.teamService.updateComment(this.editingCommentId, payload)
      .subscribe(
        () => {
          this.updateReadOnlyComment(comment, post);
          this.moveUpdatedCommentToDateOrder(post, this.editingCommentId);
          this.refreshPostDisplayComments(post);
          this.editingCommentId = 0;
        },
        (error) => console.log(error),
      );
  }

  resetToPreviousComment(comment, post) {
    const editedComment = post.comments.find(
      (c) => c.id === this.editingCommentId,
    );
    comment.description = editedComment.description;
    //comment.displayDescription = this.urlify(editedComment.description);
    //comment.image = editedComment.image;
    //comment.preview = editedComment.preview;
  }

  updateReadOnlyComment(comment, post) {
    const updatedComment = post.comments.find(
      (c) => c.id === this.editingCommentId,
    );
    if (!updatedComment) {
      return;
    }

    updatedComment.updatedAt = this.dateService.getDate();
    updatedComment.description = comment.description;
    //updatedComment.image = comment.image ? comment.image : null;
    //updatedComment.preview = comment.preview ? comment.preview : null;
    //updatedComment.displayDescription = this.urlify(comment.description);

    //updatedComment.photoSource = this.getItemUserPhotoSource(updatedComment);
    //updatedComment.imageSource = this.getItemPhoto(updatedComment.image);
  }

  moveUpdatedCommentToDateOrder(post: any, updatedCommentId: number) {
    const index = post.comments.findIndex(c => c.id === updatedCommentId);
    const updatedComment = post.comments.find(c => c.id === updatedCommentId);
    if (index < 0 || !updatedComment || post.comments.length === 1) {
      return;
    }

    post.comments.splice(index, 1);
    post.comments.push(updatedComment);
  }

  callDeleteComment(itemDetails: ItemDetails) {
    this.deleteComment(itemDetails.itemId, itemDetails.itemIndex, itemDetails.userProfileId);
  }

  private deleteComment(commentId, postIndex, userProfileId) {
    if (!this.canUserDeleteItem(userProfileId)) {
      return;
    }

    const deleteModal = this.modalService.open(BootstrapModalComponent);
    deleteModal.componentInstance.title = 'Delete Comment';
    deleteModal.componentInstance.message = 'Are you sure you want to delete this comment?';
    deleteModal.componentInstance.confirmButtonLabel = 'Delete';

    deleteModal.result.then((isConfirmed) => {
      if (isConfirmed) {
        this.teamService.deleteComment(commentId).subscribe(() => {
          const post = this.items[postIndex];
          post.comments = this.items[postIndex].comments.filter(
            (comment) => comment.id !== commentId,
          );
          this.refreshPostDisplayComments(post);
          this.utilService.showToastSuccess('Comment deleted');
        });
      }
    });
  }

  getUserProfileLevelClass(alternateClass: string): string {
    if (this.userService.isProfileLevel()) {
      return this.userService.profile.level;
    }

    return alternateClass;
  }

  onViewProfile(userProfileId) {
    this.friendsService.getFriendByUserProfileId(userProfileId)
      .subscribe((friend: IFriend) => {
        this.setAdditionalFriendProperties(friend);
        this.badgeService.getUserBadgesSummary(userProfileId)
          .subscribe((badgeCounts: IBadgeCount[]) => {
            this.openFriendPresentationModal(friend, badgeCounts);
          }, err => {
            console.log('friendsService.getFriendByUserProfileId(item.id)', err);
          });
      });
  }

  private setAdditionalFriendProperties(friend: IFriend) {
    friend.photoSrc = this.userService.getProfilePicture(friend.photo);
    friend.levelSrc = this.userService.getLevelSrc(friend.level);
    friend.username ?
      friend.usernameTruncated = this.utilService.truncateForXS(friend.username, screen.width) :
      friend.usernameTruncated = friend.username;
  }

  private openFriendPresentationModal(friend: IFriend, badgeCounts: IBadgeCount[]) {
    const modal = this.modalService.open(FriendPresentationComponent);
    modal.componentInstance.friend = friend;
    modal.componentInstance.badgeCounts = badgeCounts;

    modal.result.then((selectedAction: IFriendActions) => {
      this.doSelectedAction(selectedAction);
    }, () => {
    });
  }

  private doSelectedAction(selectedAction: IFriendActions) {
    if (selectedAction.sendFriendRequest) {
      this.sendFriendRequest(selectedAction.actionedFriend);
    }

    if (selectedAction.cancelSentFriendRequest) {
      this.cancelSentFriendRequest(selectedAction.actionedFriend);
    }

    if (selectedAction.confirmReceivedFriendRequest) {
      this.confirmReceivedFriendRequest(selectedAction.actionedFriend);
    }

    if (selectedAction.declineReceivedFriendRequest) {
      this.declineReceivedFriendRequest(selectedAction.actionedFriend);
    }

    if (selectedAction.removeConfirmedFriend) {
      this.removeConfirmedFriend(selectedAction.actionedFriend);
    }
  }

  private sendFriendRequest(friend: IFriend) {
    this.friendsService.sendFriendRequest(friend.id)
      .subscribe((requestedFriend: IFriend) => {
        const msg = `Successfully sent a friend request for ${requestedFriend.username}`;
        this.utilService.showToastSuccess('Friend Request Sent', msg);
      }, (err) => {
        console.log('sendFriendRequest error', err);
        const msg = `Unable to send a friend request for ${friend.username}`;
        this.utilService.showToastError('Friend Request Error', msg);
      });
  }

  private cancelSentFriendRequest(friend: IFriend) {
    this.friendsService.cancelSentFriendRequest(friend.id)
      .subscribe((cancelledFriend: IFriend) => {
        const msg = `Cancelled your sent friend request to ${cancelledFriend.username}`;
        this.utilService.showToastSuccess('Cancel sent friend request', msg);
      }, (err) => {
        console.log('cancelSentFriendRequest error', err);
        const msg = `Unable to cancel the friend request for ${friend.username}`;
        this.utilService.showToastError('Cancel Friend Request Error', msg);
      });
  }

  private confirmReceivedFriendRequest(friend: IFriend) {
    this.friendsService.confirmReceivedFriendRequest(friend.id)
      .subscribe((confirmedFriend: IFriend) => {
        const msg = `Successfully confirmed friend ${confirmedFriend.username}`;
        this.utilService.showToastSuccess('Confirm Friend', msg);
      }, (err) => {
        console.log('confirmReceivedFriendRequest error', err);
        const msg = `Unable to confirm the friend request from ${friend.username}`;
        this.utilService.showToastError('Confirm Friend Request Error', msg);
      });
  }

  private declineReceivedFriendRequest(friend: IFriend) {
    this.friendsService.declineReceivedFriendRequest(friend.id)
      .subscribe((declinedFriend: IFriend) => {
        const msg = `Declined the friend rerequest received from ${declinedFriend.username}`;
        this.utilService.showToastSuccess('Decline Friend Request', msg);
      }, (err) => {
        console.log('declineReceivedFriendRequest error', err);
        const msg = `Unable to decline the friend request from ${friend.username}`;
        this.utilService.showToastError('Decline Friend Request Error', msg);
      });
  }

  private removeConfirmedFriend(friend: IFriend) {
    this.friendsService.removeConfirmedFriend(friend.id)
      .subscribe((removedFriend: IFriend) => {
        const msg = `Removed friend ${removedFriend.username}`;
        this.utilService.showToastSuccess('Remove Friend', msg);
      }, (err) => {
        console.log('removeConfirmedFriend error', err);
        const msg = `Unable to remove the friend ${friend.username}`;
        this.utilService.showToastError('Remove Friend Error', msg);
      });
  }

  numberOfCommentsLabel(post) {
    return `${post.displayComments.length} of ${post.comments.length} comments`;
  }

  isDisplayNumberOfCommentsLabel(post) {
    return post.displayComments
      && post.displayComments.length > 0;
  }

  isDisplayMoreComments(post) {
    return post.displayComments
      && post.displayComments.length < post.comments.length;
  }

  displayMorePostComments(post) {
    const nrCommentsToShow = (post.displayComments.length < this.numberCommentsIncrements)
      ? this.numberCommentsIncrements
      : (post.displayComments.length + this.numberCommentsIncrements);

    post.displayComments = post.comments.slice(-nrCommentsToShow);
  }

  isDisplayLessComments(post) {
    return post.displayComments.length > this.initialNumberOfCommentsShown
      && post.displayComments.length >= post.comments.length;
  }

  displayLessComments(post) {
    const nrCommentsToSlice = this.initialNumberOfCommentsShown;
    post.displayComments = post.comments.slice(-nrCommentsToSlice);
  }

  onLikeClicked(event, obj, type) {
    event.preventDefault();
    event.stopPropagation();
    if (+obj.liked === 0) {
      this.like(obj, type);
    } else {
      this.unlike(obj, type);
    }
  }

  private like(obj, type) {
    this.likeService.like(+obj.id, type, this.userService.profile.id).subscribe(
      (data) => {
        if (data[0] && data[0].contentId) {
          obj.liked = 1;
          obj.likes = +obj.likes + 1;
        }
      },
      (err) => {
        console.log(err);
      },
    );
  }

  private unlike(obj, type) {
    this.likeService.unlike(Number.parseInt(obj.id), type).subscribe((data) => {
      if (data === 1) {
        obj.liked = 0;
        obj.likes = Number.parseInt(obj.likes) - 1;
      }
    });
  }

  getItemPhoto(image) {
    if (!image) {
      return image;
    }

    return this.getPhoto(PATH_POSTS, image);
  }

  getPhoto(path, photo) {
    return this.userService.getUserStorageAbsolutePath(path, photo);
  }
}
