<div class="textareaPrev">

  <textarea *ngIf="!disableButtons" autosize style="overflow:hidden" type="text" [formControl]="text" [class]="text.dirty && text.value? 'form-control-text form-control' : 'form-control-no-text form-control'"
    placeholder="{{placeholder}}
  " (keyup)="handleKeyPress($event) ">
  </textarea>
  <textarea placeholder="{{placeholder}}" *ngIf="disableButtons" [class]="text.dirty && text.value? 'form-control-text form-control' : 'form-control-no-text form-control'"
    disabled></textarea>
  <div *ngIf="isHandlingPreviewUrl || isImageProcessing" class="row justify-content-center mb-2">
    <i class="fa fa-spinner fa-spin fa-3x" aria-hidden="true"></i>
  </div>
  <small *ngIf="!text.value && text.dirty">Comment is required</small>

  <div *ngIf="(preview$ | async) && !(image$ | async)" class="mt-2">
    <button *ngIf="!fromShare" type="button" class="close" aria-label="Close" (click)="closePreview()">
      <span aria-hidden="true">&times;</span>
    </button>
    <img src="{{(preview$ | async).image}} " />
    <h5>{{(preview$ | async).title}}</h5>
    <p>{{(preview$ | async).description}}</p>
  </div>
  <div *ngIf="image$ | async">
    <button type="button" class="close" aria-label="Close" (click)="closeImage()">
      <span aria-hidden="true">&times;</span>
    </button>
    <img [src]="getPhoto(image$ | async)" />
  </div>
</div>
<div class="col-12" class="buttons" align="right">
  <button *ngIf="fromShare || fromEdit" class="post-button btn btn-outline-primary btn-sm mt-2 mr-2" (click)="cancel()">
    Cancel
  </button>
  <a *ngIf="!fromShare && !isComment && !(preview$ | async) && !disableButtons" class="btn add-image p-0" data-toggle="tooltip"
    data-placement="top" title="Add an image" align="left" (click)="showPicker()">
    <i class="fa fa-image mr-2 add-image" align="center" aria-hidden="true"></i>
  </a>
  <button [disabled]="disableButtons" type="submit" class="btn btn-primary btn-sm ml-10 post-button mt-2" align="right"
    [disabled]="isHandlingPreviewUrl || !text.valid && text.dirty" (click)="createNew()">
    {{actionButtonText}}
  </button>
</div>
