<div>
  <h4 *ngIf="isAddingAnswer" class="modal-title">New Answer</h4>
  <h4 *ngIf="!isAddingAnswer" class="modal-title">Edit Answer</h4>
</div>

<form [formGroup]="itemForm" (ngSubmit)="validateOperandValues() && onSaveClick()">
  <div class="row m-0">
    <div class="col-12">
      <label *ngIf="dependencyParams">This <b>answer</b> will appear as a result of</label>
      <br />
      <div *ngIf="dependencyParams" class="row m-2">
        <div class="col-5">
          <div class="custom-control custom-radio custom-control-inline mt-2">
            <input type="radio" id="any" value="any" class="custom-control-input" formControlName="dependencyLevel"
              [ngClass]="displayFieldCss('dependencyLevel')" />
            <label class="custom-control-label" for="any">Any answer to "{{dependencyParams.question}}"</label>
          </div>
        </div>
        <div *ngIf="answersDependency.length > 1" class="col-2">OR </div>
        <div class="col-5">
          <div *ngIf="answersDependency.length > 1" class="custom-control custom-radio custom-control-inline mt-2">
            <input type="radio" id="specific" value="specific" class="custom-control-input" formControlName="dependencyLevel"
              [ngClass]="displayFieldCss('dependencyLevel')" />
            <label class="custom-control-label" for="specific">After specific answers to
              "{{dependencyParams.question}}"</label>
          </div>
          <div *ngIf="answersDependency.length > 1 && isSpecificDependency()">
            <div *ngIf="answersDependency.length > 1">
              <div class="col-12 pl-3" *ngFor="let asr of answersDependency; let i = index">
                <div class="custom-control custom-checkbox">
                  <input id="{{asr.id}}" type="checkbox" checked="{{asr.checked}}" (click)="selectAnswer(i)" class="custom-control-input">
                  <label class="custom-control-label" for="{{asr.id}}">{{asr.label}}</label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Label -->
      <div *ngIf="isNumberType || isTextType" class="form-group">
        <label class="control-label">{{labelText}}</label>
        <input type="text" class="form-control" formControlName="labelControl" />
      </div>
      <div *ngIf="!isNumberType && !isTextType" class="form-group">
        <label class="control-label required">{{labelText}}</label>
        <input type="text" class="form-control" [ngClass]="displayFieldCss('labelControl')" formControlName="labelControl" />
        <span class="invalid-feedback">{{labelText}} is required</span>
      </div>
      <!-- Points -->

      <div class="form-group">
        <label class="control-label" [ngClass]="resultType === 2 || resultType === 4 ? 'required' : ''">Points</label>
        <input type="number" class="form-control" formControlName="pointsControl" [ngClass]="pointsIsInvalid() ? 'is-invalid' : ''"
          [disabled]="resultType === 1" />
        <span class="invalid-feedback">Points is required if an operand is selected or the questionnaire result
          type is by point.</span>
      </div>
      <div class="row">
        <!--- Value -->
        <div *ngIf="isNumberType" class="col-12 col-sm-6">
          <div class="form-group">
            <label class="control-label">Value</label>
            <input type="number" class="form-control" formControlName="valueControl" [ngClass]="valueIsInvalid() ? 'is-invalid' : ''" />
            <span class="invalid-feedback">Value is required if an operand is selected</span>
          </div>
        </div>

      </div>
      <div *ngIf="isNumberType" class="row">
        <!--- operand -->
        <div class="col-12 col-sm-6">
          <div class="form-group">
            <label class="control-label">Operand
              <small>( only for text/number type of answers )</small>
            </label>
            <select class="form-control custom-select" formControlName="operandControl">
              <option value=null selected>None</option>
              <option value="equalTo">Equal to</option>
              <option value="less">Less than</option>
              <option value="more">More than</option>
              <option value="between">Between</option>
            </select>
          </div>
        </div>
        <!--- Value 2 -->
        <div class="col-12 col-sm-6">
          <div class="form-group">
            <label class="control-label">Value 2
              <small>( only for between operand )</small>
            </label>
            <input type="number" class="form-control" formControlName="value2Control" [readonly]="this.itemForm.value.operandControl !== 'between'"
              [ngClass]="value2IsInvalid() ? 'is-invalid' : ''" />
            <span class="invalid-feedback">Value2 is required if the operand between is selected</span>
          </div>
        </div>
      </div>

      <!-- icon -->
      <div class="form-group w-100 d-inline-block" *ngIf="isQuestionsType('radio','checkbox')">
        <label for="logo" class="control-label logoInput">
          Icon
        </label>
        <span class="logoImg">
          <img src="{{icon}}" class="align-middle" />
        </span>
        <div class="input-group logoInput">
          <input type="text" name="icon" id="icon" class="form-control" placeholder="Choose the icon" formControlName="iconControl"
            readonly />
          <div class="input-group-prepend">
            <button type="button" class="input-group-text btn btn-primary" (click)="openFileStack()">
              Browse
            </button>
          </div>
        </div>
      </div>

      <!-- Buttons -->
      <br />
      <div class="pt-3">
        <button type="submit" class="btn btn-primary float-right " [disabled]="!canRespondToButtons">
          <i class="fa fa-floppy-o mr-2" aria-hidden="true"></i> Save answer
        </button>
      </div>
    </div>
  </div>
</form>
