import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import * as _ from 'lodash';
import { AlertComponent } from '../components/alert-modal/alert-modal.component';
import { InfoAlertComponent } from '../components/info-alert/info-alert.component';
import { BadgeModalComponent } from '../app/_shared/components/badge-modal/badge-modal.component';
import { IIdBooleanNamePair } from '../app/_models/id-boolean-name-pair';
import { IIdNamePair } from '../app/_models/id-name-pair';
import { MOBILE_IMAGE_MAX_SIZE } from '../app/_shared/enums';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class UtilService {
  VisibilityGuestId = 0;
  VisibilityUserId = 1;
  VisibilityBothId = 2;

  VisibilityGuestName = 'Guest';
  VisibilityUserName = 'User';
  VisibilityBothName = 'Both';

  private subject = new Subject<any>();

  private allVisibilities: IIdNamePair[] = [
    { id: this.VisibilityBothId, name: this.VisibilityBothName },
    { id: this.VisibilityGuestId, name: this.VisibilityGuestName },
    { id: this.VisibilityUserId, name: this.VisibilityUserName },
  ];

  private urlRegex = /(((https?:\/\/)|(www\.))[^\s]+)/g;

  constructor(
    private modalService: NgbModal,
    private toastr: ToastrService,
    private router: Router,
  ) {
  }

  showAlert(title, msg, type?) {
    const activeModal = this.modalService.open(AlertComponent, { size: 'lg' });
    activeModal.componentInstance.title = title;
    activeModal.componentInstance.message = msg;
    if (type === 2) {
      activeModal.componentInstance.type = 2;
    }
  }

  showInfo(msg, type?, icon?) {
    const infoModal = this.modalService.open(InfoAlertComponent);
    infoModal.componentInstance.message = msg;
    infoModal.componentInstance.type = type;
    infoModal.componentInstance.icon = icon;
  }

  showToast(type, title, msg?) {
    if (!msg) {
      msg = null;
    }
    if (type === 'success') {
      this.toastr.success(title, msg, {});
    } else if (type === 'error') {
      this.toastr.error(title, msg, {});
    } else if (type === 'warning') {
      this.toastr.warning(title, msg, {});
    } else if (type === 'info') {
      this.toastr.info(title, msg, {});
    }
  }

  showToastSuccess(title, msg?) {
    this.showToast('success', title, msg);
  }

  showToastInfo(title, msg?) {
    this.showToast('info', title, msg);
  }

  showToastWarning(title, msg?) {
    this.showToast('warning', title, msg);
  }

  showToastError(title, msg?, enableHtml?) {
    if (enableHtml) {
      this.toastr.error(title, msg, { enableHtml: true });
    } else {
      this.showToast('error', title, msg);
    }
  }

  redirectToHome() {
    window.location.reload();
  }

  getEmailRegEx() {
    return /(?:[a-zA-Z0-9!#$%&'*+\/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+\/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9]{1,2})\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9]{1,2}|[a-zA-Z0-9-]*[a-zA-Z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)]/;
  }

  validateEmail(email) {
    return this.getEmailRegEx().test(email);
  }

  toJoinedString(values) {
    return values.join(', ').replace(/, ((?:.(?!, ))+)$/, ' and $1');
  }

  fromCamelCase(camelCased) {
    if (!camelCased) {
      return '';
    }

    const spaced = camelCased
      .replace(/([A-Z])/g, ' $1')
      .trim();
    if (!spaced.length) {
      return '';
    }

    return spaced[0].toUpperCase() + spaced.substring(1);
  }

  toKebabCase(input: string): string {
    return input.split(' ').join('-').toLowerCase();
  }

  fromKebabCase(input: string): string {
    return input.split('-').join(' ');
  }

  isKebabCased(input: string): boolean {
    const index = input.indexOf('-');

    return index > 0;
  }

  removeAppendedId(input: string): string {
    let words = input.split('-');
    if (words.length === 0) {
      return '';
    }

    const lastElement = words[words.length - 1];
    if (+lastElement > 0
      || +lastElement === 0 && lastElement[0] === '0') {
      words.pop();
    }

    return words.join('-');
  }

  toStartCase(input: string): string {
    return _.startCase(input);
  }

  urlify(text) {
    return text.replace(this.urlRegex, function(url, b, c) {
      const url2 = c === 'www.' ? 'http://' + url : url;
      return '<a href="' + url2 + '" target="_blank">' + url + '</a>';
    });
  }

  showAchievedBadgesSequentially(badges) {
    badges.forEach(badge => {
      this.badgeAlert([badge]);
    });
  }

  badgeAlert(badgesToAward) {
    const url = this.router.url;
    _.map(badgesToAward, (badgeToAward) => {
      const badgesModal = this.modalService.open(BadgeModalComponent);
      badgesModal.componentInstance.title = 'You achieved a badge!';
      badgesModal.componentInstance.message = badgeToAward.name;
      badgesModal.componentInstance.description = badgeToAward.description;
      badgesModal.componentInstance.image = 'assets/img/badges/'.concat(badgeToAward.image);
      badgesModal.componentInstance.item = badgeToAward;

      badgesModal.result
        .then((result) => {
          if (result === 'View') {
            this.router.navigate(['reward/badges']);
          } else {
            this.subject.next({ badgesToAward: badgesToAward, url: url });
            // this.router.navigateByUrl('/').then(() => {
            // this.router.navigate([url]);
            // });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    });
  }

  formatAsYesNo(value) {
    return value ? 'Yes' : 'No';
  }

  yesNoItems(): IIdBooleanNamePair[] {
    return [{ id: true, name: 'Yes' }, { id: false, name: 'No' }];
  }

  visibilities(): IIdNamePair[] {
    return this.allVisibilities.slice();
  }

  hyphenateUrl(string, id?) {
    let urlString;
    if (id) {
      urlString = string + ' ' + id;
    } else {
      urlString = string;
    }
    return urlString.replace(/\s+/g, '-').toLowerCase();
  }

  getIdFromHyphenatedUrl(hyphenatedUrl): number {
    const idSuffix = hyphenatedUrl.split('-').pop();

    return +idSuffix;
  }

  isExternalUrl(url: string): boolean {
    return url.indexOf('://') > 0;
  }

  spellNumber(int: number): string {
    const spelt = [
      'zero', 'one', 'two', 'three', 'four', 'five',
      'six', 'seven', 'eight', 'nine', 'ten',
      'eleven', 'twelve', 'thirteen', 'fourteen', 'fifteen',
      'sixteen', 'seventeen', 'eighteen', 'nineteen', 'twenty',
    ];

    const isNegative = int < 0;
    const absoluteValue = Math.abs(int);

    if (absoluteValue > spelt.length) {
      return int.toString();
    }

    return isNegative
      ? 'negative ' + spelt[absoluteValue]
      : spelt[absoluteValue];
  }

  validateHexColor() {
    return '(^#[0-9A-Fa-f]{6}$)|(^#[0-9A-Fa-f]{3}$)';
  }

  pluralizeInnerHTML(
    className: string,
    number: number,
    singular: string,
    plural: string,
    none: string = null,
  ) {
    const outputNumber = (className)
      ? `<span class='${className}'>${number}</span>`
      : number;

    if (+number === 1) {
      return `${outputNumber} ${singular}`;
    }

    if (!+number && none) {
      return none;
    }

    return `${outputNumber} ${plural}`;
  }

  isEmptyObject(obj): boolean {
    if (obj === null) {
      return true;
    }
    if (obj === undefined) {
      return true;
    }

    const keys = Object.keys(obj);

    return keys.length === 0;
  }

  isEmptyArray(arr): boolean {
    if (arr === null) {
      return true;
    }
    if (arr === undefined) {
      return true;
    }

    return arr.length === 0;
  }

  convertObjectToUriEncodedComponent(obj: any) {
    return encodeURI(JSON.stringify(obj));
  }

  isAllTrue(values: boolean[]): boolean {
    if (!values || !values.length) {
      return false;
    }

    const index = values.findIndex(v => !v);

    return index < 0;
  }

  isAnyTrue(values: boolean[]): boolean {
    if (!values || !values.length) {
      return false;
    }

    const index = values.findIndex(v => v);

    return index >= 0;
  }

  isAllFalse(values: boolean[]): boolean {
    if (!values || !values.length) {
      return false;
    }

    const index = values.findIndex(v => v);

    return index < 0;
  }

  truncateForXS(text: string, screenWidth: number): string {
    const largestLength = 15;
    const truncatedLength = 11;
    const ellipsis = '...';
    const trimmedText = text.trim();

    return (this.isMobileDeviceScreenWidth(screenWidth)
      && trimmedText.length > largestLength)
      ? `${trimmedText.slice(0, truncatedLength)}${ellipsis}`
      : trimmedText;
  }

  isMobileDeviceScreenWidth(screenWidth: number): boolean {
    return screenWidth <= MOBILE_IMAGE_MAX_SIZE;
  }

  calculateColumns(rowColumns: number[], minColumns: number): number {
    const maxRowColumns = Math.max(...rowColumns);
    return Math.max(maxRowColumns, minColumns);
  }

  getBadge(): Observable<any> {
    return this.subject.asObservable();
  }

  isValidURL(string) {
    const res = string.match(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g);
    return res !== null;
  }

  getRandomNumber() {
    const crypto = window.crypto || window['msCrypto'];
    const array = new Uint32Array(1);
    return crypto.getRandomValues(array);
  }

  // https://stackoverflow.com/a/2117523
  genUUID() {
    return '10000000-1000-4000-8000-100000000000'.replace(/[018]/g, c =>
      (+c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> +c / 4).toString(16),
    );
  }
}
