<app-cms-list-view *ngIf='isDisplayingList && isInitialised' imgColumn='3' [dataList]='items' [columnList]='columns'
                   [additionalNumericColumns]='additionalNumericColumns' [isPreventListDelete]='true' [otherButtonName]="'Generate Link'" (emitClickOtherButtonEvent)='handleClickOtherButton()'>
</app-cms-list-view>

<div *ngIf='(isAddingItem || isEditingItem) && isInitialised' class='padding jumbotron'>

  <!-- IsActive -->
  <div *ngIf='isEditingItem'
       class='row m-0'>
    <div class='col-6'>
      <h2>{{activeStateText}}</h2>
    </div>

    <div class='col-6'>
      <button class='btn'
              [ngClass]="item.isActive ? 'btn-outline-primary' : 'btn-primary'"
              (click)='onToggleActiveState()'
      >
        {{ toggleActiveStateText }}
      </button>
    </div>
  </div>

  <form [formGroup]='itemForm' (ngSubmit)='onSaveClick()'>
    <div class='row m-0'>
      <div class='col-12'>

        <!-- Name -->
        <div class='row'>
          <div class='col-9'>
            <div class='form-group'>
              <label class='control-label required'>Name</label>
              <input type='text' class='form-control' [ngClass]="displayFieldCss('nameControl')"
                     formControlName='nameControl' />
              <span class='invalid-feedback'>Name is required</span>
            </div>
          </div>
          <div class='col-3'>
            <div class='row form-group'>
              <div class='col-12'>
                <label class='control-label'>LEAP Booking Code</label>
              </div>
              <div class='col-8'>
                <app-booking-code [form]='itemForm' [formControlName]='"bookingCodeControl"' [cssClass]='"booking-code-full"' (lostFocusEvent)='bookingCodeValidationHandler($event)' (keyUpEvent)='bookingCodeValidationHandler($event)'></app-booking-code>
                <span *ngIf='bookingCodeInvalid' class='invalid-feedback d-block'>Booking Code is invalid</span>
              </div>
              <div class='col-4 pl-0'>
                <button (click)='openBookingSite()' class='btn btn-primary w-100' type='button' [disabled]='disableVerifyBookingCode'>
                  VERIFY
                </button>
              </div>
            </div>

          </div>
        </div>

        <!-- Logo -->
        <div class='form-group w-100 d-inline-block'>
          <label for='logo' class='control-label required logoInput'>
            Logo
          </label>
          <span class='logoImg'>
            <img src='{{logo}}' class='align-middle' />
          </span>
          <div class='input-group logoInput'>
            <input type='text' name='logo' id='logo' class='form-control' [ngClass]="displayFieldCss('logoControl')"
                   placeholder='Choose the logo' formControlName='logoControl' readonly />
            <div class='input-group-prepend'>
              <button type='button' class='input-group-text btn btn-primary' (click)='openFileStack()' [disabled]='!isSuperAdmin'>
                Browse
              </button>
            </div>
            <span class='invalid-feedback'>Logo is required</span>
          </div>
        </div>

        <div class='row'>
          <!--- Register Code -->
          <div class='col-12 col-sm-6'>
            <div class='form-group'>
              <label class='control-label required'>Register Code</label>
              <input type='text' class='form-control' [ngClass]="displayFieldCss('registerCodeControl')"
                     formControlName='registerCodeControl' />
              <span class='invalid-feedback' *ngIf="itemForm.get('registerCodeControl').errors?.required">Register code is required</span>
              <span class='invalid-feedback' *ngIf="itemForm.get('registerCodeControl').errors?.maxlength">Max 22 Characters</span>
            </div>
          </div>
          <!--- Primary Color -->
          <div class='col-12 col-sm-6'>
            <div class='form-group'>
              <label class='control-label required w-100'>Primary color (hexadecimal)</label>
              <span class='colorDiv' [(colorPicker)]='this.itemForm.value.primaryColorControl' [cpDisabled]='!isSuperAdmin'
                    [style.backgroundColor]='this.itemForm.value.primaryColorControl' [cpOutputFormat]='hex'
                    (colorPickerSelect)="changeColor('primaryColorControl', this.itemForm.value.primaryColorControl)"
                    [cpOKButton]='true'></span>
              <input type='text' class='form-control colorTextBox' maxlength='9'
                     [ngClass]="displayFieldCss('primaryColorControl')" formControlName='primaryColorControl' />
              <span class='invalid-feedback'>Primary color invalid</span>
            </div>
          </div>
        </div>

        <div class='row'>
          <!--- Top bar color -->
          <div class='col-12 col-sm-6'>
            <div class='form-group'>
              <label class='control-label required w-100'>Top Bar Color (hexadecimal)</label>
              <span class='colorDiv' [(colorPicker)]='this.itemForm.value.topBarColorControl' [cpDisabled]='!isSuperAdmin'
                    [style.backgroundColor]='this.itemForm.value.topBarColorControl' [cpOutputFormat]='hex'
                    (colorPickerSelect)="changeColor('topBarColorControl', this.itemForm.value.topBarColorControl)"
                    [cpOKButton]='true'></span>
              <input type='text' class='form-control colorTextBox' maxlength='9'
                     [ngClass]="displayFieldCss('topBarColorControl')" formControlName='topBarColorControl' />
              <span class='invalid-feedback'>Top bar color invalid</span>
            </div>
          </div>
          <!--- Top text bar color -->
          <div class='col-12 col-sm-6'>
            <div class='form-group'>
              <label class='control-label required w-100'>Top Text Bar Color (hexadecimal)</label>
              <span class='colorDiv' [(colorPicker)]='this.itemForm.value.topTextBarColorControl' [cpDisabled]='!isSuperAdmin'
                    [style.backgroundColor]='this.itemForm.value.topTextBarColorControl' [cpOutputFormat]='hex'
                    (colorPickerSelect)="changeColor('topTextBarColorControl', this.itemForm.value.topTextBarColorControl)"
                    [cpOKButton]='true'></span>
              <input type='text' class='form-control colorTextBox' maxlength='9'
                     [ngClass]="displayFieldCss('topTextBarColorControl')" formControlName='topTextBarColorControl' />
              <span class='invalid-feedback'>Top text bar color invalid</span>
            </div>
          </div>
        </div>

        <div class='row mt-3'>
          <!--- bottom bar color -->
          <div class='col-12 col-sm-6'>
            <div class='form-group'>
              <label class='control-label required w-100'>Bottom Bar Color (hexadecimal)</label>
              <span class='colorDiv' [(colorPicker)]='this.itemForm.value.bottomBarColorControl' [cpDisabled]='!isSuperAdmin'
                    [style.backgroundColor]='this.itemForm.value.bottomBarColorControl' [cpOutputFormat]='hex'
                    (colorPickerSelect)="changeColor('bottomBarColorControl', this.itemForm.value.bottomBarColorControl)"
                    [cpOKButton]='true'></span>
              <input type='text' class='form-control colorTextBox' maxlength='9'
                     [ngClass]="displayFieldCss('bottomBarColorControl')" formControlName='bottomBarColorControl' />
              <span class='invalid-feedback'>Bottom bar color invalid</span>
            </div>
          </div>
          <!--- bottom text bar color -->
          <div class='col-12 col-sm-6'>
            <div class='form-group'>
              <label class='control-label required w-100'>Bottom Text Bar Color (hexadecimal)</label>
              <span class='colorDiv' [(colorPicker)]='this.itemForm.value.bottomTextBarColorControl' [cpDisabled]='!isSuperAdmin'
                    [style.backgroundColor]='this.itemForm.value.bottomTextBarColorControl' [cpOutputFormat]='hex'
                    (colorPickerSelect)="changeColor('bottomTextBarColorControl', this.itemForm.value.bottomTextBarColorControl)"
                    [cpOKButton]='true'></span>
              <input type='text' class='form-control colorTextBox' maxlength='9'
                     [ngClass]="displayFieldCss('bottomTextBarColorControl')" formControlName='bottomTextBarColorControl' />
              <span class='invalid-feedback'>Bottom text bar color invalid</span>
            </div>
          </div>
        </div>

        <div class='row mt-3'>
          <!--- Background color -->
          <div class='col-12 col-sm-6'>
            <div class='form-group'>
              <label class='control-label required w-100'>Background Color (hexadecimal)</label>
              <span class='colorDiv' [(colorPicker)]='this.itemForm.value.backgroundColorControl'
                    [style.backgroundColor]='this.itemForm.value.backgroundColorControl' [cpOutputFormat]='hex' [cpDisabled]='!isSuperAdmin'
                    (colorPickerSelect)="changeColor('backgroundColorControl', this.itemForm.value.backgroundColorControl)"
                    [cpOKButton]='true'></span>
              <input type='text' class='form-control colorTextBox' maxlength='9'
                     [ngClass]="displayFieldCss('backgroundColorControl')" formControlName='backgroundColorControl' />
              <span class='invalid-feedback'>Background color invalid</span>
            </div>
          </div>
          <!--- Body text color -->
          <div class='col-12 col-sm-6'>
            <div class='form-group'>
              <label class='control-label required w-100'>Body Text Color (hexadecimal)</label>
              <span class='colorDiv' [(colorPicker)]='this.itemForm.value.bodyColorControl' [cpDisabled]='!isSuperAdmin'
                    [style.backgroundColor]='this.itemForm.value.bodyColorControl' [cpOutputFormat]='hex'
                    (colorPickerSelect)="changeColor('bodyColorControl', this.itemForm.value.bodyColorControl)"
                    [cpOKButton]='true'></span>
              <input type='text' class='form-control colorTextBox' maxlength='9'
                     [ngClass]="displayFieldCss('bodyColorControl')" formControlName='bodyColorControl' />
              <span class='invalid-feedback'>Body text color invalid</span>
            </div>
          </div>
        </div>

        <!-- features -->
        <label class='control-label required mt-4'>Features</label>
        <div class='block'>
          <div class='form-group'>
            <label class='control-label'>Discover Features
            </label>
            <div class='row'>
              <div class='col-6 col-sm-4' *ngFor='let f of companyFeatures | featureFilter : 2;let i = index'>
                <div class='custom-control custom-checkbox'>
                  <input id='f{{f.id}}' type='checkbox'
                         [formControl]="this.itemForm.controls['discoverFeaturesControl'].controls[i]"
                         (click)='setFeature(f)' class='custom-control-input'
                         [ngClass]="!isValidGroupCheckbox(2,'discoverFeaturesControl') ? 'is-invalid' : ''">
                  <label class='custom-control-label' for='f{{f.id}}'>{{f.name}}</label>
                </div>
              </div>
            </div>
          </div>
          <hr />
          <div class='form-group'>
            <label class='control-label'>Act Features
            </label>
            <div class='row'>
              <div class='col-6 col-sm-4' *ngFor='let f of companyFeatures | featureFilter : 3 : [80];let i = index'>
                <div class='custom-control custom-checkbox'>
                  <input id='f{{f.id}}' type='checkbox' [formControl]="this.itemForm.controls['actFeaturesControl'].controls[i]"
                         (click)='setFeature(f)' class='custom-control-input' [ngClass]="!isValidGroupCheckbox(2,'actFeaturesControl') ? 'is-invalid' : ''">
                  <label class='custom-control-label' for='f{{f.id}}'>{{f.name}}</label>
                </div>
              </div>
            </div>
          </div>
          <hr />
          <div class='form-group'>
            <label class='control-label'>Share Features
            </label>
            <div class='row position-relative'>
              <div *ngFor='let f of shareMasterFeatures; let i = index' class='col-6 col-sm-4'>
                <div class='custom-control custom-checkbox'>
                  <input id='f{{f.id}}' type='checkbox'
                         [formControl]="this.itemForm.controls['shareFeaturesControl'].controls[i]"
                         (click)='setFeature(f)' class='custom-control-input'
                         [ngClass]="!isValidGroupCheckbox(2,'shareFeaturesControl') ? 'is-invalid' : ''">
                  <label class='custom-control-label' for='f{{f.id}}'>{{f.name}}</label>
                </div>
                <div *ngIf='f.id===4'>
                  <div *ngFor='let f of shareSubFeatures; let i = index' class='sub-feature'>
                    <div class='custom-control custom-checkbox'>
                      <input id='f{{f.id}}' type='checkbox'
                             [formControl]="this.itemForm.controls['shareFeaturesControl'].controls[i + shareMasterFeatures.length]"
                             (click)='setFeature(f)' class='custom-control-input'>
                      <label class='custom-control-label' for='f{{f.id}}'>{{f.name}}</label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <hr />
          <div class='form-group'>
            <label class='control-label'>Rewards Features
            </label>
            <div class='row'>
              <div class='col-6 col-sm-4' *ngFor='let f of companyFeatures | featureFilter : 5 ; let i = index'>
                <div class='custom-control custom-checkbox'>
                  <input id='f{{f.id}}' type='checkbox'
                         [formControl]="this.itemForm.controls['rewardsFeaturesControl'].controls[i]"
                         (click)='setFeature(f)' class='custom-control-input'
                         [ngClass]="!isValidGroupCheckbox(2,'rewardsFeaturesControl') ? 'is-invalid' : ''">
                  <label class='custom-control-label' for='f{{f.id}}'>{{f.name}}</label>
                </div>
              </div>
            </div>
          </div>
          <hr />
          <div class='form-group'>
            <label class='control-label'>Toolkit Features
            </label>
            <div class='row'>
              <div class='col-6 col-sm-4' *ngFor='let f of companyFeatures | featureFilter : 56 ; let i = index'>
                <div class='custom-control custom-checkbox'>
                  <input id='f{{f.id}}' type='checkbox'
                         [formControl]="this.itemForm.controls['toolkitFeaturesControl'].controls[i]"
                         (click)='setFeature(f)' class='custom-control-input'
                         [ngClass]="!isValidGroupCheckbox(2,'toolkitFeaturesControl') ? 'is-invalid' : ''">
                  <label class='custom-control-label' for='f{{f.id}}'>{{f.name}}</label>
                </div>
              </div>
            </div>
          </div>
          <hr />
          <div class='form-group'>
            <label class='control-label'>Functional Features
            </label>
            <div class='row'>
              <div class='col-6 col-sm-4' *ngFor='let f of companyFeatures | featureFilter : 0 ; let i = index'>
                <div class='custom-control custom-checkbox' *ngIf='isEnableForCurrentUser(f)'>
                  <input id='f{{f.id}}' type='checkbox'
                         [formControl]="this.itemForm.controls['functionalFeaturesControl'].controls[i]"
                         (click)='setFeature(f)' class='custom-control-input'
                         [ngClass]="!isValidGroupCheckbox(2,'functionalFeaturesControl') ? 'is-invalid' : ''">
                  <label class='custom-control-label' for='f{{f.id}}'>{{f.name}}</label>
                </div>
              </div>
            </div>
          </div>
          <hr />
          <div class='form-group'>
            <label class='control-label'>Programs
            </label>
            <div class='row'>
              <div class='col-6 col-sm-4'>
                <div *ngFor='let f of companyFeatures | featureFilter : 3;let i = index'>
                  <div class='custom-control custom-checkbox' *ngIf='f.id === 80'>
                    <input id='f{{f.id}}' type='checkbox' [formControl]="this.itemForm.controls['programsControl'].controls[0]"
                           (click)='setFeature(f)' class='custom-control-input'>
                    <label class='custom-control-label' for='f{{f.id}}'>{{f.name}}</label>
                  </div>
                </div>
              </div>
              <div class='col-6 col-sm-4' *ngFor='let p of availablePrograms; let i = index'>
                <div class='custom-control custom-checkbox'>
                  <input id='p{{p.id}}' type='checkbox'
                         [formControl]="this.itemForm.controls['programsControl'].controls[i+1]"
                         (click)='setProgram(p)'
                         class='custom-control-input'>
                  <label class='custom-control-label' for='p{{p.id}}'>{{p.title}}</label>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- welcome text -->
        <div class='form-group'>
          <label class='control-label mt-4'>Welcome text</label>
          <div [froalaEditor]='froalaOptions' formControlName='welcomeTextControl'></div>
          <span class='invalid-feedback'>Content is required.</span>
        </div>
        <div class='form-group'>
          <label class='control-label mt-4'>Welcome text link - will be shown below the link to tutorials as another
            navigation option</label>
          <div>
            <label>Description</label>
            <div [froalaEditor]='froalaOptions' formControlName='welcomeTextLinkDescriptionControl'></div>
          </div>
        </div>
        <div class='row mt-3'>
          <div class='col-6'>
            <!-- Welcome Text Link Label -->
            <div class='form-group'>
              <label class='control-label'>Label</label>
              <input type='text' class='form-control' formControlName='welcomeTextLinkLabelControl' />
              <small class='form-text text-muted'>e.g. Take me to Life Actions!</small>
            </div>
          </div>
          <div class='col-6'>
            <!-- Welcome Text Link URL -->
            <div class='form-group'>
              <label class='control-label'>URL</label>
              <input type='text' class='form-control' formControlName='welcomeTextLinkUrlControl' />
              <small class='form-text text-muted'>e.g. https://wlh.seventeenhundred.com.au/act/lifeactions</small>
            </div>
          </div>
          <!-- Mailchimp List ID -->
          <div class='col-6'>
            <div class='form-group'><label>Mailchimp List ID</label>
              <input type='text' class='form-control' formControlName='mailchimpListIdControl' />
              <small class='form-text text-muted'>Get the list id from mailchimp list > settings dropdown > list name
                and defaults.</small>
            </div>
          </div>
        </div>

        <div class='form-group'>
          <label class='control-label mt-4'>Rewards page text</label>
          <div [froalaEditor]='froalaOptions' formControlName='rewardsTextControl'></div>
          <span class='invalid-feedback'>Content is required.</span>
        </div>
        <!-- Demo user -->
        <label class='control-label mt-4' *ngIf='isAddingItem'>Demo User</label>
        <div class='block' *ngIf='isAddingItem'>
          <div class='row pt-3'>
            <!-- email -->
            <div class='col-12 col-sm-6'>
              <div class='form-group'>
                <label class='control-label'>Email / Login </label>
                <input type='email' class='form-control' [ngClass]="displayFieldCss('emailControl')"
                       formControlName='emailControl' />
                <span class='invalid-feedback'>Email is invalid or already exist</span>
              </div>
            </div>
            <!-- password -->
            <div class='col-12 col-sm-6'>
              <div class='form-group'>
                <label class='control-label'>Password </label>
                <input type='text' class='form-control' [ngClass]="displayFieldCss('passWordControl')"
                       formControlName='passWordControl' />
                <span class='invalid-feedback'>Password is invalid. Minimum 8 characters.</span>
              </div>
            </div>
          </div>
        </div>

        <div class='row mt-3'>
          <div class='col-4'>
            <!-- Company Domain Name -->
            <div class='form-group'>
              <label class='control-label required'>Company Domain Name</label>
              <input type='text' class='form-control text-lowercase' formControlName='companyDomainName'
                     [ngClass]="{'is-invalid' : (itemForm.get('companyDomainName').touched && itemForm.get('companyDomainName').invalid) || !pendingDomainValidation && existedDomainError }"
              />
              <span class='invalid-feedback' *ngIf="itemForm.get('companyDomainName').errors?.required">Company Domain Name is required</span>
              <span class='invalid-feedback' *ngIf="itemForm.get('companyDomainName').errors?.maxlength">Max 22 Characters</span>
              <span class='invalid-feedback' *ngIf="itemForm.get('companyDomainName').errors?.pattern">Domain name is invalid format (Only A-Z, a-z or 0-9 are accept)</span>
              <span class='invalid-feedback' *ngIf='!pendingDomainValidation && existedDomainError'>Company domain: <i>{{itemForm.get('companyDomainName').value}}.{{mainDomain}}</i> is existed, Please choose another domain name!</span>
              <small class='form-text text-muted'>This is used to generate the Altius Life's domain for your organisation.</small>
            </div>
          </div>
          <div class='col-4'>
            <!-- Domain -->
            <div class='form-group'>
              <label class='control-label'>Domain</label>
              <app-input-copy [form]='itemForm' [prefixCopy]='"https://"' [forceLowercase]='true' [additionalClass]="displayFieldCss('domainControl')" [formControlName]="'domainControl'" [isDisabled]='true'></app-input-copy>
              <!--              <input type="text" class="form-control text-lowercase" [ngClass]="displayFieldCss('domainControl')" formControlName="domainControl" />-->
              <span class='invalid-feedback' *ngIf="itemForm.get('domainControl').errors?.invalidLength">Subdomain: Max 22 Characters</span>
              <small class='form-text text-muted'>This is the hostname where to user originates from when using silent
                login, e.g. qantas.com.au. If the company isn't using silent login, this can be empty.</small>
            </div>
          </div>
          <div class='col-4'>
            <!-- Hostname -->
            <div class='form-group'>
              <label class='control-label'>Hostname</label>
              <app-input-copy [form]='itemForm' [prefixCopy]='"https://"' [forceLowercase]='true' [additionalClass]="displayFieldCss('hostnameControl')" [formControlName]="'hostnameControl'" [isDisabled]='true'></app-input-copy>
              <!--              <input type="text" class="form-control text-lowercase" [ngClass]="displayFieldCss('hostnameControl')" formControlName="hostnameControl" />-->
              <span class='invalid-feedback' *ngIf="itemForm.get('hostnameControl').errors?.invalidLength">Subdomain: Max 22 Characters</span>
              <small class='form-text text-muted'>This is the hostname a user from this company would use to access the
                site, e.g. mobecom@seventeenhundred.com.au. It is also the filter set on google analytics to report
                metrics by company.</small>
            </div>
          </div>
        </div>
        <div class='row mt-3'>
          <!-- Insights -->
          <div class='col-4'>
            <div class='form-group'>
              <label class='control-label'>Insights Setting</label>
              <div class='custom-control custom-checkbox'>
                <input id='cbEnableInsights' type='checkbox' formControlName='enableInsightsControl' class='custom-control-input' (change)='toggleInsightsSetting($event.target.checked)'>
                <label class='custom-control-label' for='cbEnableInsights'>Enable Insights</label>
              </div>
            </div>
            <!-- Insights Version -->
            <div class='form-group' *ngIf="itemForm.get('enableInsightsControl').value">
              <label class='control-label'>Insights Version</label>
              <select class='form-control custom-select' [ngClass]="displayFieldCss('insightsVersionControl')" formControlName='insightsVersionControl'>
                <option *ngIf="!itemForm.get('insightsVersionControl').value" value='null' selected>-- Select Version --</option>
                <option *ngFor='let item of insightsVersion' [value]='item.id'>{{item.name}}
                </option>
              </select>
              <span class='invalid-feedback'>Insights Version is required</span>
            </div>
          </div>
          <div class='col-4' *ngIf="itemForm.get('enableInsightsControl').value">
            <!-- Insights Domain -->
            <div class='form-group'>
              <label class='control-label'>Insights Domain</label>
              <app-input-copy [form]='itemForm' [prefixCopy]='"https://"' [forceLowercase]='true' [additionalClass]="displayFieldCss('insightsDomainControl')" [formControlName]="'insightsDomainControl'" [isDisabled]='true'></app-input-copy>
              <!--              <input type="text" class="form-control text-lowercase" [ngClass]="displayFieldCss('insightsDomainControl')" formControlName="insightsDomainControl" />-->
              <span class='invalid-feedback' *ngIf="itemForm.get('insightsDomainControl').errors?.invalidLength">Subdomain: Max 22 Characters</span>
              <small class='form-text text-muted'>This is the domain for the Altius Insights which provide company's reports. Only users with permission can access this.</small>
            </div>
          </div>
          <div class='col-4' *ngIf="itemForm.get('enableInsightsControl').value">
            <!-- Power BI Customer Id -->
            <div class='form-group'>
              <label class='control-label required'>Power BI Customer Id</label>
              <input type='text' class='form-control' [ngClass]="displayFieldCss('powerBICustomerIdControl')" formControlName='powerBICustomerIdControl' />
              <span class='invalid-feedback'>Power BI Customer Id is required</span>
              <small class='form-text text-muted'>This is the customer id in the Power BI that used for generating insights dashboard.</small>
            </div>
          </div>
        </div>

        <div class='row mt-3'>
          <!-- LEAP Integration -->
          <div class='col-12'>
            <div class='form-group'>
              <label class='control-label'>LEAP Integration</label>
              <div class='custom-control custom-checkbox'>
                <input id='cbEnableIntegration' type='checkbox' formControlName='enableIntegrationControl' class='custom-control-input'>
                <label class='custom-control-label' for='cbEnableIntegration'>Enable Integration</label>
              </div>
              <small class='form-text text-muted'>This is the setting to show AltiusLife links from booking site.</small>
            </div>
          </div>
        </div>

        <!-- Buttons -->
        <br />
        <div class='row pt-3'>
          <div [ngClass]="{ 'col-6': isAddingItem, 'col-4': isEditingItem }">
            <button type='button' class='btn btn-outline-primary w-100' (click)='onBackClick()'>
              <i class='fa fa-undo mr-2' aria-hidden='true'></i> BACK
            </button>
          </div>

          <div *ngIf='isEditingItem' class='col-4'>
            <button type='button' class='btn btn-outline-primary w-100' [disabled]='!canRespondToButtons || !isSuperAdmin'
                    (click)='onDeleteClick()'>
              <i class='fa fa-trash mr-2' aria-hidden='true'></i> DELETE
            </button>
          </div>

          <div [ngClass]="{ 'col-6': isAddingItem, 'col-4': isEditingItem }">
            <button type='submit' class='btn btn-primary float-right w-100' [disabled]='!canRespondToButtons || !isSuperAdmin'>
              <i class='fa fa-floppy-o mr-2' aria-hidden='true'></i> SAVE
            </button>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>
