import { RecipeNutritionManagementComponent } from '../recipe-method/recipe-nutrition-management/recipe-nutrition-management.component';
import { RecipeMethodManagementComponent } from './recipe-method-management/recipe-method-management.component';
import { RecipeIngredientManagementComponent } from './recipe-ingredient-management/recipe-ingredient-management.component';
import { Component, OnInit, NgZone } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { RecipeService } from '../../../services/recipe.service';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { UtilService } from '../../../services/util-service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { IRecipe } from '../../_models/recipe';
import { BootstrapModalComponent } from '../../../components/bootstrap-modal/bootstrap-modal.component';
import { FileStackService } from '../../../services/file-stack.service';
import { IIngredient } from '../../_models/ingredient';
import { IMethod } from '../../_models/method';
import { INutrition } from '../../_models/nutrition';
import { ContentDetailComponent } from '../../_shared/components/content-detail/content-detail.component';
import { IIdNamePair } from '../../_models/id-name-pair';
import { CompanyService } from '../../../services/company-service';
import { FeatureService } from '../../../services/feature-service';
import { CategoryService } from '../../../services/category-service';
import { IIdNameSelected } from '../../_models/id-name-selected';
import { AuthorService } from '../../../services/author-service';
import { IAuthor } from '../../_models/author';
import { COMPANIES_CMS_FEATURE_TYPES } from '../../_shared/enums';
import { UserService } from '../../../services/user-service';

@Component({
  selector: 'app-recipe-management',
  templateUrl: './recipe-management.component.html',
  styleUrls: ['./recipe-management.component.scss'],
})
export class RecipeManagementComponent implements OnInit {
  items: IRecipe[];
  item: IRecipe;
  itemType = 'Recipe';
  columns = [];
  additionalNumericColumns = [];
  allCompanies: IIdNamePair[] = [];
  excludedCompanyIds: number[] = [];

  itemForm: FormGroup;
  difficulties: string[];
  recipeIngredients: IIngredient[];
  recipeMethods: IMethod[];
  recipeNutrition: INutrition[];

  isDisplayingList: boolean;
  isInitialised: boolean;
  isEditingItem: boolean;
  isAddingItem: boolean;
  canRespondToButtons: boolean;

  features: any;
  categories: IIdNameSelected[] = [];
  selectedCategories: IIdNameSelected[] = [];
  selectableCategories: IIdNameSelected[] = [];
  selectedFeatureId = null;
  selectedVisibility = 2;
  visibilities: IIdNamePair[] = [];

  authorOptions: IAuthor[];

  private canSelectCompany = false;

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private itemService: RecipeService,
    private fb: FormBuilder,
    private utilService: UtilService,
    private fileStackService: FileStackService,
    private companyService: CompanyService,
    private ngZone: NgZone,
    private modalService: NgbModal,
    private featureService: FeatureService,
    private categoryService: CategoryService,
    private authorService: AuthorService,
    private userService: UserService,
  ) {
  }

  ngOnInit() {
    this.activatedRoute.params.subscribe((route) => {
      this.analyseRoute(route);

      if (this.isDisplayingList) {
        this.getList();
        return;
      }
      this.categoryService.refreshCache(true);
      this.setUserRoleAuthorisations();
      this.getCompanies();
      this.getFeatures();
      if (this.isEditingItem) {
        this.initialiseEditItem(+route.id);
        return;
      }
      if (this.isAddingItem) {
        this.initialiseAddItem();
      }
    });
  }

  private setUserRoleAuthorisations() {
    this.canSelectCompany = this.userService.isSuperAdminUser();
  }

  private analyseRoute(route) {
    this.isDisplayingList = false;
    this.isEditingItem = false;
    this.isAddingItem = false;
    this.isInitialised = false;
    this.canRespondToButtons = false;

    if (!route.id) {
      this.isDisplayingList = true;
      return;
    }

    if (route.id === 'new') {
      this.isAddingItem = true;
    } else {
      this.isEditingItem = true;
    }
  }

  getFeatures() {
    this.featureService
      .getAllFeaturesByType(COMPANIES_CMS_FEATURE_TYPES.DISCOVER)
      .subscribe((data) => {
        this.features = (data || []).filter(
          (f) => this.categoryService.getCachedCategoriesForFeatureId(f.id).length > 0,
        );
      }, () => (this.features = []));
  }

  validateAllFormFields(formGroup: FormGroup) {         //{1}
    Object.keys(formGroup.controls).forEach(field => {  //{2}
      const control = formGroup.get(field);             //{3}
      if (control instanceof FormControl) {             //{4}
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {        //{5}
        this.validateAllFormFields(control);            //{6}
      }
    });
  }

  showPreview() {
    if (this.itemForm.valid) {
      const item = this.itemForm.value;
      item.isRecipe = true;
      item.ingredients = this.recipeIngredients;
      item.methods = this.recipeMethods;
      item.nutrition = this.recipeNutrition;
      const previewModal = this.modalService.open(ContentDetailComponent, { size: 'lg' });
      previewModal.componentInstance.previewContent = item;
    } else {
      this.validateAllFormFields(this.itemForm);
    }
  }

  private getList() {
    this.itemService.getRecipes().subscribe(
      (data) => {
        this.items = data.map((i) => {
          return [i.id, i.title, i.difficulty, i.servings, this.utilService.formatAsYesNo(i.published)];
        });

        this.columns = ['id', 'title', 'difficulty', 'servings', 'published'];
        this.additionalNumericColumns = [3];
        this.isInitialised = true;
        this.canRespondToButtons = true;
      },
      (error) => this.utilService.showToastError('Error while loading items - ' + error),
    );
  }

  private getCompanies() {
    this.companyService.getAllCompanies()
      .subscribe(data => {
          this.allCompanies = data.map(c => {
            return { id: c.id, name: c.name };
          });
        },
        () => this.utilService.showToastError('Error while loading companies list'),
      );
  }

  private initialiseEditItem(id: number): void {
    this.itemService.getRecipeById(id).subscribe((data) => {
      this.item = data;
      if (data.categories.length) {
        this.selectedVisibility = data.categories[0].visibility;
        this.selectedFeatureId = data.categories[0].featureId;
        this.setSelectedCategories(data.categories);
        this.setSelectableCategories(this.selectedFeatureId);
      }

      this.initialiseEditableFormGroup(data);
      this.isInitialised = true;
      this.canRespondToButtons = true;
    });
  }

  setSelectedCategories(categories) {
    this.selectedCategories = categories.map((c) => {
      c.name = this.categoryService.getCategoriesName(c.name);
      return { id: c.id, name: c.name, selected: true };
    });
  }

  updateSelectedCategoriesFormValue() {
    const categoriesFormField = this.itemForm && this.itemForm.get('categories');
    if (!categoriesFormField) {
      return;
    }
    categoriesFormField.setValue(this.selectedCategories.join(','));
  }

  setSelectableCategories(featureId: number) {
    const featureCategories = this.categoryService
      .getCachedCategoriesForFeatureId(+featureId);
    const selectedCategoryIds = this.selectedCategories
      .map((c) => c.id);

    this.selectableCategories = featureCategories
      .filter((c) => selectedCategoryIds.indexOf(c.id) < 0)
      .map((c) => {
        c.name = this.categoryService.getCategoriesName(c.name);
        return { id: c.id, name: c.name, selected: false };
      });
  }

  isCategoriesInvalid() {
    return (
      this.itemForm.get('featureId').valid
      && this.itemForm.get('featureId').touched
      && !this.selectedCategories.length
    );
  }

  unselectSelectedCategory(categoryId: number) {
    this.selectedCategories = this.selectedCategories
      .filter((c) => c.id !== +categoryId);

    const featureId = this.itemForm.get('featureId').value;
    this.updateSelectedCategoriesFormValue();
    this.setSelectableCategories(featureId);
  }

  selectSelectableCategory(categoryId: number) {
    const selectedCategory = this.selectableCategories
      .find((c) => c.id === +categoryId);
    if (!selectedCategory) {
      return;
    }

    this.selectedCategories.push(selectedCategory);
    this.updateSelectedCategoriesFormValue();
    this.selectableCategories = this.selectableCategories
      .filter((c) => c.id !== +categoryId);
  }

  private initialiseAddItem(): void {
    this.item = this.itemService.getNewRecipe();
    this.initialiseEditableFormGroup(this.item);
    this.isInitialised = true;
    this.canRespondToButtons = true;
  }

  private initialiseEditableFormGroup(item: IRecipe) {
    this.recipeIngredients = this.item.ingredients.slice();
    this.recipeMethods = this.item.methods.slice();
    this.recipeNutrition = this.item.nutrition.slice();
    this.difficulties = this.itemService.allDifficulties();
    this.visibilities = this.utilService.visibilities();
    this.getAuthors();

    this.itemForm = this.fb.group({
      title: [item.title, Validators.required],
      shortDescription: [item.shortDescription, Validators.required],
      description: [item.description, Validators.required],
      difficulty: [item.difficulty, Validators.required],
      image: [item.image, Validators.required],
      thumbnail: [item.thumbnail, Validators.required],
      servings: [item.servings, Validators.required],
      servingSize: [item.servingSize, Validators.required],
      published: [item.published.toString(), Validators.required],
      companyId: item.companyId,
      featureId: [this.selectedFeatureId, Validators.required],
      categories: [this.selectedCategories.join(','), Validators.required],
      visibility: [this.selectedVisibility, Validators.required],
      authorId: [item.authorId],
    });

    this.excludedCompanyIds = this.isAllCompanies()
      ? item.excludedCompanyIds
      : [];
  }

  getAuthors() {
    this.authorService.getAllAuthors().subscribe(authors => {
      this.authorOptions = authors;
    });
  }

  private markAllControlsAsTouched() {
    Object.keys(this.itemForm.controls).forEach((field) => {
      const control = this.itemForm.get(field);
      control.markAsTouched({ onlySelf: true });
    });
  }


  onFeatureIdChange(featureId: number) {
    this.setSelectableCategories(+featureId);
  }

  isAllCompanies() {
    return !this.itemForm.get('companyId').value;
  }

  isFieldInvalid(field: string) {
    return !this.itemForm.get(field).valid && this.itemForm.get(field).touched;
  }

  displayFieldCss(field: string) {
    return {
      'is-invalid': this.isFieldInvalid(field),
    };
  }

  ingredientsLabel() {
    return this.getLabel('Ingredient', this.recipeIngredients.length);
  }

  methodsLabel() {
    return this.getLabel('Method', this.recipeMethods.length);
  }

  nutritionLabel() {
    return this.getLabel('Nutrition item', this.recipeNutrition.length);
  }

  private getLabel(type: string, count: number) {
    return count === 1 ? `1 ${type}` : `${count} ${type}s`;
  }

  private navigateToList() {
    this.router.navigate(['/cms/recipes']);
  }

  private navigateToRecipe(id: number) {
    this.router.navigate(['/cms/recipes', id]);
  }

  private deleteItem() {
    const modal = this.modalService.open(BootstrapModalComponent);
    modal.componentInstance.title = 'Delete ' + this.itemType;
    modal.componentInstance.message = 'Confirm deleting this ' + this.itemType;
    modal.componentInstance.confirmButtonLabel = 'Delete';

    modal.result.then(
      (isConfirmed) => {
        if (!isConfirmed) {
          return;
        }

        this.itemService.deleteRecipe(this.item.id).subscribe(
          () => {
            this.itemForm.reset();
            this.utilService.showToastSuccess(`${this.itemType} - ${this.item.title} - deleted`);
            this.navigateToList();
          },
          (error) => this.utilService.showToastError('Error while deleting item - ' + error),
        );
      },
      () => {
      },
    );
  }

  private persistItem() {
    this.canRespondToButtons = false;
    let recipe;
    let body: any;
    try {
      recipe = {
        title: this.itemForm.get('title').value,
        shortDescription: this.itemForm.get('shortDescription').value,
        description: this.itemForm.get('description').value,
        difficulty: this.itemForm.get('difficulty').value,
        image: this.itemForm.get('image').value,
        thumbnail: this.itemForm.get('thumbnail').value,
        servings: this.itemForm.get('servings').value,
        servingSize: this.itemForm.get('servingSize').value,
        published: this.itemForm.get('published').value,
        companyId: this.canSelectCompany ? this.itemForm.get('companyId').value : this.userService.company.id,
        excludedCompanyIds: this.isAllCompanies()
          ? this.excludedCompanyIds
          : [],
        authorId: this.itemForm.get('authorId').value,
      };
      if (this.item.id !== 0) {
        recipe.id = this.item.id;
      }
      body = {
        recipe: recipe,
        categories: this.selectedCategories.map((c) => c.id),
        visibility: this.itemForm.get('visibility').value,
      };
      console.log(body);
    } catch (error) {
      this.utilService.showToastError('Error persisting item - ' + error);
      this.canRespondToButtons = true;
      return;
    }

    if (this.isEditingItem) {
      this.updateItem(body);
    } else if (this.isAddingItem) {
      this.addNewItem(body);
    }
  }

  private updateItem(item) {
    this.itemService.updateRecipe(item).subscribe(
      () => {
        this.itemForm.reset();
        this.canRespondToButtons = true;
        this.utilService.showToastSuccess(`Updated ${this.itemType} - ${item.recipe.title}`);
        this.navigateToList();
      },
      (error) => {
        this.utilService.showToastError(`Error while saving item - ${error}`);
        this.canRespondToButtons = true;
      },
    );
  }

  private addNewItem(item) {
    this.itemService.addRecipe(item).subscribe(
      (data) => {
        this.itemForm.reset();
        this.canRespondToButtons = true;
        this.utilService.showToastSuccess(`Added new ${this.itemType} - ${item.recipe.title}`);
        this.navigateToRecipe(data[0]);
      },
      (error) => {
        this.utilService.showToastError(`Error while adding item - ${error}`);
        this.canRespondToButtons = true;
      },
    );
  }

  onCompanySelected(companyId) {
    this.itemForm.patchValue({ companyId: companyId });
  }

  excludedCompanyIdsSelected(selectedCompanyIds: number[]) {
    this.excludedCompanyIds = selectedCompanyIds;
  }

  onImageClick() {
    this.fileStackService
      .pickSpecifiedPathImage(this.itemService.getImagePath())
      .then((result) => this.ngZone.run(() => this.itemForm.patchValue({ image: result })))
      .catch((err) => this.utilService.showToastError('Error while uploading the banner', err));
  }

  onThumbnailClick() {
    this.fileStackService
      .pickSpecifiedPathImage(this.itemService.getImagePath())
      .then((result) => this.ngZone.run(() => this.itemForm.patchValue({ thumbnail: result })))
      .catch((err) => this.utilService.showToastError('Error while uploading the banner', err));
  }

  onIngredientsClick() {
    const modal = this.modalService.open(RecipeIngredientManagementComponent);
    modal.componentInstance.recipeId = this.item.id;
    modal.componentInstance.items = this.recipeIngredients;

    modal.result
      .then((result) => {
        if (result.isItemsUpdated) {
          this.recipeIngredients = result.items.slice();
        }
      })
      .catch(() => {
      });
  }

  onMethodsClick() {
    const modal = this.modalService.open(RecipeMethodManagementComponent, { size: 'lg' });
    modal.componentInstance.recipeId = this.item.id;
    modal.componentInstance.items = this.recipeMethods;

    modal.result
      .then((result) => {
        if (result.isItemsUpdated) {
          this.recipeMethods = result.items.slice();
        }
      })
      .catch(() => {
      });
  }

  onNutritionClick() {
    const modal = this.modalService.open(RecipeNutritionManagementComponent);
    modal.componentInstance.recipeId = this.item.id;
    modal.componentInstance.items = this.recipeNutrition;

    modal.result
      .then((result) => {
        if (result.isItemsUpdated) {
          this.recipeNutrition = result.items.slice();
        }
      })
      .catch(() => {
      });
  }

  onBackClick() {
    this.navigateToList();
  }

  onDeleteClick() {
    if (!this.isEditingItem) {
      return;
    }

    this.deleteItem();
  }

  onSaveClick() {
    if (!this.itemForm.valid) {
      this.markAllControlsAsTouched();
      return;
    }

    this.persistItem();
  }
}
