import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ITeam_New } from '../_models/team';
import { TeamService } from '../../services/team.service';
import { UserService } from '../../services/user-service';
import { UtilService } from '../../services/util-service';

@Component({
  selector: 'app-teams',
  templateUrl: './teams.component.html',
  styleUrls: ['./teams.component.scss']
})
export class TeamsComponent implements OnInit {

  items: ITeam_New[];
  isLoading: boolean;
  loading: boolean;

  constructor(
    private teamService: TeamService,
    private router: Router,
    public activatedRoute: ActivatedRoute,
    private userService: UserService,
    private utilService: UtilService
  ) { }

  ngOnInit() {
    this.loading = true;
    this.getList();
  }

  getList() {
    this.teamService.getAvailableTeams().subscribe(
      (data) => {
        this.loading = false;
        this.items = data;
        this.items.forEach(element => {
          element.fullName = element.name;
          element.image = element.image ? this.userService.envVariables.blobStorage.url + 'team-images/' + element.image : null
        });
      },
      (error) =>{
        this.loading = false;
        this.utilService.showToastError('Error while loading items - ' + error);
      }
    );
  }

  goToDetailLink(id){
    this.router.navigate([`/act/team-details/${id}`]);
  }

  joinTeam(item){
    this.isLoading = true;
    let body = {
      teamId: item.id,
      userProfileId: item.userprofileid
    }
    this.teamService.joinTeam(body)
    .subscribe(() => {
      let message = `You successfully joined '${item.fullName}' team`;
      this.utilService.showToastSuccess(message);
      this.getList();
      this.teamService.notifyTeamsChanged();
      this.isLoading = false;
    },
      (error) => {
        const errorMsg = `Error while adding item - ${error}`;
        this.utilService.showToastError(errorMsg);
        this.isLoading = false;
      });
  }

  leaveTeam(item){
    this.isLoading = true;
    let body = {
      teamId: item.id,
      userProfileId: item.userprofileid
    }
    this.teamService.leaveTeam(body)
    .subscribe(() => {
      let message = `You have successfully left '${item.fullName}' team`;
      this.utilService.showToastSuccess(message);
      this.getList();
      this.teamService.notifyTeamsChanged();
      this.isLoading = false;
    },
      (error) => {
        const errorMsg = `Error while adding item - ${error}`;
        this.utilService.showToastError(errorMsg);
        this.isLoading = false;
      });
  }
}
