<app-cms-list-view
  *ngIf="isDisplayingList && isInitialised"
  [dataList]="items"
  [isPreventListDelete] = "true"
  imgColumn="3"
  [columnList]="columns">
</app-cms-list-view>

<div *ngIf="(isAddingItem || isEditingItem) && isInitialised"
  class="padding jumbotron">

  <form
  [formGroup]="itemForm"
  (ngSubmit)="onSaveClick()">

  <div class="row m-0">
    <!-- Name -->
    <div class="col-6">
    <div class="form-group">
      <label class="control-label required">
        Name
      </label>
      <input
        type="text"
        class="form-control"
        [ngClass]="displayFieldCss('nameControl')"
        formControlName="nameControl">
      <span class="invalid-feedback">Name is required</span>
    </div>
    </div>

  <!-- FeatureId -->
  <div class="col-6">
    <div class="form-group">
      <label for="typeId" class="control-label required">Type</label>
      <select
        id="typeId"
        formControlName="typeIdControl"
        class="form-control custom-select"
        [ngClass]="displayFieldCss('typeIdControl')">
        <option
          value="">Select a Feature Type</option>
        <option
          *ngFor="let item of featureTypes"
          [value]="item.id">{{item.name}}
        </option>
      </select>
      <span class="invalid-feedback">Feature Type is required</span>
    </div>
  </div>

  <div class="form-group d-inline-block col-12">
    <label for="image" class="control-label required logoInput">
      Mobile Image
    </label>
    <span class="logoImg">
      <img src="{{image}}" class="align-middle" />
    </span>
    <div class="input-group logoInput">
      <input type="text" name="image" id="image" class="form-control" [ngClass]="displayFieldCss('menuImageControl')"
        placeholder="Choose the image" formControlName="menuImageControl" readonly />
      <div class="input-group-prepend">
        <button type="button" class="input-group-text btn btn-primary" (click)="openFileStack()">
          Browse
        </button>
      </div>
      <span class="invalid-feedback">Image is required</span>
    </div>
  </div>

  <div class='form-group col-12'>
    <multiple-select [allOptions]='allCompanies' *ngIf='canSelectCompany' [selectedOptionIds]='includedCompanyIds'
      [label]="'Enable for companies'" [placeholder]="'-- Select company to enable this feature --'" (optionIdsSelected)='includedCompanyIdsSelected($event)'
      [addAllOption]='true' [allOptionLabel]='"All (default)"'>
    </multiple-select>
  </div>
</div>

    <!-- Buttons -->
    <br />
    <div class="row">
      <div [ngClass]="{ 'col-6': isAddingItem, 'col-4': isEditingItem }">
        <button
          type="button"
          class="btn btn-outline-primary w-100"
          (click)="onBackClick()">
          <i class="fa fa-undo mr-2" aria-hidden="true"></i> BACK
        </button>
      </div>

      <div *ngIf="isEditingItem"
        class="col-4">
        <button
          type="button"
          class="btn btn-outline-primary w-100"
          [disabled]="!canRespondToButtons"
          (click)="onDeleteClick()">
          <i class="fa fa-trash mr-2" aria-hidden="true"></i> DELETE
        </button>
      </div>

      <div [ngClass]="{ 'col-6': isAddingItem, 'col-4': isEditingItem }">
        <button
          type="submit"
          class="btn btn-primary float-right w-100"
          [disabled]="!canRespondToButtons">
          <i class="fa fa-floppy-o mr-2" aria-hidden="true"></i> SAVE
        </button>
      </div>
    </div>
  </form>
</div>
