import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-article-preview',
  templateUrl: './article-preview.component.html',
  styleUrls: ['./article-preview.component.scss'],
})
export class ArticlePreviewComponent implements OnInit {
  @Input() bodyTextColor: string;

  sampleArticle = {
    id: 1,
    title: '8 reasons you\'re waking up during the night and how to fix it',
    excerpt:
      'Spending the dark hours of 3-4am staring at the ceiling is likely to leave you feeling tired and anxious the next day.',
    coverImage:
      'https://altiuslifestorage.blob.core.windows.net/article-images/DefaultContent.png',
    numberOfLikes: 16,
  };

  constructor() {
  }

  ngOnInit() {
  }
}
