import { Component, NgZone, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { CarouselService } from '../../../services/carousel-service';
import { CompanyService } from '../../../services/company-service';
import { UtilService } from '../../../services/util-service';
import { FileStackService } from '../../../services/file-stack.service';
import { BootstrapModalComponent } from '../../../components/bootstrap-modal/bootstrap-modal.component';
import { DateService } from '../../../services/date.service';
import { ICarousel } from '../../_models/carousel';
import { CarouselComponent } from '../../carousel/carousel.component';
import { IIdNamePair } from '../../_models/id-name-pair';
import { FormControl, NgForm } from '@angular/forms';
import { UserService } from '../../../services/user-service';

@Component({
  selector: 'app-carousel-management',
  templateUrl: './carousel-management.component.html',
  styleUrls: ['./carousel-management.component.scss'],
})
export class CarouselManagementComponent implements OnInit {
  public items = [];
  public columns = [];
  public fields: any = {};
  public f: NgForm;
  public minDate: Object;
  public loading = false;
  public id: number;
  public error = '';
  public companies = [];
  public allCompanies: IIdNamePair[] = [];
  public includedCompanyIds: number[] = [];
  public excludedCompanyIds: number[] = [];
  private canSelectCompany = false;

  constructor(
    private carouselService: CarouselService,
    private userService: UserService,
    public router: Router,
    private activatedRoute: ActivatedRoute,
    private filestackService: FileStackService,
    private utilService: UtilService,
    private companyService: CompanyService,
    private dateService: DateService,
    private modalService: NgbModal,
    public _zone: NgZone,
  ) {
  }

  ngOnInit() {
    this.activatedRoute.params.subscribe((data) => {
      if (!data.id) {
        this.getList();
      } else {
        this.setUserRoleAuthorisations();
        this.getCompanies();
        if (data.id && data.id !== 'new') {
          this.id = data.id;
          this.populateForm(this.id);
        } else {
          this.id = 0;
          this.fields.companyId = null;
          this.minDate = this.dateService.toYearMonthDayObject();
          this.fields.startDate = this.minDate;
          if (!this.canSelectCompany) {
            this.includedCompanyIds = [this.userService.company.id];
          } else {
            this.includedCompanyIds = [0];
          }
        }
      }
    });
  }

  private setUserRoleAuthorisations() {
    this.canSelectCompany = this.userService.isSuperAdminUser();
  }

  getList() {
    this.carouselService.getAll()
      .subscribe((data) => {
          data.forEach((c) => {
            this.items.push([
              c.id,
              c.title,
              this.dateService.formatDD_MMM_YYYY(c.startDate),
              this.dateService.formatDD_MMM_YYYY(c.endDate),
              this.carouselService.showVisibility(c.visibility),
              this.utilService.formatAsYesNo(c.active),
              c.order,
            ]);
          });
          this.columns = ['id', 'title', 'start date', 'end date', 'visibility', 'active', 'order'];
        },
        (err) => {
          this.utilService.showToastError('Error while loading the records', err);
        },
      );
  }

  getCompanies() {
    this.companyService.getAllCompanies().subscribe(
      (data) => {
        this.companies = data;
        this.allCompanies = this.companies.map(c => {
          return { id: c.id, name: c.name };
        });
      },
      (err) => {
        this.utilService.showToastError('Error while loading companies', err);
      },
    );
  }

  populateForm(objectId) {
    this.carouselService.getById(objectId).subscribe(
      (data) => {
        this.fields.title = data.title;
        this.fields.linkUrl = data.linkUrl;
        this.fields.description = data.description;
        this.fields.order = data.order;
        this.fields.visibility = data.visibility.toString();
        this.fields.companyId = data.companyId;
        this.fields.startDate = this.dateService.toYearMonthDayObject(data.startDate);
        this.fields.endDate = this.dateService.toYearMonthDayObject(data.endDate);
        this.fields.image = data.image;
        this.fields.mobileImage = data.mobileImage;
        this.fields.active = data.active.toString();
        this.excludedCompanyIds = data.excludedCompanyIds || [];
        this.includedCompanyIds = data.includedCompanyIds || [];
      },
      (err) => {
        this.utilService.showToastError('Error while getting carousel information', err);
      },
    );
  }

  isAllCompanies() {
    return this.fields && !+this.fields.companyId && this.canSelectCompany;
  }

  onCompanySelected(companyId) {
    this.fields.companyId = this.userService.company.id;
  }

  openFilestackForDesktopImage(type) {
    this.filestackService.pickDesktopCarouselImg().then(
      (res) => {
        this._zone.run(() => {
          this.fields.image = res;
        });
      },
      (err) => {
        this.utilService.showToastError('Error while uploading picture', err);
      },
    );
  }

  openFilestackForMobileImage(type) {
    this.filestackService.pickMobileCarouselImg().then(
      (res) => {
        this._zone.run(() => {
          this.fields.mobileImage = res;
        });
      },
      (err) => {
        this.utilService.showToastError('Error while uploading picture', err);
      },
    );
  }

  save() {
    this.disableButtonsWhileProcessing();
    if (this.fields.companyId === 'null') {
      this.fields.companyId = null;
    }
    if (!this.canSelectCompany) {
      this.fields.companyId = this.userService.company.id;
    }
    const carousel: ICarousel = {
      id: this.id,
      title: this.fields.title,
      description: this.fields.description,
      linkUrl: this.fields.linkUrl,
      order: this.fields.order,
      visibility: Number.parseInt(this.fields.visibility),
      startDate: this.dateService.fromYearMonthDayObject(this.fields.startDate),
      endDate: this.dateService.fromYearMonthDayObject(this.fields.endDate),
      image: this.fields.image,
      mobileImage: this.fields.mobileImage,
      active: this.fields.active,
      companyId: this.fields.companyId,
      contentType: 1,
      excludedCompanyIds: this.excludedCompanyIds || [],
      includedCompanyIds: this.includedCompanyIds || [],
    };

    this.carouselService.persistCarousel(carousel).subscribe(
      (data) => {
        if (!data.code) {
          this.utilService.showToastSuccess('Carousel saved');
          this.goToList();
        } else {
          this.error = data.code + ' - ' + data.description;
        }
      },
      (err) => {
        this.reenableButtons();
        this.utilService.showToastError('Error while getting carousel information', err);
      },
    );

    this.reenableButtons();
  }

  validateAllFormFields(ngForm: NgForm) {

    Object.keys(ngForm.form.controls).forEach((field: any) => {
      const control = ngForm.form.get(field);

      if (control instanceof FormControl) {
        control.markAsDirty({ onlySelf: true });
      }
    });
  }


  showPreview(f: NgForm) {
    if (f.form.valid) {
      const item = [this.fields];
      const previewModal = this.modalService.open(CarouselComponent, { size: 'lg' });
      previewModal.componentInstance.previewItem = item;
    } else {

      this.validateAllFormFields(f);
    }
  }

  delete() {
    const deleteModal = this.modalService.open(BootstrapModalComponent);
    deleteModal.componentInstance.title = 'Delete item';
    deleteModal.componentInstance.message = 'Are you sure you want to delete this item?';
    deleteModal.componentInstance.confirmButtonLabel = 'Delete';

    deleteModal.result.then((res) => {
      if (res === true) {
        this.carouselService.deleteCarousel(this.id).subscribe(
          (data) => {
            if (data === 1) {
              this.utilService.showToast('success', 'Item deleted!');
              this.goToList();
            } else {
              this.utilService.showToastError('Error while deleting item');
            }
          },
          (err) => {
            this.utilService.showToastError('Error while deleting item', err);
          },
        );
      }
    });
  }

  goToList() {
    this.router.navigate(['/cms/carousel']);
  }

  private disableButtonsWhileProcessing() {
    this.loading = true;
  }

  private reenableButtons() {
    this.loading = false;
  }

  showExcludedCompanies() {
    return this.canSelectCompany && this.includedCompanyIds.length == 0;
  }

  showIncludedCompanies() {
    return this.canSelectCompany && this.excludedCompanyIds.length == 0;
  }

  excludedCompanyIdsSelected(selectedCompanyIds: number[]) {
    this.excludedCompanyIds = selectedCompanyIds;
    if (selectedCompanyIds.length > 0) {
      this.includedCompanyIds = [];
    }
  }

  includedCompanyIdsSelected(selectedCompanyIds: number[]) {
    this.includedCompanyIds = selectedCompanyIds;
    if (selectedCompanyIds.length > 0) {
      this.excludedCompanyIds = [];
    }
  }
}
