import { Component, NgZone, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ArticleService } from '../../../services/article-service';
import { CompanyService } from '../../../services/company-service';
import { UtilService } from '../../../services/util-service';
import { UserService } from '../../../services/user-service';
import { DateService } from '../../../services/date.service';
import { FileStackService } from '../../../services/file-stack.service';
import { IPage } from '../../_models/page';
import { ILanguageData } from '../../_models/language-data';
import { ILanguage } from '../../_models/language';
import { LANGUAGES } from '../../_shared/enums';
import { IIdNamePair } from '../../_models/id-name-pair';
import { ICompany } from '../../_models/company';
import { BootstrapModalComponent } from '../../../components/bootstrap-modal/bootstrap-modal.component';
import { InfoPacksService } from '../../../services/infopacks-service';
import { IInfoPack } from '../../_models/info-pack';
import { CmsListViewService } from '../cms-list-view/cms-list-view.service';

@Component({
  selector: 'information-packs-management',
  templateUrl: './information-packs-management.component.html',
  styleUrls: ['./information-packs-management.component.scss'],
})
export class InformationPacksManagementComponent implements OnInit {
  item: IInfoPack;
  itemType = 'Information Pack';

  lastCode: string = LANGUAGES.en;
  languageData: ILanguageData[] = [];
  availableLanguages: ILanguage[] = [];
  title: string;// used for en version
  description: string;// used for en version
  image: string;// used for en version
  file: string;// used for en version
  errorMsg: string = '';// show language errors
  isDisplayingList: boolean;
  isEditingItem: boolean;
  isAddingItem: boolean;
  allCompanies: IIdNamePair[] = [];
  companies: ICompany[];
  companyId: number;
  items: IPage[];
  columns = [];

  itemForm: FormGroup;

  isInitialised: boolean;
  canRespondToButtons: boolean;
  public initControls: any;

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private infopacksService: InfoPacksService,
    private itemService: ArticleService,
    public userService: UserService,
    private fb: FormBuilder,
    private utilService: UtilService,
    private modalService: NgbModal,
    private companyService: CompanyService,
    private ngZone: NgZone,
    private dateService: DateService,
    private fileStackService: FileStackService,
    private cmsListViewService: CmsListViewService,
  ) {
  }

  ngOnInit() {
    this.companyService.refreshCacheWithCallback(() => {
      this.activatedRoute.params.subscribe((route) => {
        this.analyseRoute(route);

        if (this.isDisplayingList) {
          this.getList();
        } else if (this.isEditingItem) {
          this.initialiseEditItem(+route.id);
        } else if (this.isAddingItem) {
          this.initialiseAddItem();
        }
      });
    });
  }

  private analyseRoute(route) {
    this.isDisplayingList = false;
    this.isEditingItem = false;
    this.isAddingItem = false;
    this.isInitialised = false;
    this.canRespondToButtons = false;

    if (!route.id) {
      this.isDisplayingList = true;
      return;
    }

    if (route.id === 'new') {
      this.isAddingItem = true;
    } else {
      this.isEditingItem = true;
    }
  }

  isSuperAdmin() {
    return this.userService.isSuperAdminUser();
  }

  getCompanyId() {
    let companyId = this.companyService.getCompanyId();
    return companyId ? parseInt(companyId) : this.userService.company.id;
  }

  private getList() {
    this.companyId = this.getCompanyId();
    this.getInformationPackList(this.companyId);

    this.companyService.getAllCompanies()
      .subscribe((data: ICompany[]) => {
        let item: any = { id: 0, name: 'All', isActive: true };
        data.push(item);
        this.companies = data;
        this.allCompanies = this.companies.map(c => {
          return { id: c.id, name: c.name + this.getCompanyNameSuffix(c) };
        });
        this.companyService.removeCompanyId();
      });
  }

  private getCompanyNameSuffix(company: ICompany): string {
    if (company.isActive) {
      return '';
    }

    return ' ---- (INACTIVE)';
  }

  private getInformationPackList(companyId) {
    this.infopacksService.getInfoPacksByCompany(companyId)
      .subscribe(data => {
          this.items = data.map(i => {
            if (this.isSuperAdmin()) {
              return [
                i.id,
                this.cmsListViewService.getTitleColumn(i),
                this.companyService.companyName(i.company_id),
                this.dateService.formatDD_MMM_YYYY(i.updated_at),
                this.cmsListViewService.getLanguagesColumn(i),
              ];
            } else {
              return [
                i.id,
                this.cmsListViewService.getTitleColumn(i),
                this.dateService.formatDD_MMM_YYYY(i.updated_at),
                this.cmsListViewService.getLanguagesColumn(i),
              ];
            }
          });

          this.columns = this.isSuperAdmin() ? ['id', 'title', 'company', 'updated at', 'languages'] : ['id', 'title', 'updated at', 'languages'];
          this.isInitialised = true;
          this.canRespondToButtons = true;
        },
        error => this.utilService.showToastError('Error while loading Information Packs list - ' + error));
  }

  onCompanySelected(companyId: number) {
    this.companyId = companyId;
    this.getInformationPackList(companyId);
  }

  public initialize(initControls) {
    try {
      this.initControls = initControls;
      this.initControls.initialize();
    } catch (error) {
      this.utilService.showToastError('Error while uploading items - ' + error)
    }
  }

  private initialiseEditItem(id: number): void {
    this.infopacksService.getInfoPack(id).subscribe((data) => {
      this.item = data;
      if (this.item.languageVersion) {
        try {
          this.languageData = JSON.parse(this.item.languageVersion);
        } catch (e) {
          console.log(e);
        }
      }
      this.initialiseEditableFormGroup(this.item);
    });
  }

  private initialiseAddItem(): void {
    this.item = this.infopacksService.getNewInfoPack();
    this.initialiseEditableFormGroup(this.item);
    this.isInitialised = true;
    this.canRespondToButtons = true;
  }

  private initialiseEditableFormGroup(item: IInfoPack) {
    this.companies = this.companyService.getAllCachedCompanies();
    let config: any = {
      title: [item.title, Validators.required],
      description: [item.description, Validators.required],
      image: [item.image, Validators.required],
      file: [item.file, Validators.required],
    };

    if (this.isSuperAdmin()) {
      const companyId = this.isEditingItem ? this.companyService.companyName(this.item.company_id) : this.item.company_id;
      config.companyIdControl = [companyId, Validators.required];
    }

    this.itemForm = this.fb.group(config);

    this.isInitialised = true;
    this.canRespondToButtons = true;
  }

  onCompanyChange(companyId: any) {
    if (companyId == 'null') {
      this.itemForm.get('companyIdControl').setErrors({ invalidCompanySelected: true });
    }
  }

  private markAllControlsAsTouched() {
    Object.keys(this.itemForm.controls).forEach((field) => {
      const control = this.itemForm.get(field);
      control.markAsTouched({ onlySelf: true });
    });
  }

  private markAllControlsAsUntouched() {
    Object.keys(this.itemForm.controls).forEach((field) => {
      const control = this.itemForm.get(field);
      control.markAsUntouched({ onlySelf: true });
    });
  }

  isFieldInvalid(field: string) {
    return !this.itemForm.get(field).valid && this.itemForm.get(field).touched;
  }

  validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }

  displayFieldCss(field: string) {
    return {
      'is-invalid': this.isFieldInvalid(field),
    };
  }

  private persistItem() {
    this.errorMsg = '';
    this.canRespondToButtons = false;
    this.buildLanguageData();
    let valid = this.validateLanguageData();
    if (!valid) {
      this.canRespondToButtons = true;
      return;
    }
    let companyId = this.getCompanyId();
    if (this.isSuperAdmin()) {
      companyId = this.isEditingItem ? this.item.company_id : this.itemForm.get('companyIdControl').value;
    }

    let infoPack: IInfoPack = {
      id: this.item.id,
      title: this.title,
      description: this.description,
      image: this.image,
      file: this.file,
      languageVersion: JSON.stringify(this.languageData),
      company_id: companyId,
    };

    if (this.isEditingItem) {
      this.updateItem(infoPack);
    } else if (this.isAddingItem) {
      this.addNewItem(infoPack);
    }
  }

  private addNewItem(infoPack: IInfoPack) {
    this.infopacksService.addInfoPack(infoPack)
      .subscribe(() => {
          this.canRespondToButtons = true;
          const companyName = this.companyService.companyName(+infoPack.company_id);
          this.itemForm.reset();
          this.utilService.showToastSuccess(`Added new ${this.itemType} for company - ${companyName}`);
          this.navigateToList();
        },
        error => {
          this.utilService.showToastError(`Error while adding item - ${error}`);
          this.canRespondToButtons = true;
        });
  }

  private updateItem(infoPack: IInfoPack) {
    this.infopacksService.updateInfoPack(infoPack)
      .subscribe(() => {
          this.itemForm.reset();
          this.canRespondToButtons = true;
          const msg = `Updated ${this.itemType}`;
          this.utilService.showToastSuccess(msg);
          this.navigateToList();
        },
        (error) => {
          const errorMsg = `Error while saving item - ${error}`;
          this.utilService.showToastError(errorMsg);
          this.canRespondToButtons = true;
        },
      );
  }

  private deleteItem() {
    const modal = this.modalService.open(BootstrapModalComponent);
    modal.componentInstance.title = 'Delete ' + this.itemType;
    modal.componentInstance.message = 'Confirm deleting this ' + this.itemType;
    modal.componentInstance.confirmButtonLabel = 'Delete';

    modal.result.then(
      (isConfirmed) => {
        if (!isConfirmed) {
          return;
        }

        this.infopacksService.deleteInfoPack(this.item.id).subscribe(
          () => {
            this.itemForm.reset();
            this.utilService.showToastSuccess(`${this.itemType} for ${this.companyService.companyName(this.item.company_id)} - deleted`);
            this.navigateToList();
          },
          (error) => this.utilService.showToastError('Error while deleting item - ' + error),
        );
      },
      () => {
      },
    );
  }

  private navigateToList() {
    this.router.navigate(['/cms/information-packs']);
  }

  onBackClick() {
    this.navigateToList();
  }

  onDeleteClick() {
    if (!this.isEditingItem) {
      return;
    }

    this.deleteItem();
  }

  onSaveClick() {
    if (!this.itemForm.valid) {
      this.markAllControlsAsTouched();
      return;
    }
    this.persistItem();
  }

  openImageUpload() {
    this.fileStackService.pickSpecifiedPathImage('info-packs/images/').then(
      (res) => {
        this.ngZone.run(() => this.itemForm.patchValue({ image: res }));
      },
      (err) => {
        this.utilService.showToastError('Error while uploading image', err);
      },
    );
  }

  openFileUpload() {
    this.fileStackService.pickInfoPackFile().then(
      (res) => {
        this.ngZone.run(() => this.itemForm.patchValue({ file: res }));
      },
      (err) => {
        this.utilService.showToastError('Error while uploading file', err);
      },
    );
  }

  validateLanguageData(): boolean {
    this.errorMsg = '';
    let valid = true;
    let languagesToRemove = [];
    for (const lang of this.languageData) {
      const obj = lang.obj;
      if (obj.title === '' && obj.description === '' && obj.file === '' && obj.image === this.image) {
        languagesToRemove.push(lang.code);
      } else if (obj.title === '' || obj.description === '' || obj.image === '' || obj.file === '') {
        const name = this.availableLanguages.find(o => o.code === lang.code).name;
        this.errorMsg += `${name} language has errors.<br/>`;
        valid = false;
      }
    }
    for (const code of languagesToRemove) {
      this.languageData.splice(this.languageData.findIndex(o => o.code === code), 1);
    }
    return valid;
  }

  buildLanguageData() {
    if (this.lastCode != LANGUAGES.en) {
      let languageObj: ILanguageData = this.languageData.find(o => o.code == this.lastCode);
      if (!languageObj) {
        languageObj = { code: this.lastCode, obj: {} };
        this.languageData.push(languageObj);
      }
      languageObj.code = this.lastCode;
      languageObj.obj = {
        title: this.itemForm.get('title').value,
        description: this.itemForm.get('description').value,
        image: this.itemForm.get('image').value,
        file: this.itemForm.get('file').value,
      };
    } else {
      this.title = this.itemForm.get('title').value;
      this.description = this.itemForm.get('description').value;
      this.image = this.itemForm.get('image').value;
      this.file = this.itemForm.get('file').value;
    }
  }

  loadAvailableLanguages(languages) {
    this.availableLanguages = languages;
  }

  changeLanguage(code) {
    // build languageVersion data
    this.buildLanguageData();
    // fill data
    this.lastCode = code;
    if (code != LANGUAGES.en) {
      let languageObj: ILanguageData = this.languageData.find(o => o.code == code);
      if (!languageObj) {
        this.itemForm.get('title').setValue('');
        this.itemForm.get('description').setValue('');
        this.itemForm.get('image').setValue(this.image);
        this.itemForm.get('file').setValue('');
      } else {
        this.itemForm.get('title').setValue(languageObj.obj.title);
        this.itemForm.get('description').setValue(languageObj.obj.description);
        this.itemForm.get('image').setValue(languageObj.obj.image);
        this.itemForm.get('file').setValue(languageObj.obj.file);
      }
    } else {
      this.itemForm.get('title').setValue(this.title);
      this.itemForm.get('description').setValue(this.description);
      this.itemForm.get('image').setValue(this.image);
      this.itemForm.get('file').setValue(this.file);
    }
    // mark untouched to hide errors
    this.markAllControlsAsUntouched();
    // validate languageData
    this.validateLanguageData();
  }
}
