import { CmsComponent } from './cms.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ArticleManagementComponent } from './article-management/article-management.component';
import { AcknowledgementOfCountryManagementComponent } from './acknowledgement-of-country-management/acknowledgement-of-country-management.component';
import { InformationPacksManagementComponent } from './information-packs-management/information-packs-management.component';
import { EAPManagementComponent } from './eap-management/eap-management.component';
import { PeerSupportManagementComponent } from './peer-support-management/peer-support-management.component';
import { AuthGuard } from '../guards/auth.guard';
import { CMSEditGuard } from './cms-guard.service';
import { CarouselManagementComponent } from './carousel-management/carousel-management.component';
import { CompanyManagementComponent } from './company-management/company-management.component';
import { BadgeManagementComponent } from './badge-management/badge-management.component';
import { GroupManagementComponent } from './group-management/group-management.component';
import { CategoryManagementComponent } from './category-management/category-management.component';
import { QuestionnaireManagementComponent } from './questionnaire-management/questionnaire-management.component';
import { QuestionManagementComponent } from './questionnaire-management/question-management/question-management.component';
import { ResultManagementComponent } from './questionnaire-management/result-management/result-management.component';
import { VideoManagementComponent } from './video-management/video-management.component';
import { RecipeManagementComponent } from './recipe-management/recipe-management.component';
import { UserManagementComponent } from './user-management/user-management.component';
import { LifeActionManagementComponent } from './life-action-management/life-action-management.component';
import { AnalyticsReportsComponent } from './analytics-reporting/analytics-reporting.component';
import { UsersReportingComponent } from './users-reporting/users-reporting.component';
import { ContentReportingComponent } from './content-reporting/content-reporting.component';
import { TrackMyHealthReportingComponent } from './track-my-health-reporting/track-my-health-reporting.component';
import { AuthorManagementComponent } from './author-management/author-management.component';
import { ChallengesManagementComponent } from './challenges-management/challenges-management.component';
import { TeamsManagementComponent } from './teams-management/teams-management.component';
import { PartnerManagementComponent } from './partner-management/partner-management.component';
import { TeamsReportingComponent } from './teams-reporting/teams-reporting.component';
import { InsightsReportingComponent } from './insights-reporting/insights-reporting.component';
import { ConnectionManagementComponent } from './connection-management/connection-management.component';
import { LanguageManagementComponent } from './language-management/language-management.component';
import { MarketingPageManagementComponent } from './marketing-page/marketing-page-management.component';
import { RoleGuard } from '../guards/role.guard';
import { SYSTEM_ROLES } from '../_shared/enums';
import { MarketingBrochureComponent } from './marketing-brochure/marketing-brochure.component';
import { MenuConfigurationManagementComponent } from './menu-configuration-management/menu-configuration-management.component';
import { FeatureManagementComponent } from './feature-management/feature-management.component';
import { EapBulkUpdateComponent } from './eap-bulk-update/eap-bulk-update.component';
import { MarketingPosterComponent } from './marketing-poster/marketing-poster.component';
import { BookingPosterComponent } from './booking-poster/booking-poster.component';
import { CompanySummaryComponent } from './company-summary/company-summary.component';
import { LifeActionsReportingComponent } from './life-actions-reporting/life-actions-reporting.component';
import { PosterBrochureComponent } from './posters-brochures/posters-brochures.component';
import { MarketingTemplateDetailComponent } from './marketing-template-detail/marketing-template-detail.component';

const cmsRoutes: Routes = [
  {
    path: '',
    component: CmsComponent,
    canActivate: [AuthGuard],
    data: { breadcrumb: 'Content Management System' },
    children: [
      {
        path: '',
        redirectTo: 'company-summary',
        pathMatch: 'full',
      },
      {
        path: 'articles',
        component: ArticleManagementComponent,
        canActivate: [AuthGuard, RoleGuard],
        data: {
          breadcrumb: 'Articles',
          roles: [SYSTEM_ROLES.SUPER_ADMIN, SYSTEM_ROLES.ADMIN, SYSTEM_ROLES.COORDINATOR],
        },
      },
      {
        path: 'articles/:id',
        component: ArticleManagementComponent,
        canActivate: [AuthGuard, RoleGuard],
        canDeactivate: [CMSEditGuard],
        data: {
          breadcrumb: 'Articles Details',
          roles: [SYSTEM_ROLES.SUPER_ADMIN, SYSTEM_ROLES.ADMIN, SYSTEM_ROLES.COORDINATOR],
        },
      },
      {
        path: 'app-links-manager',
        component: MarketingPageManagementComponent,
        canActivate: [AuthGuard, RoleGuard],
        canDeactivate: [CMSEditGuard],
        data: {
          breadcrumb: 'App Links Manager',
          roles: [SYSTEM_ROLES.SUPER_ADMIN],
        },
      },
      {
        path: 'menu-configuration',
        component: MenuConfigurationManagementComponent,
        canActivate: [AuthGuard, RoleGuard],
        canDeactivate: [CMSEditGuard],
        data: {
          breadcrumb: 'Menu Configuration',
          roles: [SYSTEM_ROLES.SUPER_ADMIN],
        },
      },
      {
        path: 'acknowledgement-of-country',
        component: AcknowledgementOfCountryManagementComponent,
        canActivate: [AuthGuard, RoleGuard],
        canDeactivate: [CMSEditGuard],
        data: {
          breadcrumb: 'Acknowledgment of Country',
          roles: [SYSTEM_ROLES.SUPER_ADMIN],
        },
      },
      {
        path: 'eap-bulk-update',
        component: EapBulkUpdateComponent,
        canActivate: [AuthGuard, RoleGuard],
        canDeactivate: [CMSEditGuard],
        data: {
          breadcrumb: 'EAP Bulk Update',
          roles: [SYSTEM_ROLES.SUPER_ADMIN],
        },
      },
      {
        path: 'information-packs',
        component: InformationPacksManagementComponent,
        canActivate: [AuthGuard, RoleGuard],
        canDeactivate: [CMSEditGuard],
        data: {
          breadcrumb: 'Information Packs',
          roles: [SYSTEM_ROLES.SUPER_ADMIN, SYSTEM_ROLES.ADMIN, SYSTEM_ROLES.COORDINATOR],
        },
      },
      {
        path: 'information-packs/:id',
        component: InformationPacksManagementComponent,
        canActivate: [AuthGuard, RoleGuard],
        canDeactivate: [CMSEditGuard],
        data: {
          breadcrumb: 'Information Packs Details',
          roles: [SYSTEM_ROLES.SUPER_ADMIN, SYSTEM_ROLES.ADMIN, SYSTEM_ROLES.COORDINATOR],
        },
      },
      {
        path: 'eap',
        component: EAPManagementComponent,
        canActivate: [AuthGuard, RoleGuard],
        canDeactivate: [CMSEditGuard],
        data: {
          breadcrumb: 'EAP',
          roles: [SYSTEM_ROLES.SUPER_ADMIN],
        },
      },
      {
        path: 'eap/:id',
        component: EAPManagementComponent,
        canActivate: [AuthGuard, RoleGuard],
        canDeactivate: [CMSEditGuard],
        data: {
          breadcrumb: 'EAP Details',
          roles: [SYSTEM_ROLES.SUPER_ADMIN],
        },
      },
      {
        path: 'peer-support',
        component: PeerSupportManagementComponent,
        canActivate: [AuthGuard, RoleGuard],
        canDeactivate: [CMSEditGuard],
        data: {
          breadcrumb: 'Peer Support',
          roles: [SYSTEM_ROLES.SUPER_ADMIN],
        },
      },
      {
        path: 'peer-support/:id',
        component: PeerSupportManagementComponent,
        canActivate: [AuthGuard, RoleGuard],
        canDeactivate: [CMSEditGuard],
        data: {
          breadcrumb: 'Peer Support Details',
          roles: [SYSTEM_ROLES.SUPER_ADMIN],
        },
      },
      {
        path: 'authors',
        component: AuthorManagementComponent,
        canActivate: [AuthGuard, RoleGuard],
        data: {
          breadcrumb: 'Authors',
          roles: [SYSTEM_ROLES.SUPER_ADMIN, SYSTEM_ROLES.ADMIN, SYSTEM_ROLES.COORDINATOR],
        },
      },
      {
        path: 'authors/:id',
        component: AuthorManagementComponent,
        canActivate: [AuthGuard, RoleGuard],
        canDeactivate: [CMSEditGuard],
        data: {
          breadcrumb: 'Authors',
          roles: [SYSTEM_ROLES.SUPER_ADMIN, SYSTEM_ROLES.ADMIN, SYSTEM_ROLES.COORDINATOR],
        },
      },
      {
        path: 'carousel',
        component: CarouselManagementComponent,
        canActivate: [AuthGuard, RoleGuard],
        data: {
          breadcrumb: 'Carousel',
          roles: [SYSTEM_ROLES.SUPER_ADMIN],
        },
      },
      {
        path: 'carousel/:id',
        component: CarouselManagementComponent,
        canActivate: [AuthGuard, RoleGuard],
        data: {
          breadcrumb: 'Carousel Details',
          roles: [SYSTEM_ROLES.SUPER_ADMIN],
        },
      },
      {
        path: 'company-summary',
        component: CompanySummaryComponent,
        canActivate: [AuthGuard, RoleGuard],
        data: {
          breadcrumb: 'Company Summary',
          roles: [SYSTEM_ROLES.SUPER_ADMIN, SYSTEM_ROLES.ADMIN, SYSTEM_ROLES.COORDINATOR],
        },
      },
      {
        path: 'company-summary/:id',
        component: CompanySummaryComponent,
        canActivate: [AuthGuard, RoleGuard],
        data: {
          breadcrumb: 'Company Summary',
          roles: [SYSTEM_ROLES.SUPER_ADMIN, SYSTEM_ROLES.ADMIN],
        },
      },
      {
        path: 'companies',
        component: CompanyManagementComponent,
        canActivate: [AuthGuard, RoleGuard],
        data: {
          breadcrumb: 'Companies',
          roles: [SYSTEM_ROLES.SUPER_ADMIN, SYSTEM_ROLES.ADMIN],
        },
      },
      {
        path: 'companies/:id',
        component: CompanyManagementComponent,
        canActivate: [AuthGuard, RoleGuard],
        canDeactivate: [CMSEditGuard],
        data: {
          breadcrumb: 'Companies Details',
          roles: [SYSTEM_ROLES.SUPER_ADMIN, SYSTEM_ROLES.ADMIN],
        },
      },
      {
        path: 'connections',
        component: ConnectionManagementComponent,
        canActivate: [AuthGuard, RoleGuard],
        data: {
          breadcrumb: 'Connections',
          roles: [SYSTEM_ROLES.SUPER_ADMIN],
        },
      },
      {
        path: 'connections/:id',
        component: ConnectionManagementComponent,
        canActivate: [AuthGuard, RoleGuard],
        canDeactivate: [CMSEditGuard],
        data: {
          breadcrumb: 'Connections Details',
          roles: [SYSTEM_ROLES.SUPER_ADMIN],
        },
      },
      {
        path: 'languages',
        component: LanguageManagementComponent,
        canActivate: [AuthGuard, RoleGuard],
        data: {
          breadcrumb: 'Languages',
          roles: [SYSTEM_ROLES.SUPER_ADMIN],
        },
      },
      {
        path: 'languages/:id',
        component: LanguageManagementComponent,
        canActivate: [AuthGuard, RoleGuard],
        canDeactivate: [CMSEditGuard],
        data: {
          breadcrumb: 'Languages Details',
          roles: [SYSTEM_ROLES.SUPER_ADMIN],
        },
      },
      {
        path: 'badges',
        component: BadgeManagementComponent,
        canActivate: [AuthGuard, RoleGuard],
        data: {
          breadcrumb: 'Badge',
          roles: [SYSTEM_ROLES.SUPER_ADMIN],
        },
      },
      {
        path: 'badges/:id',
        component: BadgeManagementComponent,
        canActivate: [AuthGuard, RoleGuard],
        data: {
          breadcrumb: 'Badge Details',
          roles: [SYSTEM_ROLES.SUPER_ADMIN],
        },
      },
      {
        path: 'challenges',
        component: ChallengesManagementComponent,
        canActivate: [AuthGuard, RoleGuard],
        data: {
          breadcrumb: 'Available Challenge',
          roles: [SYSTEM_ROLES.SUPER_ADMIN],
        },
      },
      {
        path: 'challenges/:id',
        component: ChallengesManagementComponent,
        canActivate: [AuthGuard, RoleGuard],
        data: {
          breadcrumb: 'Challenge Details',
          roles: [SYSTEM_ROLES.SUPER_ADMIN],
        },
      },
      {
        path: 'teams',
        component: TeamsManagementComponent,
        canActivate: [AuthGuard, RoleGuard],
        data: {
          breadcrumb: 'Available Team',
          roles: [SYSTEM_ROLES.SUPER_ADMIN],
        },
      },
      {
        path: 'teams/:id',
        component: TeamsManagementComponent,
        canActivate: [AuthGuard, RoleGuard],
        data: {
          breadcrumb: 'Team Details',
          roles: [SYSTEM_ROLES.SUPER_ADMIN],
        },
      },
      {
        path: 'groups',
        component: GroupManagementComponent,
        canActivate: [AuthGuard, RoleGuard],
        data: {
          breadcrumb: 'Groups',
          roles: [SYSTEM_ROLES.SUPER_ADMIN, SYSTEM_ROLES.ADMIN, SYSTEM_ROLES.COORDINATOR],
        },
      },
      {
        path: 'groups/:id',
        component: GroupManagementComponent,
        canActivate: [AuthGuard, RoleGuard],
        canDeactivate: [CMSEditGuard],
        data: {
          breadcrumb: 'Group Details',
          roles: [SYSTEM_ROLES.SUPER_ADMIN, SYSTEM_ROLES.ADMIN, SYSTEM_ROLES.COORDINATOR],
        },
      },
      {
        path: 'category',
        component: CategoryManagementComponent,
        canActivate: [AuthGuard, RoleGuard],
        data: {
          breadcrumb: 'Categories',
          roles: [SYSTEM_ROLES.SUPER_ADMIN],
        },
      },
      {
        path: 'category/:id',
        component: CategoryManagementComponent,
        canActivate: [AuthGuard, RoleGuard],
        canDeactivate: [CMSEditGuard],
        data: {
          breadcrumb: 'Category Details',
          roles: [SYSTEM_ROLES.SUPER_ADMIN],
        },
      },
      {
        path: 'partners',
        component: PartnerManagementComponent,
        canActivate: [AuthGuard, RoleGuard],
        data: {
          breadcrumb: 'Partners',
          roles: [SYSTEM_ROLES.SUPER_ADMIN],
        },
      },
      {
        path: 'partners/:id',
        component: PartnerManagementComponent,
        canActivate: [AuthGuard, RoleGuard],
        canDeactivate: [CMSEditGuard],
        data: {
          breadcrumb: 'Partner Details',
          roles: [SYSTEM_ROLES.SUPER_ADMIN],
        },
      },
      {
        path: 'questionnaires',
        component: QuestionnaireManagementComponent,
        canActivate: [AuthGuard, RoleGuard],
        data: {
          breadcrumb: 'Questionnaires',
          roles: [SYSTEM_ROLES.SUPER_ADMIN],
        },
      },
      {
        path: 'questionnaires/:id',
        component: QuestionnaireManagementComponent,
        canActivate: [AuthGuard, RoleGuard],
        canDeactivate: [CMSEditGuard],
        data: {
          breadcrumb: 'Questionnaire Details',
          roles: [SYSTEM_ROLES.SUPER_ADMIN],
        },
      },
      {
        path: 'questionnaires/:questionnaireId/question/:id',
        component: QuestionManagementComponent,
        canActivate: [AuthGuard, RoleGuard],
        canDeactivate: [CMSEditGuard],
        data: {
          breadcrumb: 'Question Details',
          roles: [SYSTEM_ROLES.SUPER_ADMIN],
        },
      },
      {
        path: 'questionnaires/:questionnaireId/result/:id',
        component: ResultManagementComponent,
        canActivate: [AuthGuard, RoleGuard],
        canDeactivate: [CMSEditGuard],
        data: {
          breadcrumb: 'Result Details',
          roles: [SYSTEM_ROLES.SUPER_ADMIN],
        },
      },
      {
        path: 'videos',
        component: VideoManagementComponent,
        canActivate: [AuthGuard, RoleGuard],
        data: {
          breadcrumb: 'Videos',
          roles: [SYSTEM_ROLES.SUPER_ADMIN, SYSTEM_ROLES.ADMIN, SYSTEM_ROLES.COORDINATOR],
        },
      },
      {
        path: 'videos/:id',
        component: VideoManagementComponent,
        canActivate: [AuthGuard, RoleGuard],
        canDeactivate: [CMSEditGuard],
        data: {
          breadcrumb: 'Video Details',
          roles: [SYSTEM_ROLES.SUPER_ADMIN, SYSTEM_ROLES.ADMIN, SYSTEM_ROLES.COORDINATOR],
        },
      },
      {
        path: 'recipes',
        component: RecipeManagementComponent,
        canActivate: [AuthGuard, RoleGuard],
        data: {
          breadcrumb: 'Recipes',
          roles: [SYSTEM_ROLES.SUPER_ADMIN, SYSTEM_ROLES.ADMIN, SYSTEM_ROLES.COORDINATOR],
        },
      },
      {
        path: 'recipes/:id',
        component: RecipeManagementComponent,
        canActivate: [AuthGuard, RoleGuard],
        canDeactivate: [CMSEditGuard],
        data: {
          breadcrumb: 'Recipe Details',
          roles: [SYSTEM_ROLES.SUPER_ADMIN, SYSTEM_ROLES.ADMIN, SYSTEM_ROLES.COORDINATOR],
        },
      },
      {
        path: 'users',
        component: UserManagementComponent,
        canActivate: [AuthGuard, RoleGuard],
        data: {
          breadcrumb: 'Users',
          roles: [SYSTEM_ROLES.SUPER_ADMIN],
        },
      },
      {
        path: 'users/:id',
        component: UserManagementComponent,
        canActivate: [AuthGuard, RoleGuard],
        canDeactivate: [CMSEditGuard],
        data: {
          breadcrumb: 'User Details',
          roles: [SYSTEM_ROLES.SUPER_ADMIN, SYSTEM_ROLES.ADMIN, SYSTEM_ROLES.COORDINATOR],
        },
      },
      {
        path: 'lifeactions',
        component: LifeActionManagementComponent,
        canActivate: [AuthGuard, RoleGuard],
        data: {
          breadcrumb: 'Life Actions',
          roles: [SYSTEM_ROLES.SUPER_ADMIN],
        },
      },
      {
        path: 'lifeactions/:id',
        component: LifeActionManagementComponent,
        canActivate: [AuthGuard, RoleGuard],
        canDeactivate: [CMSEditGuard],
        data: {
          breadcrumb: 'Life Action Details',
          roles: [SYSTEM_ROLES.SUPER_ADMIN],
        },
      },
      {
        path: 'analytics',
        component: AnalyticsReportsComponent,
        canActivate: [AuthGuard, RoleGuard],
        data: {
          breadcrumb: 'Google Analytics',
          roles: [SYSTEM_ROLES.SUPER_ADMIN, SYSTEM_ROLES.ADMIN, SYSTEM_ROLES.COORDINATOR],
        },
      },
      {
        path: 'users-reporting',
        component: UsersReportingComponent,
        canActivate: [AuthGuard, RoleGuard],
        data: {
          breadcrumb: 'Users Reporting',
          roles: [SYSTEM_ROLES.SUPER_ADMIN, SYSTEM_ROLES.ADMIN, SYSTEM_ROLES.COORDINATOR],
        },
      },
      {
        path: 'content-reporting',
        component: ContentReportingComponent,
        canActivate: [AuthGuard, RoleGuard],
        data: {
          breadcrumb: 'Content Reporting',
          roles: [SYSTEM_ROLES.SUPER_ADMIN, SYSTEM_ROLES.ADMIN, SYSTEM_ROLES.COORDINATOR],
        },
      },
      {
        path: 'tmh-reporting',
        component: TrackMyHealthReportingComponent,
        canActivate: [AuthGuard, RoleGuard],
        data: {
          breadcrumb: 'Track My Health Reporting',
          roles: [SYSTEM_ROLES.SUPER_ADMIN, SYSTEM_ROLES.ADMIN, SYSTEM_ROLES.COORDINATOR],
        },
      },
      {
        path: 'teams-reporting',
        component: TeamsReportingComponent,
        canActivate: [AuthGuard, RoleGuard],
        data: {
          breadcrumb: 'Teams Reporting',
          roles: [SYSTEM_ROLES.SUPER_ADMIN, SYSTEM_ROLES.ADMIN],
        },
      },
      {
        path: 'insights-reporting',
        component: InsightsReportingComponent,
        canActivate: [AuthGuard, RoleGuard],
        data: {
          breadcrumb: 'Insights Reporting',
          roles: [SYSTEM_ROLES.SUPER_ADMIN, SYSTEM_ROLES.ADMIN],
        },
      },
      {
        path: 'life-actions-reporting',
        component: LifeActionsReportingComponent,
        canActivate: [AuthGuard, RoleGuard],
        data: {
          breadcrumb: 'Life Actions Reporting',
          roles: [SYSTEM_ROLES.SUPER_ADMIN, SYSTEM_ROLES.ADMIN],
        },
      },
      {
        path: 'marketing-template-detail/:templateId',
        component: MarketingTemplateDetailComponent,
        canActivate: [AuthGuard, RoleGuard],
        data: {
          breadcrumb: 'Marketing Template',
          roles: [SYSTEM_ROLES.SUPER_ADMIN, SYSTEM_ROLES.ADMIN, SYSTEM_ROLES.COORDINATOR],
        },
      },
      {
        path: 'marketing-template-detail/:templateId/:companyId',
        component: MarketingTemplateDetailComponent,
        canActivate: [AuthGuard, RoleGuard],
        data: {
          breadcrumb: 'Marketing Template',
          roles: [SYSTEM_ROLES.SUPER_ADMIN, SYSTEM_ROLES.ADMIN],
        },
      },
      {
        path: 'marketing-brochure',
        component: MarketingBrochureComponent,
        canActivate: [AuthGuard, RoleGuard],
        data: {
          breadcrumb: 'Marketing Brochure',
          roles: [SYSTEM_ROLES.SUPER_ADMIN, SYSTEM_ROLES.ADMIN, SYSTEM_ROLES.COORDINATOR],
        },
      },
      {
        path: 'marketing-brochure/:id',
        component: MarketingBrochureComponent,
        canActivate: [AuthGuard, RoleGuard],
        data: {
          breadcrumb: 'Marketing Brochure',
          roles: [SYSTEM_ROLES.SUPER_ADMIN, SYSTEM_ROLES.ADMIN],
        },
      },
      {
        path: 'marketing-poster',
        component: MarketingPosterComponent,
        canActivate: [AuthGuard, RoleGuard],
        data: {
          breadcrumb: 'Marketing Poster',
          roles: [SYSTEM_ROLES.SUPER_ADMIN, SYSTEM_ROLES.ADMIN, SYSTEM_ROLES.COORDINATOR],
        },
      },
      {
        path: 'marketing-poster/:id',
        component: MarketingPosterComponent,
        canActivate: [AuthGuard, RoleGuard],
        data: {
          breadcrumb: 'Marketing Poster',
          roles: [SYSTEM_ROLES.SUPER_ADMIN, SYSTEM_ROLES.ADMIN],
        },
      },
      {
        path: 'booking-poster',
        component: BookingPosterComponent,
        canActivate: [AuthGuard, RoleGuard],
        data: {
          breadcrumb: 'Booking Poster',
          roles: [SYSTEM_ROLES.SUPER_ADMIN, SYSTEM_ROLES.ADMIN, SYSTEM_ROLES.COORDINATOR],
        },
      },
      {
        path: 'booking-poster/:id',
        component: BookingPosterComponent,
        canActivate: [AuthGuard, RoleGuard],
        data: {
          breadcrumb: 'Booking Poster',
          roles: [SYSTEM_ROLES.SUPER_ADMIN, SYSTEM_ROLES.ADMIN],
        },
      },
      {
        path: 'feature',
        component: FeatureManagementComponent,
        canActivate: [AuthGuard, RoleGuard],
        data: {
          breadcrumb: 'Features',
          roles: [SYSTEM_ROLES.SUPER_ADMIN],
        },
      },
      {
        path: 'feature/:id',
        component: FeatureManagementComponent,
        canActivate: [AuthGuard, RoleGuard],
        canDeactivate: [CMSEditGuard],
        data: {
          breadcrumb: 'Feature Details',
          roles: [SYSTEM_ROLES.SUPER_ADMIN],
        },
      },
      {
        path: 'posters-brochures',
        component: PosterBrochureComponent,
        canActivate: [AuthGuard, RoleGuard],
        data: {
          breadcrumb: 'Posters and Brochures',
          roles: [SYSTEM_ROLES.SUPER_ADMIN, SYSTEM_ROLES.ADMIN, SYSTEM_ROLES.COORDINATOR],
        },
      }
    ],
  },
];

@NgModule({
  imports: [
    RouterModule.forChild(cmsRoutes),
  ],
  exports: [
    RouterModule,
  ],
})
export class CmsRoutingModule {
}
