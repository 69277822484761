import { Component, OnInit } from '@angular/core';
import { PageService } from '../../../services/page-service';
import { IPage } from '../../_models/page';
import { UserService } from '../../../services/user-service';

@Component({
  selector: 'app-eap-booking-widget',
  templateUrl: './eap-booking-widget.component.html',
})
export class EapBookingComponent implements OnInit {
  image: string;
  widgetTitle: string;
  title: string;

  constructor(
    private pageService: PageService,
    private userService: UserService,
  ) {
  }

  ngOnInit(): void {
    this.pageService.getPage('eap', this.userService.profile.lang, this.userService.company.id)
      .subscribe(data => {
        if (data && data.code !== 404) {
          this.image = this.getImagePath(data);
          const dataObj = JSON.parse(data.data);
          this.widgetTitle = dataObj.widgetTitle || 'Employee Assistance Program';
          this.title = data.title;
        } else {
          this.image = '../../../assets/img/widgets/Employee_Assistance_Program_widget_image.jpg';
        }
      });
  }

  getImagePath(item: IPage): string {
    return this.userService.getImagePath('eap-images/' + item.image);
  }
}
