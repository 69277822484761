<div class="container">
  <h2>Sign Up</h2>
  <form [formGroup]="form" (ngSubmit)="onSubmitRegisterForm()">
    <div class="form-group">
      <app-company-access-code-input [parentForm]="form" [inputcss]="setInputOutlineClass(companyAccessCode)"
        [selectedCompany]="company"></app-company-access-code-input>
    </div>

    <div *ngIf="customerStripeTokenReceived">
      <p>Payment details received!</p>
      <p>Plan: <b>{{ (selectedStripePlan$ | async)?.priceDetails }}</b></p>
    </div>

    <div class="form-group">
      <app-email-input [parentForm]="form" [inputcss]="setInputOutlineClass(email)"></app-email-input>
    </div>

    <div class="form-group">
      <app-password-input [parentForm]="form" [inputcss]="setInputOutlineClass(confirmPassword)"
        [showConfirmPassword]="true"></app-password-input>
    </div>

    <div *ngIf="canShowExtraRegistrationFields" class="form-group">
      <form [formGroup]="extraRegistrationFieldsForm">
        <div class="form-row">
          <div class="col-12 mt-1">
              <div class="form-check">
                <input
                  class="form-check-input"
                  [formControlName]="'isAcceptMarketing'"
                  type="checkbox"
                  value=""
                  id="isAcceptMarketing"
                  checked />
                <small><label class="form-check-label" for="isAcceptMarketing">
                  Yes, I consent to SeventeenHundred sending my details to HCF so that HCF can contact me about their products and services
                </label></small>
              </div>
          </div>
        </div>
      </form>
    </div>

    <div class="form-row">
      <div class="col-12 mt-1">
          <div class="form-check">
            <input
              class="form-check-input"
              [formControlName]="'is18OrAbove'"
              type="checkbox"
              value=""
              id="is18OrAbove"
              checked />
            <small><label class="form-check-label" for="is18OrAbove">
              I am 18 years or older
            </label></small>
          </div>
      </div>
    </div>

    <div class="form-group">
      <app-terms-privacy-input [parentForm]="form" (termsOfServiceRequested)="onOpenTermsOfServiceRequested()"
        (privacyPolicyRequested)="onOpenPrivacyPolicyRequested()">
      </app-terms-privacy-input>
    </div>
    <div>
      <button type="submit" class="btn btn-primary btn-block oauth-button" [disabled]="isFormSubmitDisabled()">Sign Up</button>
    </div>
  </form>

  <div *ngIf="isLoading" class="row justify-content-center mt-4">
    <i class="fa fa-spinner fa-spin fa-3x" aria-hidden="true"></i>
  </div>
</div>
