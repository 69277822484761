import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class PeerSupportService {
  constructor(private http: HttpClient) {
  }

  deletePeerSupport(id) {
    return this.http.delete<any>(`/cms/peer-support/${id}`);
  }
}
