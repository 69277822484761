import { Injectable } from '@angular/core';
import { UserService } from '../../services/user-service';

@Injectable({
  providedIn: 'root'
})
export class FroalaService {

  imageAllowedTypes: Array<string> = ['jpeg', 'jpg', 'png', 'gif', 'webp', 'avif'];

  options: Object = {
    placeholderText: 'Edit Content Here',
    paragraphFormat: {
      N: 'Normal',
      H1: 'Heading 1',
      H2: 'Heading 2',
      H3: 'Heading 3',
      H4: 'Heading 4',
      H5: 'Heading 5',
      H6: 'Heading 6'
    }, linkAlwaysBlank: true,
    toolbarSticky: true,
    key: this.userService.envVariables.cms.froalaApiKey,
    imageUpload: false,
    imageInsertButtons: [],
    toolbarButtons: ['bold', 'italic', 'underline', 'strikeThrough', 'subscript', 'superscript', '|', 'fontFamily', 'fontSize', 'color', '|', 'emoticons', 'specialCharacters', '|', 'paragraphFormat', 'align', 'formatOL', 'formatUL', 'outdent', 'indent', 'quote', '|', 'insertLink', 'selectAll', 'html', '|', 'undo', 'redo']

  };

  constructor(private userService: UserService) {}

  getEditorOptions() {
    return this.options;
  }

  getAllowedImageOptions() {
    return this.imageAllowedTypes;
  }
}



