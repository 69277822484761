import { Component, NgZone, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';

import { PageService } from '../../../services/page-service';
import { ArticleService } from '../../../services/article-service';
import { CompanyService } from '../../../services/company-service';
import { UtilService } from '../../../services/util-service';
import { UserService } from '../../../services/user-service';
import { DateService } from '../../../services/date.service';
import { FileStackService } from '../../../services/file-stack.service';
import { IPage } from '../../_models/page';
import { LANGUAGES } from '../../_shared/enums';
import { environment } from '../../../environments/_active-environment/environment';
import { FroalaService } from '../froala-service';

@Component({
  selector: 'app-acknowledgement-of-country-management',
  templateUrl: './acknowledgement-of-country-management.component.html',
  styleUrls: ['./acknowledgement-of-country-management.component.scss'],
})
export class AcknowledgementOfCountryManagementComponent implements OnInit {
  item: IPage;
  itemType = 'Acknowledgement of Country';
  pageType = 'acknowledgement_of_country';
  itemForm: FormGroup;
  private options: any = {
    events: {
      'froalaEditor.link.beforeInsert': function(e, editor, link, text, attrs) {
        if (link.indexOf('://') < 0) {
          attrs['data-contenttype'] = link.split('/').slice(-2)[0];
          attrs['data-internallink'] = true;
          attrs['data-contentid'] = link.split('/').slice(-1)[0];
        }
      },
    },
    placeholderText: 'Edit Content Here',
    paragraphFormat: {
      N: 'Normal',
      H1: 'Heading 1',
      H2: 'Heading 2',
      H3: 'Heading 3',
      H4: 'Heading 4',
      H5: 'Heading 5',
      H6: 'Heading 6',
    },
    linkList: [
      {
        text: 'Article Link (update)',
        href: `/home/article/:id`,
      },
      {
        text: 'Recipe Link (update)',
        href: `/home/recipe/:id`,
      },
      {
        text: 'Video Link (update)',
        href: `/home/video/:id`,
      },
      {
        text: 'External Link',
        href: 'https://',
      },
    ],
    toolbarSticky: false,
    key: this.userService.getFroalaApiKey(),
    toolbarButtons: ['fullscreen', 'bold', 'italic', 'underline', 'strikeThrough', 'subscript', 'superscript', '|',
      'fontFamily', 'fontSize', 'color', 'inlineClass', 'inlineStyle', 'paragraphStyle', 'lineHeight', '|',
      'paragraphFormat', 'align', 'formatOL', 'formatUL', 'outdent', 'indent', 'quote', '|',
      'insertLink', 'insertImage', 'insertFile', 'insertTable', '|',
      'emoticons', 'specialCharacters', 'insertHR', 'selectAll', 'clearFormatting', '|',
      'print', 'getPDF', 'help', 'html', '|',
      'undo', 'redo'],
  };

  isInitialised: boolean;
  canRespondToButtons: boolean;
  public initControls: any;

  preview = false;

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private pageService: PageService,
    private itemService: ArticleService,
    public userService: UserService,
    private fb: FormBuilder,
    private utilService: UtilService,
    private modalService: NgbModal,
    private companyService: CompanyService,
    private ngZone: NgZone,
    private dateService: DateService,
    private fileStackService: FileStackService,
    private froalaService: FroalaService,
  ) {
  }

  ngOnInit() {
    this.isInitialised = false;
    this.canRespondToButtons = false;
    this.initialiseEditItem();
  }

  public initialize(initControls) {
    try {
      this.options.imageAllowedTypes = this.froalaService.getAllowedImageOptions();
      this.options.imageUploadURL= `${environment.app.endpoint}/blob-storage/upload`;
      this.options.imageUploadMethod= 'POST';
      this.options.imageUploadParams= {folderPath: 'marketing-images'};
      this.options.events['froalaEditor.image.beforeUpload'] =  (e, editor, images) => {
        const requestHeaders = editor.opts.requestHeaders || {};
        editor.opts.requestHeaders = {
          ...requestHeaders,
          Authorization: this.userService.token,
        };
      };
      this.initControls = initControls;
      this.initControls.initialize();
    } catch (error) {
      this.utilService.showToastError('Error while uploading items - ' + error)
    }
  }

  private initialiseEditItem(): void {
    this.pageService.getPage(this.pageType, LANGUAGES.en, 0).subscribe((data) => {
      if (data.code && data.code === 404) {
        this.item = this.pageService.getNewPage();
      } else {
        this.item = data;
      }
      this.initialiseEditableFormGroup(this.item);
    });
  }

  private initialiseEditableFormGroup(item: IPage) {
    this.itemForm = this.fb.group({
      title: [item.title],
      longText: [item.longText, Validators.required],
      // image: [item.image],
    });

    this.isInitialised = true;
    this.canRespondToButtons = true;
  }

  private markAllControlsAsTouched() {
    Object.keys(this.itemForm.controls).forEach((field) => {
      const control = this.itemForm.get(field);
      control.markAsTouched({ onlySelf: true });
    });
  }

  isFieldInvalid(field: string) {
    return !this.itemForm.get(field).valid && this.itemForm.get(field).touched;
  }

  validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }

  // showPreview() {
  //   if (this.itemForm.valid) {
  //     const item = this.itemForm.value;
  //     item.isArticle = true;
  //     const previewModal = this.modalService.open(ContentDetailComponent, { size: 'lg' });
  //     previewModal.componentInstance.previewContent = item;
  //   } else {
  //     this.validateAllFormFields(this.itemForm);
  //   }
  // }

  displayFieldCss(field: string) {
    return {
      'is-invalid': this.isFieldInvalid(field),
    };
  }

  private updateItem() {
    this.canRespondToButtons = false;
    let page: IPage = {
      title: this.itemForm.get('title').value,
      longText: this.itemForm.get('longText').value,
      image: '',
      type: this.pageType,
    };

    this.pageService.updatePage(page)
      .subscribe(() => {
          this.canRespondToButtons = true;
          const msg = `Updated ${this.itemType}`;
          this.utilService.showToastSuccess(msg);
        },
        (error) => {
          const errorMsg = `Error while saving item - ${error}`;
          this.utilService.showToastError(errorMsg);
          this.canRespondToButtons = true;
        },
      );
  }

  onSaveClick() {
    if (!this.itemForm.valid) {
      this.markAllControlsAsTouched();
      return;
    }
    this.updateItem();
  }

  openFileStack() {
    this.fileStackService.pickArticleImg().then(
      (res) => {
        this.ngZone.run(() => this.itemForm.patchValue({ image: res }));
      },
      (err) => {
        this.utilService.showToastError('Error while uploading picture', err);
      },
    );
  }
}
