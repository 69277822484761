import { Component, EventEmitter, Input, NgZone, OnInit, Output } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import * as rxjs from 'rxjs';
import { map } from 'rxjs/operators';

import { UserService } from '../../../../services/user-service';
import { UtilService } from '../../../../services/util-service';
import { FileStackService } from '../../../../services/file-stack.service';

@Component({
  selector: 'app-text-area',
  templateUrl: './textarea.component.html',
  styleUrls: ['./textarea.component.scss'],
})
export class TextareaComponent implements OnInit {
  @Input() placeholder;
  @Input() path;
  @Input() type;
  @Input() description;
  @Input() inputImage;
  @Input() inputPreview;
  @Input() fromShare = false;
  @Input() fromEdit = false;
  @Input() actionButtonText;
  @Input() disableButtons = false;
  @Output() data: EventEmitter<any> = new EventEmitter<any>();
  @Output() editCancelled: EventEmitter<any> = new EventEmitter<any>();

  invalidAttempt = false;

  text: FormControl;
  preview;
  isHandlingPreviewUrl = false;
  isImageProcessing = false;
  private previewData = new rxjs.BehaviorSubject<object>(null);
  preview$ = this.previewData.asObservable();

  imageString;
  public image = new rxjs.BehaviorSubject<string>(null);
  image$ = this.image.asObservable();
  storedUrl: any;

  isComment: Boolean = false;

  constructor(
    private http: HttpClient,
    private fileStackService: FileStackService,
    public _zone: NgZone,
    private utilService: UtilService,
    private userService: UserService,
  ) {
  }

  ngOnInit() {
    this.text = new FormControl(this.description, Validators.required);
    if (this.inputImage) {
      this.imageString = this.inputImage;
      this.image.next(this.imageString);
    }

    if (this.inputPreview) {
      this.preview = this.inputPreview;
      this.previewData.next(this.inputPreview);
    }

    if (this.type === 'comment') {
      this.isComment = true;
    }
  }

  changePreview(json) {
    this.previewData.next(json);
  }

  showPicker() {
    this.isImageProcessing = true;
    if (this.path == 'team-post-images/') {
      this.fileStackService.pickTeamPostImage().then(
        (res) => {
          this._zone.run(() => this.image.next(res));
          this.imageString = res;
          this.changePreview(null);
          this.isImageProcessing = false;
        },
        (err) => {
          this.utilService.showAlert('Error while uploading picture', err);
          this.isImageProcessing = false;
        },
      );
    } else {
      this.fileStackService.pickPostImage().then(
        (res) => {
          this._zone.run(() => this.image.next(res));
          this.imageString = res;
          this.changePreview(null);
          this.isImageProcessing = false;
        },
        (err) => {
          this.utilService.showAlert('Error while uploading picture', err);
          this.isImageProcessing = false;
        },
      );
    }
  }

  getPhoto(photo) {
    return this.userService.getUserStorageAbsolutePath(this.path, photo);
  }

  closePreview() {
    this.preview = null;
    this.changePreview(null);
  }

  closeImage() {
    this.imageString = null;
    this.image.next(null);
  }

  cancel() {
    this.editCancelled.emit();
  }

  handleKeyPress(e) {
    this.isHandlingPreviewUrl = true;

    const url = /([-a-zA-Z0-9@:%_\+.~#?&//=]{2,256}\.[a-z]{2,4}\b(\/[-a-zA-Z0-9@:%_\+.~#?&//=]*)?)/gi;
    let urls;
    let output = '';

    if (
      e.keyCode === 8 &&
      e.keyCode !== 9 &&
      e.keyCode !== 13 &&
      e.keyCode !== 32 &&
      e.keyCode !== 46
    ) {
      // Return if backspace, tab, enter, space or delete was not pressed.
      this.isHandlingPreviewUrl = false;

      return;
    }

    // GC keyCodes
    if (e.keyCode === 17) {
      // Return if backspace, tab, enter, space or delete was not pressed.
      this.isHandlingPreviewUrl = false;

      return;
    }
    const internalLink = this.checkForInternalLink(this.text.value);
    if (internalLink) {
      this.isHandlingPreviewUrl = false;
      return;
    }
    if ((urls = url.exec(this.text.value)) !== null && !this.preview) {
      setTimeout(() => {
        output += urls[0]; // output += url.exec(this.text.value)[0];
        if (output !== this.storedUrl) {
          this.handleUrl(output)
            .subscribe(data => {
                this.isHandlingPreviewUrl = false;
                if (data.error) {
                  return;
                } else {
                  this.preview = data;
                  this.changePreview(data);
                  this.storedUrl = null;
                }
              },
              () => this.isHandlingPreviewUrl = false,
            );
        }
      }, 700);
      this.isHandlingPreviewUrl = false;
    } else {
      this.isHandlingPreviewUrl = false;

    }
  }

  getImagePath(path, contentType): string {
    if (contentType === 'article') {
      return this.userService.getArticleImage(path);
    }

    if (contentType === 'recipe') {
      return this.userService.getRecipeImage(path);
    }

    return path;
  }

  checkForInternalLink(url) {
    // const hostname = this.userService.company.hostname;
    // const containsInternalLink = url.includes(hostname);
    const appBaseUrls = this.userService.envVariables.app.internalLinkBaseDomains;
    const containsInternalLink = !!appBaseUrls.find(appUrl => url && url.toLowerCase().includes(appUrl));
    return containsInternalLink;
    // Not show preview for internal link
    // if (containsInternalLink) {
    // const urlArray = url.split('/');
    // const contentId = urlArray.pop();
    // const contentType = urlArray.pop();
    // this.contentService.getContentByIdForAnyUser(
    //   contentId, contentType, this.userService.isLoggedIn())
    //   .subscribe(item => {
    //     const imageWithPath = this.getImagePath(item.image, contentType);

    //     const previewObject = {
    //       description: contentType !== 'recipe' ? item.shortText : item.shortDescription,
    //       title: item.title,
    //       // image: imageWithPath,
    //       // url: url,
    //       appParams: { page: 'ContentDetailPage', type: contentType, id: contentId },
    //     };
    //     this.preview = previewObject;
    //     this.previewData.next(previewObject);
    //     this.isHandlingPreviewUrl = false;
    //   });
    // return containsInternalLink;
    // }
  }

  private handleUrl(url) {
    this.storedUrl = url;
    this.isHandlingPreviewUrl = true;
    return this.http
      .get<any>(`https://api.linkpreview.net/?key=5b29a56895d6ad8271bfa91d4bf7230b832ea610afbbd&q=${url}`)
      .pipe(
        map(res => {
          this.isHandlingPreviewUrl = false;
          return res;
        }));
  }

  createNew() {

    if (!this.text.value) {
      this.invalidAttempt = true;
      this.utilService.showInfo('Post must include a description', 1, 'exclamation');
      return;
    }

    const data = { preview: this.preview, description: this.text.value, image: this.imageString };

    this.data.emit(data);
    this.previewData.next(null);
    this.preview = null;
    this.image.next(null);
    this.imageString = null;
    this.text.reset();
  }
}
